import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Autocomplete, IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';
import { createButtonColor, primaryColor, secondaryColor } from '../../../constant';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
};

export default function AddClientsModel({ open, setOpen, isEdit, updateData, fetchData }) {

    const [errors, setErrors] = useState({});
    const [employees, setEmployees] = useState([]);

    const [client_name, setClient_name] = useState('')
    const [manager_id, setManager_id] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState([]);

    const { user_data } = useSelector(state => state.user);

    const handleClose = () => {
        setSelectedEmployee(null);
        setClient_name('')
        setManager_id(null)
        setOpen(false);
    };

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const fetchEmployees = async () => {
        let result = await get_data(`employee/get-all-employees-by-company-for-options/${user_data?._id}`);
        if (result?.status) {
            setEmployees(result?.data);
        }
    };

    React.useEffect(() => {
        fetchEmployees()
        if (isEdit) {
            setClient_name(updateData?.client_name)
            setManager_id(updateData?.manager_id)
            setSelectedEmployee(updateData?.employees || []);
        }
    }, [updateData, open]);

    const validation = () => {
        let error = false;

        if (!client_name) {
            error = true;
            handleError('please Enter Client Name', 'client_name')
        }

        if (selectedEmployee.length === 0) {
            error = true;
            handleError('Please select at least one Employee', 'selectedRoles');
        }

        if (!manager_id) {
            error = true;
            handleError('Please select an Manager', 'manager_id');
        }

        return error;
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            let selectedEmployeeIds = selectedEmployee?.map((item) => item?._id)
            const body = {
                client_name,
                manager_id: manager_id?._id,
                employees: selectedEmployeeIds,
                company_id: user_data?._id
            };

            let result = await post_data(`clients/create-client`, body);

            if (result?.status) {
                toast.success('client added successfully');
                fetchData();
                handleClose();
            } else {
                toast.error("Something went wrong");
            }
        }
    };

    const handleUpdate = async () => {
        const hasError = validation();
        if (!hasError) {
            let selectedEmployeeIds = selectedEmployee?.map((item) => item?._id)
            const body = {
                client_name,
                manager_id: manager_id?._id,
                employees: selectedEmployeeIds,
            };


            let result = await post_data(`clients/update-client/${updateData?._id}`, body);
            if (result?.status) {
                toast.success('Clients updated successfully');
                fetchData();
                handleClose();
            } else {
                toast.error("Something went wrong");
            }
        }
    };

    const handleEmployeeChange = (event, value) => {
        setSelectedEmployee(value);
        if (value) {
            handleError('', 'selectedEmployee');
        }
    };

    const handleManagerChange = (event, value) => {
        setManager_id(value);
        if (value) {
            handleError('', 'manager_id');
        }
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Client Name"
                                value={client_name}
                                onChange={(e) => setClient_name(e.target.value)}
                                onFocus={() => handleError('', 'client_name')}
                                error={!!errors?.client_name}
                                helperText={errors?.client_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={employees}

                                getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name || ''}
                                value={employees?.find((item) => item?._id === manager_id?._id) || ''}
                                onChange={handleManagerChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Manager"
                                        error={!!errors?.manager_id}
                                        helperText={errors?.manager_id}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={employees}
                                multiple
                                getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name || ''}
                                value={selectedEmployee}
                                onChange={handleEmployeeChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Employee"
                                        error={!!errors?.selectedEmployee}
                                        helperText={errors?.selectedEmployee}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: '20px 0px' }}>
                        <Button onClick={isEdit ? handleUpdate : handleSubmit} style={{ background: secondaryColor, color: '#fff' }} fullWidth>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
