import LoginForm from "../../global/components/LoginForm";
import Footer from "../../website/components/Footer";
import Header from "../../website/components/Header";

export default function CompanyLogin() {
    return (
        <>
            <Header />
            <LoginForm />
            <Footer />
        </>
    )
}