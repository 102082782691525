import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { primaryColor, secondaryColor } from '../../constant';
import BackButton from '../../global/components/BackButton';
import { get_data, post_data } from '../../api';
import ApprovePayrollTable from '../components/tables/ApprovePayrollTable';
import { useSelector } from 'react-redux';
import Empty from '../../global/components/Empty';
import TableLoader from '../../global/components/TableLoader';
import ApproveDesignationsTable from '../components/tables/ApproveDesignationsTable';
import ApproveEmployeeDetailsTable from '../components/tables/ApproveEmployeeDetailsTable';

export default function Approve() {

    const [value, setValue] = React.useState(0);

    const [data, setData] = React.useState([]);
    const [data2, setData2] = React.useState([]);
    const [data3, setData3] = React.useState([]);

    const [totalPages, setTotalPages] = React.useState(null);
    const [totalPages2, setTotalPages2] = React.useState(null);
    const [totalPages3, setTotalPages3] = React.useState(null);

    const [totalData, setTotalData] = React.useState(null);
    const [totalData2, setTotalData2] = React.useState(null);
    const [totalData3, setTotalData3] = React.useState(null);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentPage2, setCurrentPage2] = React.useState(1);
    const [currentPage3, setCurrentPage3] = React.useState(1);

    const [loading, setLoading] = React.useState(true);
    const [loading2, setLoading2] = React.useState(true);
    const [loading3, setLoading3] = React.useState(true);

    const { user_data } = useSelector(state => state.user);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const getAllPendingPayrolls = async () => {
        try {
            let payload = { company_id: user_data?._id }
            const data = await post_data(`payroll/get-all-pending-payrolls?pageNumber=${currentPage}`, payload);
            if (data.status) {
                setData(data?.data?.payrolls);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalPayrolls);
            }
        } catch (error) {
        }
    }

    const getAllPendingDesignation = async () => {
        try {
            let payload = { company_id: user_data?._id }
            const data = await post_data(`employee/get-all-pending-designations?pageNumber=${currentPage2}`, payload);
            if (data.status) {
                setData2(data?.data?.designations);
                setTotalPages2(data?.data?.totalPages);
                setTotalData2(data?.data?.totalDesignations);
            }
        } catch (error) {
        }
    }

    const getAllEmployeeUpdateRequest = async () => {
        try {
            const data = await get_data(`update-request/get-all-update-requests/${user_data?._id}?pageNumber=${currentPage3}`);
            if (data.status) {
                setData3(data?.data?.updateRequests);
                setTotalPages3(data?.data?.totalPages);
                setTotalData3(data?.data?.totalUpdateRequests);
            }
        } catch (error) {
        }
    }

    React.useEffect(function () {
        if (currentPage) {
            getAllPendingPayrolls()
        }
        if (currentPage2) {
            getAllPendingDesignation()
        }
        if (currentPage3) {
            getAllEmployeeUpdateRequest()
        }
    }, [user_data, currentPage])


    React.useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const tableHeader = ["S No.", "Member Name", "Request Date", "For Employee", "Payable Salary", "Month", "Status"]
    const tableHeaderDesignation = ["S No.", "Member Name", "Roles", "Requested Date", "For Employee", "Designation", "Status"]
    const tableHeaderEmployeeDetails = ["S No.", "Employee Name", "Employee ID", "Phone", "Request Date", "Fields To Update", "Updated", "Declined"]

    return (
        <>
            <BackButton />
            <Box>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: secondaryColor,
                                height: '3px',
                            },
                        }}
                    >
                        <Tab
                            label="Payroll"
                            {...a11yProps(0)}
                            sx={{
                                color: value === 0 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Designation"
                            {...a11yProps(1)}
                            sx={{
                                color: value === 1 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Employee Details"
                            {...a11yProps(2)}
                            sx={{
                                color: value === 1 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                    </Tabs>
                </Box>
            </Box>

            <div style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <CustomTabPanel value={value} index={0}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <ApprovePayrollTable
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={15}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllPendingPayrolls={getAllPendingPayrolls}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data2?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <ApproveDesignationsTable
                                            data={data2}
                                            tableHeader={tableHeaderDesignation}
                                            itemsPerPage={15}
                                            currentPage={currentPage2}
                                            setCurrentPage={setCurrentPage2}
                                            totalData={totalData2}
                                            totalPages={totalPages2}
                                            loading={loading2}
                                            setLoading={setLoading2}
                                            getAllPendingDesignation={getAllPendingDesignation}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data3?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <ApproveEmployeeDetailsTable
                                            data={data3}
                                            tableHeader={tableHeaderEmployeeDetails}
                                            itemsPerPage={15}
                                            currentPage={currentPage3}
                                            setCurrentPage={setCurrentPage3}
                                            totalData={totalData3}
                                            totalPages={totalPages3}
                                            loading={loading3}
                                            setLoading={setLoading3}
                                            getAllEmployeeUpdateRequest={getAllEmployeeUpdateRequest}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </CustomTabPanel>
            </div>
        </>
    );
}