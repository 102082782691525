import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';


import { formatDate, primaryColor } from "../../../constant";
import Empty from "../../../global/components/Empty";

const AdminCompanyModel = ({ open, setOpen, data, fromDate, toDate }) => {

    const contentRef = useRef(null);
    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    const handleDownloadExcel = () => {
        if (data && data.length > 0) {
            // Prepare the data in the format needed for Excel

            const excelData = data.map(item => ({
                'Company Name': item?.name || '',
                'Email': item?.email || '-',
                'Date': formatDate(item?.created_at) || '',
                'Contact Phone': item?.phone || '',
                'Location': `${item?.address?.city}, ${item?.address?.state},  ${item?.address?.country}` || ''
            }));


            // Create a new workbook and add the data
            const worksheet = XLSX.utils.json_to_sheet(excelData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Companies");

            // Download the file
            XLSX.writeFile(workbook, "companies.xlsx");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <Empty />
                        ) : (
                            <>
                                <Button variant="contained" onClick={() => { handleDownloadExcel() }} style={button}>Download</Button>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0, }}>Companies Details</h2>
                                            {/* <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Services with a Lifestyle
                                </p> */}
                                        </div>
                                        <h2 style={{ fontWeight: 600, margin: 0 }}>Companies</h2>
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            <tr>
                                                <th>Company Name</th>
                                                <th>Email</th>
                                                <th>Date</th>
                                                <th>Contact Phone</th>
                                                <th>Location</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((row, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                                <td style={{ padding: '1% 0' }}>{row?.name || ""}</td>
                                                                <td style={{ padding: '1% 0' }}>{row?.email || ""}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(row?.created_at) || ""}</td>
                                                                <td style={{ padding: '1% 0' }}>{row?.phone || ""}</td>
                                                                <td style={{ padding: '1% 0' }}>{`${row?.address?.city}, ${row?.address?.state},  ${row?.address?.country}` || ""}</td>
                                                                <td style={{ padding: '1% 0' }}>{row?.phone}</td>
                                                                <td style={{ padding: '1% 0' }}>{row?.address?.city || '-'}</td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Companies are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default AdminCompanyModel;