import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../global/components/SearchComponent";
import EmployeeTable from "../components/tables/EmployeeTable";
import { createButtonColor, primaryColor } from "../../constant";
import FilterModal from "../components/FilterModel";
import TableLoader from "../../global/components/TableLoader";
import { useSelector } from "react-redux";
import AdminEmployeeTable from "../components/tables/AdminEmployeeTable";
import Empty from "../../global/components/Empty";
import DatePicker from "react-datepicker";
import CompanyEmployeeModel from "../components/modals/CompanyEmployeeModel";
import moment from "moment";
import toast from "react-hot-toast";

export default function AdmminEmployee({ isAdmin }) {

    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const [employeeType, setEmployeeType] = useState('')
    const [employees, setEmployees] = useState('')
    const [department, setDepartment] = useState('')
    const [rowsData, setRowsData] = useState(data || []);
    const [refresh, setRefresh] = useState(false)
    const [searchState, setSearchState] = useState(false);

    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [employeeData, setEmployeeData] = useState([])

    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            if (value !== '') {
                searchData();
                setSearchState(true);
            } else {
                fetchData();
                setSearchState(false);
            }
        }, 500);
    };

    const searchData = async () => {
        try {
            const response = await get_data(`employee/search-employees-for-admin/${value}?pageNumber=${currentPage}`);
            setLoading(false);
            if (response.status) {
                setRowsData(response?.data?.employees);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalEmployees);
            } else {
                setRowsData([]);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const fetchData = async () => {
        try {
            const response = await get_data(`employee/get-all-employees?pageNumber=${currentPage}`);
            if (response.data) {
                setRowsData(response?.data?.employees);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalEmployees);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchData();
            }
            else {
                fetchData();
            }
        }
    }, [refresh, currentPage, searchState]);


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [searchState, currentPage, refresh]);

    const handleDateChange = async (dates) => {
        if (dates && dates?.length === 2) {

            setDateRange(dates);
            try {
                const fromDate = moment(startDate).toISOString();
                const toDate = moment(endDate).toISOString();
                let response

                response = await post_data(`employee/get-all-employees-by-date?pageNumber=${currentPage}`, {
                    from: fromDate,
                    to: toDate
                })
                if (response?.status) {
                    setOpen(false)
                    setRowsData(response?.data?.employee)
                    setTotalData(response?.data?.totalEmployees)
                    setTotalPages(response?.data?.totalPages)
                    setFromDate(response?.data?.fromDate)
                    setToDate(response?.data?.toDate)
                } else {
                    toast.error('Error! Something went wrong!')
                }
            } catch (error) {
            }
        }
    };



    const itemsPerPage = 15;
    const count = data?.length;

    const handleClick = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response

            response = await post_data('employee/get-all-employees-by-date', {
                from: fromDate,
                to: toDate
            })
            if (response?.status) {
                setOpen(true)
                setEmployeeData(response?.data?.employee)

                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {
                toast.error('Error! Something went wrong!')
            }
        } catch (error) {
        }
    }

    const handleAddEmployee = async () => {
        navigate('/admin/dashboard/addemployee')
    }

    const tableHeader = [
        "S No.","Company Name", "Employee Name", "EMP ID", "Gender", "Designation", "Hiring Date", "Contact Phone", "City"
    ]

    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '4% 0% 4% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };

    const datePicker = {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        borderRadius: 3,
        border: '1px solid gray',
        gap: 10
    }

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>

                    <div style={{ display: 'flex', marginRight: 'auto' }}> <SearchComponent handleSearch={handleSearch} setValue={setValue} value={value} /></div>

                    <div style={{ cursor: 'pointer', background: 'gainsboro', borderRadius: 3, width: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <FilterModal
                            employeeType={employeeType}
                            setEmployeeType={setEmployeeType}
                            employees={employees}
                            setEmployees={setEmployees}
                            setRowsData={setRowsData}
                            setTotalPages={setTotalPages}
                            setTotalData={setTotalData}
                            department={department}
                            isAdmin={isAdmin}
                            setDepartment={setDepartment}
                        />

                    </div>

                    {/* <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <span style={datePicker}>
                            <div style={{ ...rangePickerStyle, border: 'none', }}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    dateFormat="dd-MM-yyyy"
                                    maxDate={new Date()}
                                    customInput={
                                        <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                            {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                        </button>
                                    }
                                />
                            </div>
                        </span>

                        <Button onClick={handleClick} variant="contained" style={{ background: primaryColor, boxShadow: 'none' }}>
                            Export
                        </Button>
                    </div> */}
                </div>

                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    rowsData?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <>
                                            <AdminEmployeeTable
                                                type='employee'
                                                data={rowsData}
                                                tableHeader={tableHeader}
                                                itemsPerPage={15}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalData={totalData}
                                                totalPages={totalPages}
                                                loading={loading}
                                                setLoading={setLoading}
                                                refresh={refresh}
                                                setRefresh={setRefresh}
                                            />

                                            <CompanyEmployeeModel
                                                open={open}
                                                setOpen={setOpen}
                                                fromDate={fromDate}
                                                toDate={toDate}
                                                data={employeeData}
                                            />
                                        </>
                                    )
                                }
                            </>
                        )
                    }

                </div>

            </Grid>
        </>
    )
}