import React, { useEffect, useState } from "react"
import { Grid, Button, capitalize } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { formatPrice, primaryColor, secondaryColor } from "../../constant";
import { get_data } from "../../api";
import TableLoader from "../../global/components/TableLoader";
import EditPlanModal from "../../global/components/EditPlanModal";
import CheckoutModal from "./CheckoutModal";
import { useSelector } from "react-redux";

export default function PricingPlans({ screen, billing }) {

    const navigate = useNavigate()
    const { user_data } = useSelector(state => state.user);
    const [isAnnual, setIsAnnual] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [selectedTenure, setSelectedTenure] = useState('')
    const [checkoutOpen, setCheckoutOpen] = useState(false)

    const pricing_page = {
        width: screen === 'admin' ? '100%' : '85%',
        margin: "2% auto",
        background: 'white',
        borderRadius: 10,
        border: '1px solid #F1F1F2',
        boxShadow: '0px 3px 4px 0 #00000008'
    }

    const pricing_title = {
        fontWeight: 600,
        fontSize: 23
    }

    const border_style = {
        borderRight: '1px solid #E6E9F5',
        borderBottom: '1px solid #E6E9F5'
    }

    const plan_cost_title = {
        fontWeight: 600,
        fontSize: 26,
        color: 'black',
        margin: '6% 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        gap: '2%'
    }

    const button_styles = {
        boxShadow: 'none',
        color: 'white',
        padding: '4% 0',
        marginBottom: '3%',
        fontWeight: 600,
        fontSize: 14,
        textTransform: 'capitalize'
    }

    const pricingSwitcher = () => {
        return (
            <div className="pricing-switcher">
                <button style={{ cursor: 'pointer' }}
                    className={`option ${!isAnnual ? "active" : ""}`}
                    onClick={() => setIsAnnual(false)}
                >
                    Monthly
                </button>
                <button style={{ cursor: 'pointer' }}
                    className={`option ${isAnnual ? "active" : ""}`}
                    onClick={() => setIsAnnual(true)}
                >
                    Annual
                </button>
            </div>
        );
    };


    const fetchData = async () => {
        try {
            const response = await get_data(`plans/get-all-plans`);
            setLoading(false);
            if (response.data) {
                setData(response?.data);
            } else {
                setData([]);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])


    const allPlans = [
        {
            id: data?.[0]?._id,
            name: data?.[0]?.name,
            monthly_price: data?.[0]?.monthly_price,
            monthly_discount_price: data?.[0]?.monthly_discount_price,
            yearly_price: data?.[0]?.yearly_price,
            yearly_discount_price: data?.[0]?.yearly_discount_price,
            permissions: data?.[0]?.permissions?.filter(item => item?.value === 'yes' || item?.value === 'no'),
            limited_permissions: data?.[0]?.permissions?.filter(item => item?.value !== 'yes' && item?.value !== 'no'),
            description: data?.[0]?.description,
        },
        {
            id: data?.[1]?._id,
            name: data?.[1]?.name,
            monthly_price: data?.[1]?.monthly_price,
            monthly_discount_price: data?.[1]?.monthly_discount_price,
            yearly_price: data?.[1]?.yearly_price,
            yearly_discount_price: data?.[1]?.yearly_discount_price,
            permissions: data?.[1]?.permissions?.filter(item => item?.value === 'yes' || item?.value === 'no'),
            limited_permissions: data?.[1]?.permissions?.filter(item => item?.value !== 'yes' && item?.value !== 'no'),
            description: data?.[1]?.description,
        },
        {
            id: data?.[2]?._id,
            name: data?.[2]?.name,
            monthly_price: data?.[2]?.monthly_price,
            monthly_discount_price: data?.[2]?.monthly_discount_price,
            yearly_price: data?.[2]?.yearly_price,
            yearly_discount_price: data?.[2]?.yearly_discount_price,
            permissions: data?.[2]?.permissions?.filter(item => item?.value === 'yes' || item?.value === 'no'),
            limited_permissions: data?.[2]?.permissions?.filter(item => item?.value !== 'yes' && item?.value !== 'no'),
            description: data?.[2]?.description
        },
    ]

    const handlePlanClick = (plan, tenure) => {
        setSelectedPlan(plan)
        if (screen === 'admin') {
            setOpen(true)
        }
        else {
            if (user_data?.is_company) {
                if (billing && billing?.plan_id?._id === plan?.id && billing?.plan_tenure === tenure) {

                }
                else {
                    setSelectedTenure(tenure)
                    setCheckoutOpen(true)
                }
            }
            else {
                navigate('/signup')
            }
        }
    }


    const pricingPlansDetails = (plan, tenure, permissions, limited_permissions) => {
        const formatPermissionName = (str) => {
            return str
                .split('_')
                .map(word =>
                    word.charAt(0).toUpperCase() + word.slice(1)
                )
                .join(' ');
        }


        return (
            <>
                <div style={{ position: 'relative' }}>

                    {
                        billing && billing?.plan_id?._id === plan?.id ?
                            <>
                                <div style={{
                                    top: 0,
                                    left: '25%',
                                    position: "absolute",
                                    width: 160,
                                    padding: '0 1%',
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: '#0069ff',
                                    textTransform: 'capitalize',
                                    borderRadius: '0 0 4px 4px',
                                }}>
                                    <p style={{
                                        color: 'white',
                                        fontSize: 12
                                    }}>
                                        Current Plan - {billing?.plan_tenure}
                                    </p>
                                </div>
                            </>
                            :
                            <></>
                    }

                    <div style={{ ...border_style, padding: '7%' }}>
                        <h3 style={{ ...pricing_title, color: secondaryColor }}>{plan?.name}</h3>
                        <p style={{ color: '#B5AEBB', marginTop: 0 }}>{plan?.description}</p>
                        <div style={plan_cost_title}>
                            <h3 style={{ color: 'black', margin: 0 }}>
                                {isAnnual ? formatPrice(plan?.yearly_discount_price) : formatPrice(plan?.monthly_discount_price)}
                            </h3>
                            <p style={{ color: '#858BA0', fontSize: 14, fontWeight: 500, textTransform: 'capitalize' }}>
                                {tenure}
                            </p>
                        </div>

                        <Button
                            onClick={() => handlePlanClick(plan, tenure)}
                            variant="contained"
                            style={{
                                ...button_styles,
                                background: plan?.name === 'Premium' ? secondaryColor : primaryColor,
                            }}
                            fullWidth
                        >
                            {
                                screen === 'admin' ? 'Edit Plan' :
                                    <>
                                        {
                                            billing && billing?.plan_id?._id === plan?.id ? (
                                                <>Your Plan</>
                                            ) : (
                                                <>Go {plan?.name}</>
                                            )
                                        }
                                    </>
                            }
                        </Button>
                    </div>
                    {
                        permissions?.map((item, i) => {
                            return (
                                <div style={{
                                    ...border_style,
                                    padding: '6%',
                                    display: "flex",
                                    justifyContent: 'left',
                                    gap: 7,
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    fontSize: 16,
                                    height: 30
                                }}>
                                    <img src="/images/green-check-icon.svg" style={{ width: 23 }} /> {formatPermissionName(item?.permission_name)}
                                </div>
                            )
                        })
                    }
                    {
                        limited_permissions?.map((item, i) => {
                            return (
                                <div style={{
                                    ...border_style,
                                    padding: '6%',
                                    display: "flex",
                                    justifyContent: 'left',
                                    gap: 7,
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    fontSize: 16,
                                    height: 30
                                }}>
                                    <img src="/images/green-check-icon.svg" style={{ width: 23 }} />   {formatPermissionName(item?.value)} {formatPermissionName(item?.permission_name)}
                                </div>
                            )
                        })
                    }
                </div>
            </>
        )
    }

    return (
        <>
            <CheckoutModal open={checkoutOpen} setOpen={setCheckoutOpen} selectedPlan={selectedPlan} selectedTenure={selectedTenure} />
            <div style={{ padding: '5%', paddingTop: screen === 'admin' ? 0 : '5%' }}>
                <EditPlanModal open={open} setOpen={setOpen} selectedPlan={selectedPlan} fetchData={fetchData} />
                <>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {pricingSwitcher()}
                                <Grid container spacing={0} style={pricing_page}>
                                    <Grid item md={4} style={{ width: '100%' }}>
                                        {isAnnual ?
                                            pricingPlansDetails(allPlans?.[0], 'annual', allPlans?.[0]?.permissions, allPlans?.[0]?.limited_permissions) :
                                            pricingPlansDetails(allPlans?.[0], 'monthly', allPlans?.[0]?.permissions, allPlans?.[0]?.limited_permissions)
                                        }
                                    </Grid>
                                    <Grid item md={4} style={{ width: '100%', }}>
                                        {isAnnual ?
                                            pricingPlansDetails(allPlans?.[1], 'annual', allPlans?.[1]?.permissions, allPlans?.[1]?.limited_permissions) :
                                            pricingPlansDetails(allPlans?.[1], 'monthly', allPlans?.[1]?.permissions, allPlans?.[1]?.limited_permissions)
                                        }
                                    </Grid>
                                    <Grid item md={4} style={{ width: '100%', }}>
                                        {isAnnual ?
                                            pricingPlansDetails(allPlans?.[2], 'annual', allPlans?.[2]?.permissions, allPlans?.[2]?.limited_permissions) :
                                            pricingPlansDetails(allPlans?.[2], 'monthly', allPlans?.[2]?.permissions, allPlans?.[2]?.limited_permissions)
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </>
            </div>
        </>
    )
}