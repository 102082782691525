import React, { useState } from 'react';
import { Box, Modal, Grid, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IoMdAdd } from 'react-icons/io';
import { styled } from '@mui/material/styles';
import { primaryColor, createButtonColor, secondaryColor } from '../../../constant';
import { get_data, post_data } from '../../../api';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import dayjs from 'dayjs'; // Import dayjs to handle date formatting

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: 'calc(100% + 32px)',
    '& .MuiInputBase-input': {
        width: '100% ',
    },
    '& .MuiOutlinedInput-root': {
        width: '100% ',
    },
}));

const fields = [
    { label: 'Expenses For', name: 'expenseFor', type: 'text' },
    { label: 'Amount', name: 'amount', type: 'number' },
    { label: 'Description', name: 'description', type: 'text' },
];

export default function AddExpenseModal({ refresh, setRefresh, isEdit, expensesId }) {

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ expenseFor: '', amount: '', description: '', dates: null });
    const [errors, setErrors] = useState({});

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setFormData({ expenseFor: '', amount: '', description: '', dates: null });
    }

    const { isLoggedIn, user_data } = useSelector(state => state.user);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) handleError('', name);
    };

    const handleDateChange = (newValue) => {
        setFormData(prev => ({ ...prev, dates: newValue }));
        if (errors.dates) handleError('', 'dates');
    };

    const handleError = (error, field) => {
        setErrors(prev => ({ ...prev, [field]: error }));
    };

    const fetchExpensesDetails = async () => {
        let result = await get_data(`expenses/get-expenses/${expensesId}`);

        if (result?.status == true) {
            setFormData({
                expenseFor: result?.data?.expense_type,
                amount: result?.data?.amount,
                description: result?.data?.description,
                dates: result?.data?.date ? dayjs(result?.data?.date) : null, // Convert date to dayjs object
            });
        }
    };

    React.useEffect(function () {
        if (isEdit) {
            fetchExpensesDetails();
        }
    }, [isEdit]);

    const validate = () => {
        const newErrors = {};
        const { expenseFor, amount, description, dates } = formData;

        if (!expenseFor.trim()) newErrors.expenseFor = 'Please input Expense For';
        if (!amount) newErrors.amount = 'Please input amount';
        if (!description.trim()) newErrors.description = 'Please input Description';
        if (!dates) newErrors.dates = 'Please select a date';

        setErrors(newErrors);
        return Object.keys(newErrors).length > 0;
    };

    const handleSubmit = async () => {
        if (!validate()) {
            handleClose();
            let body = {
                expense_type: formData?.expenseFor,
                amount: formData?.amount,
                date: formData?.dates ? formData?.dates.toISOString() : null,
                description: formData?.description,
                company_id: user_data?._id,
            };

            let result = isEdit
                ? await post_data(`expenses/update-expenses/${expensesId}`, body)
                : await post_data('expenses/create-expenses', body);

            if (result?.status == true) {
                toast.success(isEdit ? 'Expense updated successfully!' : 'Expense created successfully!');
                handleClose();
                setRefresh(!refresh);
            }
        }
    };

    return (
        <div>

            <div onClick={handleOpen}>
                {isEdit ? <div>Edit</div> : <Button startIcon={<IoMdAdd />} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                    Add Expense
                </Button>}
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        {fields?.map(({ label, name, type }) => (
                            <Grid item xs={12} key={name}>
                                <TextField
                                    type={type}
                                    multiline={name === 'description' ? true : false}
                                    rows={name === 'description' ? 2 : 0}
                                    name={name}
                                    value={formData[name]}
                                    onChange={handleChange}
                                    onFocus={() => handleError('', name)}
                                    error={Boolean(errors[name])}
                                    helperText={errors[name]}
                                    label={label}
                                    fullWidth
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={formData.dates}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            error={Boolean(errors.dates)}
                                            helperText={errors.dates}
                                            fullWidth
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: '20px 0', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Button onClick={handleSubmit} style={{ background: secondaryColor, color: '#fff' }} fullWidth>
                            {isEdit ? 'Edit' : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
