import React from "react";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import { formatDate, formatPrice, primaryColor, secondaryColor } from "../../constant";
import { useSelector } from "react-redux";
import { useState } from "react";
import BankModal from "./modals/BankModal";
import { get_data, post_data } from "../../api";
import Empty from "../../global/components/Empty"
import TableLoader from "../../global/components/TableLoader";
import AddSalaryModal from "./modals/AddSalaryModal";
import SalaryInvoiceModal from "./modals/SalaryInvoiceModal";

export default function PayrollComp({ employeeData, fetchEmployeeDetails, isAdmin }) {

    const [open, setOpen] = useState(false)
    const [salaryOpen, setSalaryOpen] = useState(false)
    const [invoiceOpen, setInvoiceOpen] = useState(false)
    const [payrolls, setPayrolls] = useState([])
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [selectedItem, setSelectedItem] = useState({})
    const [searchState, setSearchState] = useState(false);
    const [penalty, setPenalty] = useState({});

    const { user_data } = useSelector(state => state.user);

    const fetchEmployeePayrolls = async () => {
        let body = {
            employee_id: employeeData?._id,
            company_id: user_data?._id
        }
        let result = await post_data(`payroll/get-payroll-by-employee?pageNumber=${currentPage}`, body)
        if (result?.status == true) {
            setLoading(false)
            setPayrolls(result?.data?.payrolls);
            setPenalty({
                totalPenalty: result?.data?.totalPenalties,
                penalty_amount: result?.data?.penalty_amount
            });
            setTotalPages(result?.data?.totalPages);
            setTotalData(result?.data?.totalPayrolls);
        }
        else {
            setLoading(false)
        }
    }

    React.useEffect(function () {
        if (currentPage) {
            fetchEmployeePayrolls()
        }
    }, [employeeData, currentPage])

    const itemsPerPage = 5

    const list = [{ title: 'Account Holder Name ', value: employeeData?.bank_account?.accountHolderName || '-' },
    { title: 'Bank Name ', value: employeeData?.bank_account?.bankName || '-' },
    { title: 'Account Number', value: employeeData?.bank_account?.accountNumber || '-' },
    { title: 'IFSC Code', value: employeeData?.bank_account?.ifscCode || '-' },
    { title: 'Branch Name', value: employeeData?.bank_account?.branchName || '-' },
    ]

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    return (<div>

        <AddSalaryModal open={salaryOpen} setOpen={setSalaryOpen} employeeData={({ ...employeeData, payrolls })} penalty={penalty} fetchEmployeeDetails={fetchEmployeeDetails} />

        <BankModal open={open} setOpen={setOpen} employeeData={employeeData} fetchEmployeeDetails={fetchEmployeeDetails} />

        <SalaryInvoiceModal open={invoiceOpen} setOpen={setInvoiceOpen} employeeData={employeeData} currentInvoice={selectedItem} />

        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Grid container spacing={2} >
                    <Grid item xs={12} style={{ display: 'flex', border: '1px solid gainsboro', borderRadius: '5px', margin: 5, flexDirection: 'column' }}>
                        <Typography fontSize={17} fontWeight="500" gutterBottom>
                            Bank Account Details
                        </Typography>
                        <Button style={{ background: '#e1ffd1', color: 'green', width: '10%', margin: '10px 0px 20px 0px', textTransform: "capitalize", borderRadius: 50 }}>
                            Active
                        </Button>
                        <div style={{ width: '100%', paddingBottom: isAdmin ? '3%' : '' }}>
                            {list?.map((item) => {
                                return (<div style={{ display: 'flex', marginTop: 5 }}>
                                    <div style={{ width: '25%' }} >
                                        <span style={{ color: "#808080", fontSize: '14px' }}>
                                            {item?.title}
                                        </span>
                                    </div>
                                    <div style={{ width: '75%', }}>
                                        <span style={{ fontSize: '14px', }}>
                                            {item?.value}
                                        </span>
                                    </div>
                                </div>)
                            })}
                        </div>

                        {
                            !isAdmin && (
                                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                                    <Button onClick={() => setOpen(true)}
                                        style={{ background: `${secondaryColor}`, color: '#fff', width: '', margin: '20px 15px 20px 0px', textTransform: "capitalize", padding: '9px 20px' }}>
                                        Change Details
                                    </Button>
                                </div>
                            )
                        }

                    </Grid>


                    <Grid item xs={12} style={{ display: 'flex', border: '1px solid gainsboro', borderRadius: '5px', margin: 5, flexDirection: 'column' }}>
                        <Typography fontSize={17} fontWeight="500" gutterBottom>
                            Receipt History
                        </Typography>
                        <div style={{ width: '100%', marginBottom: '10px' }}>

                            {
                                loading ? (
                                    <TableLoader />
                                ) : (
                                    <>
                                        {
                                            payrolls?.length === 0 ? (
                                                <>
                                                    <Empty />
                                                </>
                                            ) : (
                                                <>
                                                    {payrolls?.map((item) => {
                                                        return (<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, alignItems: 'center' }}>
                                                            <div style={{ width: 170 }}>
                                                                <span style={{ fontSize: '14px', }}>
                                                                    {item?.month}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{ fontSize: '14px', }}>
                                                                    {formatPrice(item?.salary?.net_salary)}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span style={{ color: "#808080", fontSize: '14px' }} >
                                                                    {formatDate(item?.created_at)}
                                                                </span>
                                                            </div>
                                                            <div style={{ marginRight: '10px' }}>
                                                                <Button style={{ fontSize: 13 }} onClick={() => {
                                                                    setInvoiceOpen(true)
                                                                    setSelectedItem(item)
                                                                }}>
                                                                    Download
                                                                </Button>
                                                            </div>
                                                        </div>)
                                                    })}
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }

                            {payrolls?.length > 0 && (
                                <div
                                    style={{
                                        borderTop: "1px solid gainsboro",
                                        padding: "2% 0",
                                        marginTop: '3%',
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 15,
                                            color: "black",
                                        }}
                                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                </div>
                            )}

                        </div>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ display: 'flex', border: '1px solid gainsboro', borderRadius: '5px', margin: 5, flexDirection: 'column' }}>
                        <Typography fontSize={17} fontWeight="500" gutterBottom>
                            Payment Method
                        </Typography>
                        <div style={{ width: '100%', fontSize: '14px', paddingBottom: isAdmin ? '5%' : '' }}>
                            Direct Bank Transfer
                        </div>
                        {
                            !isAdmin && (
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Button onClick={() => setSalaryOpen(true)} style={{ background: `${primaryColor}`, color: '#fff', width: '', margin: '30px 14px 14px 0px', textTransform: "capitalize", padding: '8px 0' }}>
                                        Pay Now
                                    </Button>
                                </div>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>)
}