import { useSelector } from "react-redux";
import PricingPlans from "../../website/components/PricingPlans";
import CompanySubscription from "../components/CompanySubscription";
import CompanyTransactionsTable from "../components/CompanyTransactionsTable";

export default function CompanyBilling() {

    const { user_data } = useSelector(state => state.user);

    return (
        <>
            {
                user_data?.plan_id && (
                    <>
                        <CompanySubscription plan={user_data?.billing?.plan_id} billing={user_data?.billing} />
                        <CompanyTransactionsTable />
                    </>
                )
            }
            <PricingPlans screen={'company'} user_data={user_data} billing={user_data?.billing} />
        </>
    )
}