import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function BackButton() {

    const navigate = useNavigate()

    return (
        <>
            <div style={{ display: 'flex', gap: 5, alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate(-1)}>
                <IoIosArrowRoundBack fontSize={27} />
                <h3 style={{ fontWeight: 500, fontSize: 18, margin: 0 }}>Back</h3>
            </div>
        </>
    )
}