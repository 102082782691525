import { Button, useMediaQuery } from "@mui/material"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react";
import { secondaryColor } from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";

export default function PaymentStatus() {

    const { user_data } = useSelector(state => state.user);
    const [screenLoading, setScreenLoading] = useState(false)
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const navigate = useNavigate()
    const location = useLocation()
    const status = location?.state?.status

    const success_div = {
        background: '#F3F6F9',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
    }

    const success_box = {
        width: isDesktop ? '35%' : '90%',
        margin: 'auto',
        padding: '2% 2% 3%',
        boxShadow: '4px 4px 44px rgba(0,5,46,.08)',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 15,
        alignItems: 'center',
        flexDirection: 'column',
    }

    const btnStyles = {
        padding: '13px 20px',
        fontSize: '14px',
        color: '#fff',
        backgroundColor: secondaryColor,
        border: 'none',
        borderRadius: '10px',
        cursor: 'pointer',
        width: '40%',
        marginTop: '20px',
    }

    let title = status === 'success' ? 'Payment Successfull!' : 'Payment Unsuccessfull!'

    return (
        <>
            <div style={success_div}>
                <div style={success_box}>
                    <img src={`/images/success.svg`} style={{ width: 110, marginTop: '-17%', marginBottom: '3%' }} />
                    <h3 className="global-h3" style={{ margin: '2% 0', fontSize: 20, fontWeight: 500 }}>{title}</h3>
                    <p style={{ textAlign: 'center', fontSize: 13, marginTop: '1%', opacity: '70%' }}>
                        Questions? Suggestions? Insightful thoughts?
                    </p>
                    <p style={{ color: secondaryColor, fontSize: 13, fontWeight: 500, textDecoration: 'underline', cursor: "pointer" }} onClick={() => navigate('/contact')}>
                        Contact us
                    </p>
                    <Button style={btnStyles} onClick={() => navigate('/company/dashboard')}>Back to Dashboard</Button>
                </div>
            </div>
        </>
    )
}