import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Contact() {
    return (
        <>
            <Header />
            <ContactForm />
            <Footer />
        </>
    )
}