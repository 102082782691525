import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, Typography, Box, IconButton } from '@mui/material';
import { secondaryColor } from '../../constant';
import { IoCallOutline, IoChatbubblesOutline, IoLocationOutline } from 'react-icons/io5';
import { FaFacebookF, FaXTwitter } from 'react-icons/fa6';
import { IoLogoInstagram } from 'react-icons/io';
import { post_data } from '../../api';
import toast from 'react-hot-toast';

const ContactForm = () => {

    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleInputChange = (field) => (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({ ...prevData, [field]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' })); // Clear error on typing
    };

    const validateField = (field, value) => {
        switch (field) {
            case 'firstName':
            case 'lastName':
                return value.trim() === '' ? 'This field is required' : '';
            case 'email':
                return /^\S+@\S+\.\S+$/.test(value) ? '' : 'Enter a valid email';
            case 'phone':
                return /^\d{10}$/.test(value) ? '' : 'Enter a valid 10-digit phone number';
            case 'message':
                return value.trim() === '' ? 'Message cannot be empty' : '';
            default:
                return '';
        }
    };

    const handleBlur = (field) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: validateField(field, formData[field]),
        }));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};
        Object.keys(formData).forEach((field) => {
            newErrors[field] = validateField(field, formData[field]);
        });
        setErrors(newErrors);
        if (Object.values(newErrors).every((error) => error === '')) {
            setLoading(true)
            const response = await post_data('company/send-contact-mail', formData)
            if (response?.status) {
                toast.success('Form Submitted')
                setLoading(false)
            }
            else {
                toast.error('Something went wrong!')
                setLoading(false)
            }
        }
    };

    const iconStyle = {
        border: '1px solid gainsboro',
        borderRadius: '50%',
        padding: '5px',
        color: secondaryColor,
    };



    const leftGridItem = (icon, heading, content, detail) => (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
            <IconButton style={{ ...iconStyle, alignSelf: 'flex-start' }}>
                {icon}
            </IconButton>
            <div style={{ marginLeft: '15px', width: '100%' }}>
                <h3 style={{ margin: 0, fontWeight: 500 }}>{heading}</h3>
                <p style={{ margin: '5px 0' }}>{content}</p>
                <p style={{ fontWeight: 600, margin: 0, width: '80%', fontSize: 15 }}>{detail}</p>
            </div>
        </div>
    );


    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={4} style={{ padding: '7%' }} maxWidth="1200px" margin="auto">
            <Box flex={1} component="form" onSubmit={handleSubmit} display="flex" flexDirection="column">
                <Box display="flex" gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
                    <TextField
                        label="First name"
                        variant="outlined"
                        fullWidth
                        value={formData.firstName}
                        onChange={handleInputChange('firstName')}
                        onBlur={() => handleBlur('firstName')}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                    />
                    <TextField
                        label="Last name"
                        variant="outlined"
                        fullWidth
                        value={formData.lastName}
                        onChange={handleInputChange('lastName')}
                        onBlur={() => handleBlur('lastName')}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                    />
                </Box>
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.email}
                    onChange={handleInputChange('email')}
                    onBlur={() => handleBlur('email')}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    label="Phone number"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.phone}
                    onChange={handleInputChange('phone')}
                    onBlur={() => handleBlur('phone')}
                    error={!!errors.phone}
                    helperText={errors.phone}
                />
                <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={formData.message}
                    onChange={handleInputChange('message')}
                    onBlur={() => handleBlur('message')}
                    error={!!errors.message}
                    helperText={errors.message}
                />

                <Button type="submit" variant="contained" color="primary" fullWidth style={{
                    background: secondaryColor,
                    boxShadow: 'none',
                    padding: '1.5% 0',
                    marginTop: '3%'
                }}>
                    {loading ? 'Send message...' : 'Send message'}
                </Button>
            </Box>

            <Box flex={1}>
                {leftGridItem(<IoChatbubblesOutline size={25} />, 'Chat to us', 'Our friendly team is here to help.', 'support@nivishka.com')}
                {leftGridItem(<IoLocationOutline size={25} />, 'Visit us', 'Come say hello at our office HQ.', 'No. 2497/577, 8th Main Rd, 5th Block, 1st Stage, HBR Layout, Bengaluru, Karnataka 560043')}
                {leftGridItem(<IoCallOutline size={25} />, 'Call us', 'Directly call us now.', '+91 8889997776')}
                <div style={{ display: 'flex', marginTop: '10%', gap: '15px' }}>
                    <a style={{ textDecoration: 'none' }} href='https://www.facebook.com' target='_blank'><FaFacebookF style={iconStyle} /></a>
                    <a style={{ textDecoration: 'none' }} href='https://x.com/' target='_blank'><FaXTwitter style={iconStyle} /></a>
                    <a style={{ textDecoration: 'none' }} href='https://www.instagram.com/' target='_blank'><IoLogoInstagram style={iconStyle} /></a>
                </div>
            </Box>
        </Box>
    );
};

export default ContactForm;
