import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    data,
    Pagination
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { get_data } from '../../api';
import { useSelector } from 'react-redux';
import { formatDate, formatPrice } from '../../constant';
import CompTansactionsModal from './CompTansactionsModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

export default function CompanyTransactionsTable() {

    const itemsPerPage = 15;
    const { user_data } = useSelector(state => state.user);
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(null);
    const [data, setData] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const getAllCompanyTransactions = async () => {
        setLoading(true);
        try {
            const response = await get_data(`company-billings/get-all-billings-by-company/${user_data?._id}?pageNumber=${currentPage}`);
            if (response.status) {
                setData(response?.data?.transactions);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalBillings);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentPage) {
            getAllCompanyTransactions();
        }
    }, [currentPage]);

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const count = data?.length;

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const tableHeader = [
        "S No.", "Plan Name", "Tenure", "Amount", "Paid Amount", "Purchased Date", "Expiry Date", "Download"
    ];

    const handleDownload = (row) => {
        setSelectedRow(row)
        setOpen(true)
    }

    return (
        <>
            <CompTansactionsModal
                setOpen={setOpen}
                open={open}
                data={selectedRow}
            />
            <h3 style={{ fontWeight: 500, marginTop: '3%' }}>Billing History</h3>
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {
                                tableHeader?.map((item) => {
                                    return (
                                        <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: 60, padding: '1.3% 0' }}>{index + 1}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', width: 300 }}>{row?.plan_id?.name || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textTransform: 'capitalize' }}>{row?.plan_tenure || '-'}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(row?.amount_before_gst)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(row?.payment_amount)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.created_at)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', color: 'red' }}>{formatDate(row?.plan_expiry_date)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <Button
                                        onClick={() => handleDownload(row)}
                                        style={{ fontSize: 10, fontWeight: 500, backgroundColor: '#E7F5E9', color: '#43A047', borderRadius: 50 }}>
                                        Download
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {data?.length >= 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}

            </TableContainer>
        </>
    );
};


