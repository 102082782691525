import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AttendanceTable from "../components/tables/AttendanceTable";
import { useSelector } from "react-redux";
import Empty from "../../global/components/Empty";
import TableLoader from "../../global/components/TableLoader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default function Attendance() {
    const { user_data } = useSelector(state => state.user);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const getAllAttendance = async () => {
        try {
            const data = await get_data(`attendance/get-all-employee-attendances-of-company/${user_data?._id}?pageNumber=${currentPage}`);
            if (data.status) {
                setData(data?.data?.attendances);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalAttendances);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllAttendance(currentPage);
        }
    }, [currentPage, user_data])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", "Selfie In", "Selfie Out", "Employee Name", "Number", "Punch In", "Punch Out", "Location In", "Location Out", "Date", "Status", "Record"
    ]

    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '4% 0% 4% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };

    const handleDateChange = (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            setOpen(true)
        }
    }, [startDate, endDate]);

    const datePicker = {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        border: '1px solid gray',
        borderRadius: 3,
        gap: 10
    }

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ marginLeft: 'auto', gap: 10, display: 'flex', justifyContent: 'right', marginBottom: '1%', marginTop: 0 }}>
                    <span style={datePicker}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Backdrop
                            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </span>
                </div>

                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <AttendanceTable
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllAttendance={getAllAttendance}
                                        />
                                    )
                                }
                            </>
                        )
                    }

                </div>

            </Grid>
        </>
    )
}