import DepartmentTable from "../components/tables/DepartmentTable";
import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import TableLoader from "../../global/components/TableLoader";
import Empty from "../../global/components/Empty";
import { useSelector } from "react-redux";
import AdminSalesTable from "../components/tables/AdminSalesTable";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import toast from "react-hot-toast";
import { primaryColor } from "../../constant";
import AdminExportBillingsModal from "../components/modals/AdminExportBillingsModal";

export default function AdminSales() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const { user_data } = useSelector(state => state.user);

    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [open, setOpen] = useState(false)
    const [billingsData, setBillingsData] = useState([]);
    const [exportOpen, setExportOpen] = useState(false);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const getAllDepartments = async () => {
        setLoading(true);
        try {
            const response = await get_data(`company-billings/get-all-billings?pageNumber=${currentPage}`);
            if (response.status) {
                setData(response?.data?.billings);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalBillings);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentPage) {
            getAllDepartments()
        }
    }, [currentPage]);

    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Company Name", "Plan Name", "Tenure", "Amount", "Paid Amount", "Purchased Date", "Expiry Date", "Download"
    ];


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [currentPage]);


    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '4% 0% 4% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };


    const handleDateChange = async (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
            try {
                const fromDate = moment(startDate).toISOString();
                const toDate = moment(endDate).toISOString();
            } catch (error) {
            }
        }
    };

    const datePicker = {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        borderRadius: 3,
        border: '1px solid gray',
        gap: 10
    }

    useEffect(() => {
        if (startDate && endDate) {
        }
    }, [startDate, endDate]);

    const handleClick = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response

            response = await post_data('company-billings/get-all-billings-by-date', {
                from: fromDate,
                to: toDate,
            })

            if (response?.status) {
                setBillingsData(response?.data?.billings)
                setExportOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {
                toast.error('Error! Something went wrong!')
            }
        } catch (error) {
        }
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                <AdminExportBillingsModal
                                    setExportOpen={setExportOpen}
                                    exportOpen={exportOpen}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    data={billingsData}
                                />

                                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>
                                    <span style={datePicker}>
                                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleDateChange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                dateFormat="dd-MM-yyyy"
                                                maxDate={new Date()}
                                                customInput={
                                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                                    </button>
                                                }
                                            />
                                        </div>
                                        <Backdrop
                                            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                                            open={open}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    </span>


                                    <Button onClick={handleClick} variant="contained" style={{ background: primaryColor, boxShadow: 'none' }}>
                                        Export
                                    </Button>
                                </div>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <AdminSalesTable
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Grid>
        </>
    );
}