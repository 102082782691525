import Footer from "../components/Footer";
import Header from "../components/Header";

export default function About() {
    return (
        <>
            <Header />
            <Footer />
        </>
    )
}