import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate, secondaryColor } from '../../../constant';
import { post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';
import FieldsDetailsModal from '../modals/FieldsDetailsModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    statusButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.pending': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.approved': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.rejected': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },

    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const ApproveEmployeeDetailsTable = ({
    data,
    tableHeader,
    currentPage,
    setCurrentPage,
    totalData,
    totalPages,
    getAllEmployeeUpdateRequest,
    itemsPerPage
}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const [title, setTitle] = useState('')

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handleOpenModal = (row, title) => {
        setTitle(title)
        setSelectedRow(row)
        setOpen(true)
    }

    return (
        <>
            <FieldsDetailsModal open={open} setOpen={setOpen} data={selectedRow} title={title} getAllEmployeeUpdateRequest={getAllEmployeeUpdateRequest} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {
                                tableHeader?.map((item) => {
                                    return (
                                        <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: 60, padding: '1.5% 0' }}>{index + 1}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.employee_id?.first_name + ' ' + row?.employee_id?.last_name}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.employee_id?.employee_id}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.employee_id?.phone}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.created_at)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', color: secondaryColor, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleOpenModal(row, 'Fields To Update')}>{row?.fields_to_update?.filter((item) => item?.status === 'pending')?.length} Fields to update</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', color: secondaryColor, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleOpenModal(row, 'Fields Updated')}>{row?.fields_to_update?.filter((item) => item?.status === 'approved')?.length} Fields Updated</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro', color: secondaryColor, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleOpenModal(row, 'Fields Declined')}>{row?.fields_to_update?.filter((item) => item?.status === 'declined')?.length} Fields Declined</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {data?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}

            </TableContainer>
        </>
    );
};

export default ApproveEmployeeDetailsTable;