import React, { useEffect, useMemo, useState } from 'react';
import { TextField, Chip, IconButton, Button, Autocomplete } from '@mui/material';
import { Close as CloseIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import { secondaryColor } from '../../constant';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../api';
import toast, { Toaster } from 'react-hot-toast';

function SendEmail() {

    const { user_data } = useSelector(state => state.user);
    const [to, setTo] = useState([]);
    const [emailInput, setEmailInput] = useState({});
    const [subject, setSubject] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [errors, setErrors] = useState({ to: false, subject: false, content: false, client: false, employee: false, manager: false });
    const [content, setContent] = useState('');

    const [clientList, setClientList] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [managerList, setManagerList] = useState([])
    const [employeeEmails, setEmployeeEmails] = useState([])
    const [managerEmail, setManagerEmail] = useState([])
    const [client, setClient] = useState({})
    const [employees, setEmployees] = useState([])
    const [manager, setManager] = useState({})

    const fetchAllClients = async () => {
        let result = await get_data(`clients/get-all-clients-by-company-for-options/${user_data?._id}`)
        if (result?.status) {
            setClientList(result?.data)
        }
    }

    useEffect(function () {
        fetchAllClients()
    }, [])

    const handleAddRecipient = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            const trimmedEmail = emailInput.trim();
            if (trimmedEmail && !to.includes(trimmedEmail)) {
                setTo([...to, trimmedEmail]);
            }
            setEmailInput('');
        }
    };

    const handleDeleteRecipient = (emailToDelete) => {
        setTo(to.filter((email) => email !== emailToDelete));
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setAttachments([...attachments, ...Array.from(event.target.files)]);
        }
    };

    const handleRemoveAttachment = (fileToRemove) => {
        setAttachments(attachments.filter((file) => file !== fileToRemove));
    };


    console.log('errore', errors)

    const handleSend = async () => {
        const toError = to.length === 0;
        const subjectError = subject.trim() === '';
        const contentError = content.trim() === '';
        const employeeError = employees.length === 0;

        setErrors({ content: contentError, employeeEmails: employeeError });

        if (!contentError) {
            let formData = new FormData();
            formData.append('company_id', user_data?._id);
            formData.append('to', employeeEmails.join(','));
            formData.append('subject', subject);
            formData.append('content', content);

            attachments.forEach((file) => {
                formData.append('attachments', file);
            });

            let result = await post_data('emails/create-email', formData);
            if (result?.status) {
                toast.success('Email sent successfully');
                handleDiscard();
            }
        }
    };



    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    ['image', 'link', 'video'],
                    ['code-block'],
                    [
                        {
                            color: [
                                '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
                                '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
                                '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66',
                                '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00',
                                '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000',
                                '#663d00', '#666600', '#003700', '#002966', '#3d1466',
                            ],
                        },
                    ],
                ],
                imageResize: {
                    displaySize: true,
                },
            },
        }),
        []
    );

    const handleDiscard = () => {
        setSubject('')
        setContent('')
        setEmailInput('')
        setTo([])
        setAttachments([])
    }

    console.log('sujecte', subject)

    return (
        <>
            <div className="email-compose">
                <div className="header">
                    <div className="from">
                        <span>From:</span> {user_data?.email}
                    </div>
                    <div className="to" style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: '2%' }}>
                        <div style={{ width: 80 }}>Client:</div>
                        {/* <TextField
                            value={emailInput}
                            onChange={(e) => {
                                setEmailInput(e.target.value)
                                setErrors({ ...errors, to: false })
                            }}
                            onKeyDown={handleAddRecipient}
                            placeholder="Enter recipients email"
                            variant="outlined"
                            size='small'
                            style={{ width: 400 }}
                            error={errors.to}
                            helperText={errors.to ? 'At least one recipient is required.' : ''}
                        /> */}
                        <Autocomplete
                            options={clientList}
                            value={client}
                            getOptionLabel={(option) => option?.client_name || ''}
                            style={{ width: 400 }}
                            onChange={(event, newValue) => {
                                setClient(newValue)
                                setErrors({ ...errors, client: false })
                                setEmployeeList(newValue?.employees)
                                setManagerList([newValue?.manager_id])
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Client"
                                    error={!!errors?.client}
                                    helperText={errors?.client}
                                    fullWidth
                                />
                            )}
                        />
                        <div className="chip-container">
                            {to.map((email) => (
                                <Chip
                                    key={email}
                                    label={email}
                                    onDelete={() => handleDeleteRecipient(email)}
                                    deleteIcon={<CloseIcon />}
                                    style={{ margin: '5px' }}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="to" style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: '1%' }}>
                    <div style={{ width: 80 }}>Employee:</div>
                    {/* <TextField
                        type="text"
                        className="subject-input"
                        placeholder="Subject"
                        value={subject}
                        onChange={(e) => {
                            setSubject(e.target.value)
                            setErrors({ ...errors, subject: false })
                        }}
                        variant="outlined"
                        size='small'
                        error={errors.subject}
                        helperText={errors.subject ? 'Subject is required.' : ''}
                        style={{ marginTop: '20px', width: 400 }}
                    /> */}
                    <Autocomplete
                        options={employeeList}
                        value={employees}
                        getOptionLabel={(option) => option?.first_name || ''}
                        style={{ width: 400 }}
                        multiple
                        onChange={(event, newValue) => {
                            setEmployees(newValue)
                            setErrors({ ...errors, employee: false })
                            newValue?.map((item) => {
                                setEmployeeEmails([...employeeEmails, item?.email])
                            })

                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Employees"
                                error={!!errors?.employeeEmails}
                                helperText={errors?.employeeEmails}
                                fullWidth
                            />
                        )}
                    />

                    <div className="chip-container">
                        {employeeEmails.map((email) => (
                            <Chip
                                key={email}
                                label={email}
                                onDelete={() => {
                                    setEmployeeEmails(employeeEmails.filter((tempEmail) => tempEmail !== email))
                                    setEmployees(employees?.filter((item) => item?.email !== email))
                                }}
                                deleteIcon={<CloseIcon />}
                                style={{ margin: '5px' }}
                            />
                        ))}
                    </div>
                </div>

                <div className="to" style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: '1%' }}>
                    <div style={{ width: 80 }}>Manager:</div>
                    <Autocomplete
                        options={managerList}
                        value={manager}
                        getOptionLabel={(option) => option?.first_name || ''}
                        style={{ width: 400 }}
                        onChange={(event, newValue) => {
                            setManager(newValue)
                            setErrors({ ...errors, manager: false })
                            setManagerEmail([...managerEmail, newValue?.email])
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Manager"
                                error={!!errors?.manager}
                                helperText={errors?.manager}
                                fullWidth
                            />
                        )}
                    />
                    <div className="chip-container">
                        {managerEmail.map((email) => (
                            <Chip
                                key={email}
                                label={email}
                                onDelete={() => {
                                    setManagerEmail(managerEmail.filter((tempEmail) => tempEmail !== email))
                                    setManager({})
                                }}
                                deleteIcon={<CloseIcon />}
                                style={{ margin: '5px' }}
                            />
                        ))}
                    </div>
                </div>

                <ReactQuill
                    value={content}
                    onChange={(value) => {
                        setContent(value);
                        setErrors({ ...errors, content: false });
                    }}
                    style={{ height: 270, marginTop: '3%' }}
                    modules={modules}
                />
                {errors.content && (
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        Email content is required.
                    </div>
                )}

                {/* <div className="attachments" style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: '5%' }}>
                    <span>Attachments</span>
                    <IconButton component="label">
                        <AttachFileIcon />
                        <input type="file" multiple hidden onChange={handleFileChange} />
                    </IconButton>
                </div> */}

                <div className="attachment-names">
                    {attachments?.map((file, index) => (
                        <div key={index}>
                            <span>{file.name}</span>
                            <IconButton onClick={() => handleRemoveAttachment(file)} size="small">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    ))}
                </div>

                <div className="actions" style={{ marginTop: '5%' }}>
                    <Button variant="outlined" color="secondary" style={{ color: 'red', borderColor: 'red' }} onClick={handleDiscard}>
                        Discard
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleSend} style={{ marginRight: '10px', boxShadow: 'none', background: secondaryColor }}>
                        Send Email
                    </Button>
                </div>
            </div>
        </>
    );
}

export default SendEmail;