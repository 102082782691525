import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Grid,
    Typography,
    InputAdornment,
    IconButton,
    Autocomplete,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast, Toaster } from 'react-hot-toast';
import { primaryColor, secondaryColor } from "../../constant";
import { get_data, post_data } from '../../api';

const AdminAddSubAdmin = ({ open, onClose, refresh, setRefresh }) => {

    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        permissions: [],
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [selectedPermission, setSelectedPermission] = useState([]);
    const subAdminId = localStorage.getItem('subAdminId');

    const fetchPermissions = async () => {
        try {
            const response = await get_data(`permission/get-all-sub-admin-permission-for-options`);
            if (response?.status && Array.isArray(response?.data?.subAdminPermission)) {
                setPermissions(response.data.subAdminPermission);
            } else {
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    const fetchSubAdminData = async () => {
        if (subAdminId) {
            try {
                const response = await get_data(`sub-admin/get-sub-admin/${subAdminId}`);
                if (response.status) {
                    let temp = response?.data?.permissions?.map((item) => item?._id) || [];
                    setFormData({
                        name: response.data.name,
                        phoneNumber: response.data.phoneNumber,
                        email: response.data.email,
                        password: '',
                        confirmPassword: '',
                        permissions: temp,
                    });
                    setSelectedPermission(response.data.permissions || []);
                }
            } catch (error) {
            }
        }
    };

    useEffect(() => {
        fetchSubAdminData();
    }, [subAdminId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const validate = () => {
        const validationErrors = {};
        if (!formData.name) validationErrors.name = 'Name is required';
        if (!formData.phoneNumber) validationErrors.phoneNumber = 'Phone number is required';
        if (!formData.email) validationErrors.email = 'Email is required';
        if (!formData.password && !subAdminId) validationErrors.password = 'Password is required';
        if (formData.password !== formData.confirmPassword) validationErrors.confirmPassword = 'Passwords do not match';
        if (selectedPermission.length === 0) validationErrors.permission = 'Permission is required';
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate();
        if (Object.keys(errors)?.length > 0) {
            Object.keys(errors).forEach((key) => {
                toast.error(errors[key]);
            });
            return;
        }

        try {
            const apiEndpoint = subAdminId ? `sub-admin/update-sub-admin/${subAdminId}` : 'sub-admin/create-sub-admin';
            const response = await post_data(apiEndpoint, {
                ...formData,
            });

            if (response.status === true) {
                toast.success(response.message);
                setFormData({
                    name: '',
                    phoneNumber: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    permissions: [],
                });

                setSelectedPermission([]);
                localStorage.removeItem('subAdminId');
                onClose();
                setRefresh(!refresh)
            } else {
                toast.error(response?.response?.data?.message || 'Something went wrong!');
            }
        } catch (error) {
            console.error('Error processing sub admin:', error);
            toast.error(`An error occurred while ${subAdminId ? 'updating' : 'creating'} Sub Admin.`);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ color: primaryColor }}>
                {subAdminId ? 'Edit Sub Admin' : 'Create Sub Admin'}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ padding: 3 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Phone number"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Autocomplete
                                    options={permissions || []}
                                    getOptionLabel={(option) => option?.permission_name || ""}
                                    onChange={(event, value) => {
                                        setSelectedPermission(value || []);
                                        setFormData((prev) => ({
                                            ...prev,
                                            permissions: value ? value.map((item) => item?._id).filter(Boolean) : [],
                                        }));
                                    }}
                                    value={selectedPermission || []}
                                    multiple
                                    renderInput={(params) => (
                                        <TextField {...params} label="Permission" required />
                                    )}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    fullWidth
                                    required={!subAdminId}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handlePasswordVisibility}>
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    fullWidth
                                    required={!subAdminId}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleConfirmPasswordVisibility}>
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: secondaryColor, boxShadow: 'none', }}>
                    {subAdminId ? 'Update Sub Admin' : 'Create Sub Admin'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminAddSubAdmin;
