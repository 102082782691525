import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2';
import { post_data } from '../../../api';
import AddHolidaysModal from '../AddHolidaysModal';
import dayjs from "dayjs"

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    stageButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.analysis': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.qualification': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

export default function HolidaysTable({
    data, tableHeader, refresh, setRefresh,
    itemsPerPage, totalPages, totalData, currentPage, setCurrentPage
}) {

    const classes = useStyles();

    const [rowsData, setRowsData] = useState(data || []);

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleCheckboxToggle = (index) => {
        const updatedRows = [...rowsData];
        updatedRows[index].checked = !updatedRows[index].checked;
        setRowsData(updatedRows);
    };

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handleDeleteHoliDays = async (row) => {

        Swal.fire({
            title: "Do you want to Dalete this HoliDays?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `Don't Delete`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const endpoint = `holidays/delete-holidays/${row._id}`
                var result = await post_data(endpoint);
                if (result.status) {
                    Swal.fire("Deleted", "", "success");
                    setRefresh(!refresh)
                } else Swal.fire("Fail to delete record", "", "success");
            } else if (result.isDenied) {
                Swal.fire("Your record is safe", "", "info");
            }
        });
    };

    const formatDateV2 = (date) => {
        return dayjs(date).startOf('day').format('YYYY-MM-DD'); // Adjust format as per your requirement
    };


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {
                            tableHeader?.map((item) => {
                                return (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px', width: 60 }}>{item}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: 60 }}>{index + 1}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 750 }}>{row?.name}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 200 }}>{formatDateV2(row?.from_date)}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 200 }}>{formatDateV2(row?.to_date)}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>
                                <IconButton
                                    className={classes.moreIcon}
                                    onClick={(event) => handleMenuOpen(event, index)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && currentRow === index}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem > < AddHolidaysModal refresh={refresh} setRefresh={setRefresh} isEdit={true} holidayId={row?._id} /></MenuItem>
                                    <MenuItem onClick={() => { handleDeleteHoliDays(row) }}>Delete</MenuItem>
                                </Menu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {data?.length >= 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}

        </TableContainer>
    );
};