import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useSelector } from "react-redux";
import Empty from "../../global/components/Empty";
import TableLoader from "../../global/components/TableLoader";
import EmployeeAttendanceTable from "./tables/EmployeeAttendanceTable";

export default function EmployeeAttendance({ employeeData }) {

    const { user_data } = useSelector(state => state.user);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const getAllAttendance = async () => {
        try {
            const data = await get_data(`attendance/get-all-attendance-of-employee/${employeeData?._id}?pageNumber=${currentPage}`);
            if (data.status) {
                setData(data?.data?.attendances);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalAttendances);
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllAttendance(currentPage);
        }
    }, [currentPage])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;


    const tableHeader = [
        "S No.", "Selfie In", "Selfie Out", "Name", "Punch In", "Punch Out", "Location In", "Location Out", "Date", "Status", "Record"
    ]


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <EmployeeAttendanceTable
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllAttendance={getAllAttendance}
                                        />
                                    )
                                }
                            </>
                        )
                    }

                </div>

            </Grid>
        </>
    )
}