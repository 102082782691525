import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, InputAdornment, IconButton, Autocomplete, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast, Toaster } from 'react-hot-toast';
import { primaryColor, secondaryColor } from "../../constant"; // Import your color constants
import { useParams } from 'react-router-dom'; // Assuming you'll get companyId from URL
import { City, Country, State } from 'country-state-city'; // Correct import
import { get_data, post_data } from '../../api';

const AddCompanyComp = ({ existingData }) => {

    let companyId = localStorage.getItem('companyId')
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        logo: null,
        website: '',
        password: '',
        confirmPassword: '',
        gst: '', // New GST field
        support_phone:'',
        support_email:'',
        hr_email:'',
        address: {
            street: '',
            zip_code: '',
            country: '',
            state: '',
            city: ''
        }
    });

    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [countries, setCountries] = useState([]); // Will hold countries
    const [states, setStates] = useState([]); // Will hold states based on country
    const [cities, setCities] = useState([]); // Will hold cities based on state
    const [logoPreview, setLogoPreview] = useState(''); // State for logo preview
    const [isEditMode, setIsEditMode] = useState(false)

    useEffect(() => {
        const allCountries = Country.getAllCountries();
        setCountries(allCountries);
    }, []);

    useEffect(() => {
        if (isEditMode && existingData) {
            setFormData({
                ...formData,
                ...existingData,
            });
        }
    }, [existingData, isEditMode]);


    const fetchCompanyDetails = async () => {
        let result = await get_data(`company/get-company/${companyId}`);
        if (result?.status === true) {
            setFormData((prev) => {
                return {
                    ...prev,
                    ...result?.data
                };
            });

            // Fetch and set states based on the country
            if (result?.data?.address?.country) {
                const selectedStates = State.getStatesOfCountry(result?.data?.address?.country);
                setStates(selectedStates);

                // Fetch and set cities based on the state
                if (result?.data?.address?.state) {
                    const selectedCities = City.getCitiesOfState(result?.data?.address?.country, result?.data?.address?.state);
                    setCities(selectedCities);
                }
            }
        }
    };


    useEffect(() => {
        if (companyId) {
            setIsEditMode(true);
            fetchCompanyDetails();
        }
    }, [companyId, countries]);


    const handleCountryChange = (event, value) => {
        if (value) {
            setFormData((prev) => {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        country: value.isoCode,
                        state: '',
                        city: ''
                    }
                };
            });
            setErrors((prev) => ({ ...prev, country: '' })); // Clear country error
            const selectedStates = State.getStatesOfCountry(value.isoCode);
            setStates(selectedStates);
            setCities([]); // Clear cities when country changes
        } else {
            setStates([]);
            setCities([]);
        }
    };

    const handleStateChange = (event, value) => {
        if (value) {
            setFormData((prev) => {
                return {
                    ...prev,
                    address: {
                        ...prev.address,
                        state: value.isoCode,
                        city: ''
                    }
                };
            });
            setErrors((prev) => ({ ...prev, state: '' })); // Clear state error
            const selectedCities = City.getCitiesOfState(formData?.address?.country, value.isoCode);
            setCities(selectedCities);
        } else {
            setCities([]);
        }
    };

    const handleCityChange = (event, value) => {
        setFormData({
            ...formData,
            address: {
                ...formData?.address,
                city: value?.name || ''
            }
        });
        setErrors((prev) => ({ ...prev, city: '' })); // Clear city error
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, logo: file });
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setLogoPreview(previewUrl); // Set the preview URL
        }
    };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Company Name is required';
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Valid phone number required';
        if (!formData.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) newErrors.email = 'Valid email required';
        if (!isEditMode && (!formData.password || formData.password.length < 6)) newErrors.password = 'Password should be at least 6 characters';

        if (!isEditMode && (!formData?.confirmPassword || formData?.confirmPassword.length < 6)) newErrors.confirmPassword = 'Confirm Password should be at least 6 characters';
        if (!isEditMode && formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

        if (!formData?.address?.street) newErrors.street = 'Street is required'
        if (!formData?.address?.zip_code) newErrors.zip_code = 'Valid Zip Code required';
        if (!formData?.address?.country) newErrors.country = 'Country is required';
        if (!formData?.address?.state) newErrors.state = 'State is required';
        if (!formData?.address?.city) newErrors.city = 'City is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const { logo, ...newFormData } = formData
            const payload = new FormData();
            Object.keys(formData).forEach((key) => {
                if (key === 'address') {
                    payload.append(key, JSON.stringify(formData[key]))
                } else {
                    payload.append(key, formData[key]);
                }
            });
            try {
                let result
                isEditMode ?
                    result = await post_data(`company/update-company/${companyId}`, newFormData) :
                    result = await post_data(`company/create-company`, payload);
                const message = isEditMode ? 'Company updated successfully!' : 'Company created successfully!';
                toast.success(message);
            } catch (error) {
                toast.error(isEditMode ? 'Failed to edit company!' : 'Failed to create company!');
            }
        }
    };


    return (
        <Box
            sx={{
                padding: 3,
                backgroundColor: 'white',
                borderRadius: 2,
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 2, color: primaryColor, fontWeight: 600 }}>
                {isEditMode ? 'Edit Company' : 'Add Company'}
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                    <Grid item md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ color: primaryColor }}>Company Information</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Company Name"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    fullWidth

                                    onFocus={() => handleError('', 'name')}
                                    error={!!errors.name}
                                    helperText={errors.name}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Phone"
                                    value={formData.phone}
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    fullWidth

                                    onFocus={() => handleError('', 'phone')}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Email"
                                    type="email"
                                    value={formData.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    fullWidth

                                    onFocus={() => handleError('', 'email')}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                />
                            </Grid>

                            <Grid item xs={6} display="flex" alignItems="center">
                                <Button variant="contained" component="label" sx={{ border: '1px dashed ' + secondaryColor, boxShadow: 'none', color: secondaryColor, background: 'white' }}>
                                    Upload Logo
                                    <input type="file" hidden onChange={handleFileChange} />
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Website Link"
                                    value={formData.website}
                                    onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                                    fullWidth
                                    onFocus={() => handleError('', 'website')}
                                    error={!!errors.website}
                                    helperText={errors.website}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="GST"
                                    value={formData.gst}
                                    onChange={(e) => setFormData({ ...formData, gst: e.target.value })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Support Phone"
                                                        fullWidth
                                                        onChange={(e) => setFormData({ ...formData, support_phone: e.target.value })}
                                                        value={formData?.support_phone}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Support Email"
                                                        fullWidth
                                                        onChange={(e) => setFormData({ ...formData, support_email: e.target.value })}
                                                        value={formData?.support_email}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Hr Email"
                                                        fullWidth
                                                        onChange={(e) => setFormData({ ...formData, hr_email: e.target.value })}
                                                        value={formData?.hr_email}
                                                        variant="outlined"
                                                    />
                                                </Grid>

                            {/* Password */}
                            {!isEditMode && (
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formData.password}
                                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                            fullWidth

                                            onFocus={() => handleError('', 'password')}
                                            error={!!errors?.password}
                                            helperText={errors?.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handlePasswordVisibility}>
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Confirm Password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={formData.confirmPassword}
                                            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                                            fullWidth

                                            onFocus={() => handleError('', 'confirmPassword')}
                                            error={!!errors?.confirmPassword}
                                            helperText={errors?.confirmPassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleConfirmPasswordVisibility}>
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}

                            {/* Address Information */}
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ color: primaryColor }}>Address Information</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Street"
                                    value={formData?.address?.street}
                                    onChange={(e) => setFormData({ ...formData, address: { ...formData?.address, street: e.target.value } })}
                                    fullWidth
                                    onFocus={() => handleError('', 'street')}
                                    error={!!errors?.street}
                                    helperText={errors?.street}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="Zip Code"
                                    value={formData.address?.zip_code}
                                    onChange={(e) => setFormData({ ...formData, address: { ...formData?.address, zip_code: e.target.value } })}
                                    fullWidth

                                    onFocus={() => handleError('', 'zip_code')}
                                    error={!!errors?.zip_code}
                                    helperText={errors?.zip_code}
                                />
                            </Grid>

                            {/* Country, State, City */}
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={countries}
                                    getOptionLabel={(option) => option.name}
                                    value={countries.find(country => country.isoCode === formData?.address?.country) || null} // Prefill country value
                                    onChange={handleCountryChange}
                                    renderInput={(params) => <TextField {...params} label="Country" error={!!errors?.country} helperText={errors?.country} />}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    options={states}
                                    getOptionLabel={(option) => option.name}
                                    value={states.length > 0 ? states.find(state => state.isoCode === formData?.address?.state) || null : null} // Prefill state value only if states are loaded
                                    onChange={handleStateChange}
                                    renderInput={(params) => <TextField {...params} label="State" error={!!errors?.state} helperText={errors?.state} />}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item xs={4}>
                                <Autocomplete
                                    options={cities}
                                    getOptionLabel={(option) => option.name}
                                    value={cities.length > 0 ? cities.find(city => city.name === formData?.address?.city) || null : null} // Prefill city value only if cities are loaded
                                    onChange={handleCityChange}
                                    renderInput={(params) => <TextField {...params} label="City" error={!!errors?.city} helperText={errors?.city} />}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" type="submit" sx={{ backgroundColor: secondaryColor, boxShadow: 'none' }}>
                                    {isEditMode ? 'Update Company' : 'Add Company'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={4} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        marginTop: '4%'
                    }}>
                        {logoPreview && (
                            <img
                                src={logoPreview}
                                alt="Logo Preview"
                                style={{
                                    width: '70%',
                                    marginLeft: '10px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px'
                                }}
                            />
                        )}
                    </Grid>

                </Grid>
            </form>
        </Box>
    );
};

export default AddCompanyComp;
