import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdDashboard, MdPeople, MdSettings, MdOutlineSubtitles } from "react-icons/md";
import { TbCreditCardRefund } from "react-icons/tb";
import { BsGraphUpArrow } from "react-icons/bs";
import { HiCalendar } from "react-icons/hi";
import { BsCart2 } from "react-icons/bs";
import { useSelector } from "react-redux";
import { BiSolidUserPin } from "react-icons/bi";
import AdminSidebar from "../components/AdminSidebar";
import AdminDashboardPage from "../components/AdminDashboardPage";
import Employee from "../../company-dashboard/pages/Employee";
import Companies from "../../company-dashboard/pages/Companies";
import SubAdmin from "../../company-dashboard/pages/SubAdmin";
import AddCompany from "../../company-dashboard/pages/AddCompany";
import AdmminEmployee from "../../company-dashboard/pages/AdminEmployee";
import AdminEmployeeDetails from "../../company-dashboard/components/AdminEmployeeDetails";
import AdminAddEmployee from "../../company-dashboard/pages/AdminAddEmployee";
import AdminSetting from "../../company-dashboard/pages/AdminSetting";
import AdminPermissions from "../../company-dashboard/pages/AdminPermissions";
import EmployeeComp from "../../company-dashboard/components/EmployeeComp";
import PermissionDenied from "../../global/components/PermissionDenied";
import { PiUsersBold } from "react-icons/pi";
import { FiLock } from "react-icons/fi";
import { IoMdPricetags } from "react-icons/io";
import AdminPricingPlans from "../components/AdminPricingPlans";
import { RiBuilding4Line } from "react-icons/ri";
import AdminSales from "../../company-dashboard/pages/AdminSales";

export default function AdminDashboard() {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const { user_data } = useSelector(state => state.user);
    const [permissions, setPermissions] = useState([])
    const location = useLocation()


    function getPermissionKeys(arr) {
        return arr?.map(permission => permission?.permission_key);
    }

    useEffect(() => {
        const sub_admin_permissions = getPermissionKeys(user_data?.permissions)

        if (sub_admin_permissions) {
            setPermissions(sub_admin_permissions)
        }
    }, [user_data])

    // let current_path = window.location.pathname;

    const dashboard_items = [
        {
            title: 'Sales',
            link: '/admin/dashboard/sales',
            icon: <BsGraphUpArrow style={{ color: location.pathname === '/admin/dashboard/sales' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Companies',
            link: '/admin/dashboard',
            icon: <RiBuilding4Line style={{ color: location.pathname === '/admin/dashboard' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Employees',
            link: '/admin/dashboard/employees',
            icon: <PiUsersBold style={{ color: location.pathname === '/admin/dashboard/employees' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Sub Admins',
            link: '/admin/dashboard/sub-admins',
            icon: <PiUsersBold style={{ color: location.pathname === '/admin/dashboard/sub-admins' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Permissions',
            link: '/admin/dashboard/permissions',
            icon: <FiLock style={{ color: location.pathname === '/admin/dashboard/permissions' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Plans',
            link: '/admin/dashboard/plans',
            icon: <IoMdPricetags style={{ color: location.pathname === '/admin/dashboard/plans' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Settings',
            link: '/admin/dashboard/setting',
            icon: <MdSettings style={{ color: location.pathname === '/admin/dashboard/setting' ? 'white' : '#c9c9c9' }} />,
        }
    ]

    return (
        <>
            {
                isDesktop ? (
                    <>
                        <div className="layout-container">
                            <div className="sidebar-container">
                                <AdminSidebar dashboard_items={dashboard_items} />
                            </div>
                            <div className="content-container" style={{ paddingTop: '1%' }}>
                                <Routes>

                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('companies') ? (
                                                <Route element={<Companies />} path="/" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/" />
                                            )
                                        ) : (
                                            <Route element={<Companies />} path="/" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('employees') ? (
                                                <Route element={<AdmminEmployee isAdmin={true} />} path="/employees" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/employees" />
                                            )
                                        ) : (
                                            <Route element={<AdmminEmployee isAdmin={true} />} path="/employees" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('sales') ? (
                                                <Route element={<AdminSales isAdmin={true} />} path="/sales" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/sales" />
                                            )
                                        ) : (
                                            <Route element={<AdminSales isAdmin={true} />} path="/sales" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/sub-admins" />
                                        ) : (
                                            <Route element={<SubAdmin />} path="/sub-admins" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/permissions" />
                                        ) : (
                                            <Route element={<AdminPermissions />} path="/permissions" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('companies') ? (
                                                <Route element={<AddCompany />} path="/addcompany" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/addcompany" />
                                            )
                                        ) : (
                                            <Route element={<AddCompany />} path="/addcompany" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('plans') ? (
                                                <Route element={<AdminPricingPlans screen='admin' />} path="/plans" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/plans" />
                                            )
                                        ) : (
                                            <Route element={<AdminPricingPlans screen='admin' />} path="/plans" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('employees') ? (
                                                <Route element={<EmployeeComp isAdmin={true} />} path="/employeedetails" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/employeedetails" />
                                            )
                                        ) : (
                                            <Route element={<EmployeeComp isAdmin={true} />} path="/employeedetails" />
                                        )
                                    }


                                    {
                                        user_data?.isSubAdmin ? (
                                            permissions?.includes('employees') ? (
                                                <Route element={<AdminAddEmployee />} path="/addemployee" />
                                            ) : (
                                                <Route element={<PermissionDenied title="You don't have Permission to view this page" />} path="/addemployee" />
                                            )
                                        ) : (
                                            <Route element={<AdminAddEmployee />} path="/addemployee" />
                                        )
                                    }

                                    <Route element={<AdminSetting />} path="/setting" />
                                </Routes>
                            </div>
                        </div>
                    </>
                ) : (
                    <>This Web App is not made for small screen devices</>
                )
            }
        </>
    )
}