import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../global/components/SearchComponent";
import HolidaysTable from "./tables/HolidaysTable";
import AddHolidaysModal from "./AddHolidaysModal";
import TableLoader from "../../global/components/TableLoader";
import Empty from "../../global/components/Empty";
import { useSelector } from "react-redux";

export default function HolidaysComp() {

    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [searchState, setSearchState] = useState(false);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            if (value !== '') {
                searchData();
                setSearchState(true);
            } else {
                setSearchState(false);
                getAllHolidays();
            }
        }, 500);
    };

    const searchData = async () => {
        setLoading(true);
        try {
            const response = await get_data(`holidays/search-holidays/${value}/${user_data?._id}?pageNumber=${currentPage}`);
            if (response.status) {
                setData(response?.data?.holidays);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalHolidays);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const getAllHolidays = async () => {
        setLoading(true);
        try {
            const response = await get_data(`holidays/get-all-holidays/${user_data?._id}?pageNumber=${currentPage}`);
            if (response.status) {
                setData(response?.data?.holidays);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalHolidays);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchData();
            } else {
                getAllHolidays();
            }
        }
    }, [currentPage, refresh, searchState]);

    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = [
        "S No.", "Holiday Name", "From Date", "To Date",
    ];

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [searchState, currentPage, refresh]);

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>
                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                        <SearchComponent handleSearch={handleSearch} setValue={setValue} value={value} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <AddHolidaysModal refresh={refresh} setRefresh={setRefresh} />
                    </div>
                </div>

                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <HolidaysTable
                                            type='employee'
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllHolidays={getAllHolidays}
                                            refresh={refresh}
                                            setRefresh={setRefresh}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Grid>
        </>
    );
}
