import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { IoMdAdd } from "react-icons/io";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';
import { createButtonColor, primaryColor, secondaryColor } from '../../../constant';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
};

export default function AddRoles({ open, setOpen, isEdit, updateData, setUpdateData, fetchData }) {
    const [errors, setErrors] = useState({});
    const [rolesName, setRolesName] = useState('');
    const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);

    const { user_data } = useSelector(state => state.user);

    const handleClose = () => {
        setRolesName('');
        setSelectedPermissionIds([]);
        setOpen(false);
    };

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const fetchPermissions = async () => {
        let result = await get_data(`company-permissions/get-all-company-permissions-for-options/${user_data?._id}`);
        if (result?.status) {
            setPermissionsList(result?.data);
        }
    };

    React.useEffect(() => {
        fetchPermissions();
        if (isEdit) {
            setRolesName(updateData?.name);
            setSelectedPermissionIds(updateData?.permissions?.length > 0 ? updateData?.permissions?.map((item) => item?._id) : []);
        }
    }, [updateData]);

    const validation = () => {
        let error = false;

        if (selectedPermissionIds.length === 0) {
            error = true;
            handleError('Please select at least one permission', 'selectedPermissionIds');
        }
        if (!rolesName) {
            error = true;
            handleError('Please input Roles Name', 'rolesName');
        }

        return error;
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            const body = {
                name: rolesName,
                permissions: selectedPermissionIds,
                company_id: user_data?._id
            };

            let result = await post_data(`roles/create-role`, body);
            if (result?.status) {
                fetchData();
                handleClose();
                toast.success('Roles added successfully');
            }
            else {
                toast.error("something went wrong");
            }
        }
    };

    const handleUpdate = async () => {
        const hasError = validation();
        if (!hasError) {
            const body = {
                name: rolesName,
                permissions: selectedPermissionIds
            };

            let result = await post_data(`roles/update-role/${updateData?._id}`, body);
            if (result?.status) {
                toast.success('Roles updated successfully');
                fetchData();
                handleClose();
            }
            else {
                toast.error("something went wrong");
            }
        }
    };



    const handlePermissionChange = (event) => {
        setSelectedPermissionIds(event.target.value);
    };

    const handleOpen = () => setOpen(true);

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                value={rolesName}
                                onFocus={() => handleError('', 'rolesName')}
                                error={Boolean(errors.rolesName)}
                                helperText={errors.rolesName}
                                onChange={(e) => setRolesName(e.target.value)}
                                label="Roles Name"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Select Permissions</InputLabel>
                                <Select
                                    multiple
                                    value={selectedPermissionIds}
                                    onChange={handlePermissionChange}
                                    label="Select Permissions"
                                    renderValue={(selected) => selected.map(id => permissionsList?.find(option => option._id === id)?.permission_name).join(', ')}
                                >
                                    {permissionsList?.map(permission => (
                                        <MenuItem key={permission._id} value={permission._id}>
                                            {permission.permission_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors?.selectedPermissionIds && (
                                    <Typography variant="body2" color="error">
                                        {errors?.selectedPermissionIds}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: '20px 0px' }}>
                        <Button onClick={isEdit ? handleUpdate : handleSubmit} style={{ background: secondaryColor, color: '#fff' }} fullWidth>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
