import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { IoMdAdd } from "react-icons/io";
import { useState } from 'react';
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { primaryColor, createButtonColor, secondaryColor } from '../../../constant';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',

};

export default function AddShiftModal({ refresh, setRefresh, isEdit, shiftId }) {

    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [shiftName, setShiftName] = useState('')
    const [startDays, setStartDays] = useState('')
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [days, setDays] = useState('')

    const { isLoggedIn, user_data } = useSelector(state => state.user);

    const handleClose = () => {
        setOpen(false)
        setDays('')
        setEndTime(null)
        setStartTime(null)
    }

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const fetchShiftDetails = async () => {
        let result = await get_data(`shifts/get-shift/${shiftId}`)
        if (result?.status) {
            setShiftName(result?.data?.name)
            setStartTime(dayjs(result?.data?.start_time));
            setEndTime(dayjs(result?.data?.end_time));
            setDays(result?.data?.days)
        }
    }

    React.useEffect(function () {
        fetchShiftDetails()
    }, [])

    const validation = () => {
        let error = false;

        if (!days) {
            error = true;
            handleError('Please input Days', 'days');
        }

        if (!startTime) {
            error = true;
            handleError('Please input Start Time', 'days');
        }

        if (!endTime) {
            error = true;
            handleError('Please input End Time', 'days');
        }

        return error;
    };


    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            if (isEdit) {
                let body = {
                    name: shiftName,
                    company_id: user_data?._id,
                    start_time: startTime,
                    end_time: endTime,
                    days
                }
                let result = await post_data(`shifts/update-shift/${shiftId}`, body)
                if (result?.status) {
                    toast.success('Shift updated successfully');
                    handleClose()
                    setRefresh(!refresh)
                }
            } else {
                let body = {
                    name: shiftName,
                    company_id: user_data?._id,
                    start_time: startTime,
                    end_time: endTime,
                    days
                }
                let result = await post_data('shifts/create-shift', body)
                if (result?.status) {
                    toast.success('Shift created successfully');
                    handleClose()
                    setRefresh(!refresh)
                }
            }
        }
    };


    const handleOpen = () => setOpen(true);

    return (
        <div>
            <div onClick={handleOpen}>
                {isEdit ? <div>Edit</div> : <Button startIcon={<IoMdAdd />} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                    Add Shifts
                </Button>}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                            <TextField
                                type='text'
                                value={shiftName}
                                onFocus={() => handleError('', 'shiftName')}
                                error={errors.shiftName}
                                helperText={errors.shiftName}
                                onChange={(e) => setShiftName(e.target.value)}
                                label='Shift Name' fullWidth />
                        </Grid> */}

                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <TimePicker
                                        label="Start Time"
                                        value={startTime}
                                        onChange={(newValue) => setStartTime(newValue)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <span style={{ margin: '0 8px' }}> - </span>
                                    <TimePicker
                                        label="End Time"
                                        value={endTime}
                                        onChange={(newValue) => setEndTime(newValue)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="days-select-label">Days</InputLabel>
                                <Select
                                    labelId="days-select-label"
                                    value={days}
                                    onChange={(e) => setDays(e.target.value)}
                                    onFocus={() => handleError('', 'days')}
                                    error={errors.days}
                                >
                                    <MenuItem value={'Monday - Friday'}>Monday - Friday</MenuItem>
                                    <MenuItem value={'Monday - Saturday'}>Monday - Saturday</MenuItem>
                                </Select>
                                {errors.days && <Typography variant="caption" color="error">{errors.days}</Typography>}
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Box sx={{ minWidth: 120, padding: '20px 0px 20px 0px', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Button onClick={handleSubmit} style={{ background: `${secondaryColor}`, color: '#fff' }} fullWidth>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}