import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IoMdAdd } from 'react-icons/io';
import { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { primaryColor, createButtonColor, secondaryColor } from '../../constant';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../api';
import dayjs from 'dayjs';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

export default function AddHolidaysModal({ refresh, setRefresh, isEdit, holidayId }) {

    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [holidayName, setHolidayName] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const { isLoggedIn, user_data } = useSelector(state => state.user);

    const handleClose = () => {
        setOpen(false)
        setHolidayName('')
        setFromDate(null)
        setToDate(null)
    }

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const fetchHoliDayDetails = async () => {
        let result = await get_data(`holidays/get-holidays/${holidayId}`)
        if (result?.status == true) {
            setHolidayName(result?.data?.name);
            setFromDate(dayjs(result?.data?.from_date));
            setToDate(dayjs(result?.data?.to_date));
        }
    }

    React.useEffect(function () {
        fetchHoliDayDetails()
    }, [])

    const validate = () => {
        let hasError = false;
        if (!holidayName.trim()) {
            handleError('Please input Holiday Name', 'holidayName');
            hasError = true;
        } if (!fromDate) {
            handleError('Please select a date', 'fromDate');
            hasError = true;
        }
        if (!toDate) {
            handleError('Please select a date', 'toDate');
            hasError = true;
        }

        return hasError;
    };
    const handleSubmit = async () => {
        const hasError = validate();
        if (!hasError) {
            if (isEdit == true) {
                let body = {
                    company_id: user_data?._id,
                    name: holidayName,
                    from_date: fromDate,
                    to_date: toDate,
                }
                let result = await post_data(`holidays/update-holidays/${holidayId}`, body)
                if (result?.status == true) {
                    toast.success('Holiday updated successfully');
                    handleClose()
                    setRefresh(!refresh)
                }
            } else {
                let body = {
                    company_id: user_data?._id,
                    name: holidayName,
                    from_date: fromDate,
                    to_date: toDate,
                }
                let result = await post_data('holidays/create-holidays', body)
                if (result?.status == true) {
                    toast.success('Holiday created successfully');
                    handleClose()
                    setRefresh(!refresh)
                }
            }
            handleClose();
        }
    };

    const handleOpen = () => setOpen(true);

    return (
        <div>
            <div onClick={handleOpen}>
                {isEdit ? <div>Edit</div> : <Button startIcon={<IoMdAdd />} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                    Add Holiday
                </Button>}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                value={holidayName}
                                onChange={(e) => setHolidayName(e.target.value)}
                                onFocus={() => handleError('', 'holidayName')}
                                error={!!errors.holidayName}
                                helperText={errors.holidayName}
                                label="Holiday Name"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6} style={{ width: '100%', }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        setFromDate(newValue)
                                        setErrors({ ...errors, fromDate: false })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!errors.fromDate}
                                            helperText={errors.fromDate}
                                            fullWidth
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            {errors.fromDate ? <span style={{ color: 'red', marginLeft: '4%', marginTop: '2%' }}>{errors.fromDate}</span> : <></>}
                        </Grid>


                        <Grid item xs={6} style={{ width: '100%' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={toDate}
                                    onChange={(newValue) => {
                                        setToDate(newValue);
                                        setErrors({ ...errors, toDate: false })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={!!errors.toDate}
                                            helperText={errors.toDate}
                                            fullWidth
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            {errors.toDate ? <span style={{ color: 'red', marginLeft: '4%', marginTop: '2%' }}>{errors.toDate}</span> : <></>}
                        </Grid>

                    </Grid>
                    <Box sx={{ padding: '20px 0', display: 'flex', justifyContent: 'space-between', gap: 2, }}>
                        <Button onClick={handleSubmit} style={{ background: secondaryColor, color: '#fff' }} fullWidth >
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
