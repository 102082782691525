import Header from "../components/Header"
import Footer from "../components/Footer"

export default function PrivacyPolicy() {

    return (
        <>
            <Header />
            <div style={{ background: "#F4F5F6", padding: '4% 8%' }}>
                <h2 style={{ color: 'black', margin: 0, fontWeight: 500, fontSize: 40, textAlign: 'left', marginBottom: 0 }}>
                    Privacy Policy
                </h2>
            </div>
            <div style={{ padding: '2% 8%' }}>

                <div style={{ lineHeight: '1.6', marginTop: 0 }}>
                </div>

            </div>
            <Footer />
        </>
    )
}