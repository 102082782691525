import daysjs from "dayjs"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

export const primaryColor = "#1d2c2d"
// export const primaryColor = '#06121E'
// export const secondaryColor = '#0069ff'
export const secondaryColor = "#40c944"
export const createButtonColor = '#007e59'

export const formatPrice = (value) => {
    const formatted_price = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value || 0);
    return formatted_price
}

// export const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getUTCFullYear();
//     let month = date.getUTCMonth() + 1;
//     let day = date.getUTCDate();
//     month = month < 10 ? '0' + month : month;
//     day = day < 10 ? '0' + day : day;
//     const outputDate = day + '-' + month + '-' + year;
//     return outputDate;
// }

export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date?.getFullYear();  // Use getFullYear() instead of getUTCFullYear()
    let month = date?.getMonth() + 1;  // Use getMonth() instead of getUTCMonth()
    let day = date?.getDate();         // Use getDate() instead of getUTCDate()

    // Ensure month and day are two digits
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    const outputDate = `${day}-${month}-${year}`;
    return outputDate;
};


const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatDateTime = (date) => {
    let dateObject = new Date(date) || ''
    let day = dateObject?.getDate();
    let month = months[dateObject?.getMonth()];
    let year = dateObject?.getFullYear();
    let hours = dateObject?.getHours();
    let minutes = dateObject?.getMinutes();
    let formattedTime = hours >= 12 ? 'PM' : 'AM';
    let formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    let formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedDateTime = `${day} ${month} ${year} at ${formattedHours}:${formattedMinutes} ${formattedTime}`;
    return formattedDateTime
}


export const formatTime = (date) => {
    let dateObject = new Date(date) || '';
    let hours = dateObject?.getHours();
    let minutes = dateObject?.getMinutes();
    let formattedTime = hours >= 12 ? 'PM' : 'AM';
    let formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    let formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTimeString = `${formattedHours}:${formattedMinutes} ${formattedTime}`;
    return formattedTimeString;
};


export const getAddressFromCoordinates = async (latitude, longitude) => {
    const apiKey = 'AIzaSyD8oFz3vOQ_4-Xsc_Mfv0m6gCBDCE3Hcr4';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === "OK") {
            const address = data?.results?.[0]?.formatted_address;
        } else {
        }
    } catch (error) {
    }
};



export function getValueByPermissionName(arr, targetPermissionName) {
    const foundObj = arr?.find(item => item?.permission_name === targetPermissionName);
    return foundObj ? foundObj?.value : null;
}


export const fetchAddress = async (lat, long) => {
    const apiKey = "AIzaSyD8oFz3vOQ_4-Xsc_Mfv0m6gCBDCE3Hcr4";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === "OK") {
            const formattedAddress = data.results[0]?.formatted_address;
            return formattedAddress
            // setAddress(formattedAddress || "Address not found");
        } else {
            console.error("Error fetching address:", data.error_message);
            // setAddress("Failed to fetch address");
            return "Failed to fetch address"
        }
    } catch (error) {
        console.error("Error:", error);
        // setAddress("Error occurred");
        return "Error occurred"
    }
};