import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CiFilter } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { primaryColor, createButtonColor, secondaryColor } from '../../../constant';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

export default function AddDesignationModal({ handleAddDesignation, refresh, setRefresh, isEdit, designationId }) {

    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [designation, setDesignation] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [departmentList, setDepartmentList] = useState([])
    const [departmentId, setDepartmentId] = useState([])

    const { user_data } = useSelector(state => state.user);

    const getAllDepartments = async () => {
        const data = await get_data(`department/get-all-departments-by-company-for-options/${user_data?._id}`);
        if (data.status) {
            setDepartmentList(data?.data)
        }
    }

    const fetchDesignationDetails = async () => {
        let result = await get_data(`designation/get-designation/${designationId}`)
        if (result?.status == true) {
            setDesignation(result?.data?.name)
            setDepartmentId(result?.data?.department_id?._id)
        }
    }

    React.useEffect(function () {
        getAllDepartments()
        fetchDesignationDetails()
    }, [refresh])

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!designation?.trim()) {
            error = true;
            handleError('Please input Designation', 'designation');
        }

        if (departmentId.length === 0) {
            error = true;
            handleError('Please select department', 'department');
        }

        return error;
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            if (isEdit) {
                let body = {
                    name: designation,
                    company_id: user_data?._id,
                    department_id: departmentId,
                }
                let result = await post_data(`designation/update-designation/${designationId}`, body)
                if (result?.status == true) {
                    toast.success('Designation updated successfully!');
                    handleClose()
                    setRefresh(!refresh)
                }
            } else {
                let body = {
                    name: designation,
                    company_id: user_data?._id,
                    department_id: departmentId,
                }
                let result = await post_data('designation/create-designation', body)
                if (result?.status == true) {
                    toast.success('Designation created successfully!');
                    handleClose()
                    setRefresh(!refresh)
                }
            }
        }
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setDepartmentId('')
        setDesignation('')
    }

    return (
        <div>

            <div onClick={handleOpen}>
                {isEdit ? <div>Edit</div> : <Button startIcon={<IoMdAdd />} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                    Add Designation
                </Button>}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField
                        value={designation}
                        onFocus={() => handleError('', 'designation')}
                        error={errors.designation}
                        helperText={errors.designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        label='Add Designation' fullWidth />

                    <TextField select fullWidth
                        onChange={(e) => { setDepartmentId(e.target.value) }}
                        onFocus={() => handleError('', 'department')}
                        error={errors.department}
                        helperText={errors.department}
                        label="Select Department"
                        style={{ marginTop: '15px' }}
                        value={departmentId}
                    >
                        {departmentList?.map((item) => {
                            return <MenuItem value={item?._id} >{item?.name}</MenuItem>
                        })}
                    </TextField>

                    <Box sx={{ minWidth: 120, padding: '20px 0px 20px 0px', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Button onClick={handleSubmit} style={{ background: `${secondaryColor}`, color: '#fff' }} fullWidth>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}