import { Button, Divider, Grid, TextField, Typography, Box, InputLabel, MenuItem, FormControl, Select, Autocomplete, IconButton, InputAdornment, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, Avatar } from "@mui/material";
import { useState, useEffect } from "react";
import { get_data, post_data, serverURL } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { getValueByPermissionName, primaryColor, secondaryColor } from "../../constant";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast, { Toaster } from 'react-hot-toast';
import moment from "moment";
import dayjs from 'dayjs';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChangePassModal from "../../global/components/ChangePassModal";


const sections = [
    {
        title: 'Profile Information',
        fields: [
            { name: 'first_name', label: 'First Name', type: 'text' },
            { name: 'last_name', label: 'Last Name', type: 'text' },
            // { name: 'profile_picture', label: 'Profile Picture', type: 'file' },
            { name: 'password', label: 'Password', type: 'password' }
        ]
    },
    {
        title: 'General Information',
        fields: [
            { name: 'salary', label: 'Salary', type: 'number' },
            { name: 'client', label: 'Client', type: 'autocomplete' },
            { name: 'department', label: 'Department', type: 'autocomplete' },
            { name: 'designation', label: 'Designation', type: 'autocomplete' },
            { name: 'shift', label: 'Shift', type: 'autocomplete' },
            // { name: 'roles', label: 'Roles', type: 'autocomplete' },
        ]
    },
    {
        title: 'Personal Information',
        fields: [
            { name: 'country', label: 'Country', type: 'autocomplete' },
            { name: 'state', label: 'State', type: 'autocomplete' },
            { name: 'city', label: 'City', type: 'autocomplete' },
            { name: 'gender', label: 'Gender', type: 'select', options: ['Male', 'Female'] },
            { name: 'dob', label: 'Date of Birth', type: 'date', placeholder: 'MM/DD/YYYY' },
            { name: 'email', label: 'Email', type: 'text' },
            { name: 'phone', label: 'Phone No.', type: 'number' },
            { name: 'street', label: 'Street', type: 'text' },
            { name: 'zip_code', label: 'Zip Code', type: 'text' }
        ]
    },
    {
        title: 'Employment Information',
        fields: [
            { name: 'hire_date', label: 'Date of hire', type: 'date', placeholder: 'MM/DD/YYYY' },
            { name: 'date_of_joining', label: 'Date of Joining', type: 'date', placeholder: 'MM/DD/YYYY' },
            // { name: 'aadhar_card_img', label: 'Aadhar Card Image', type: 'file' },
            // { name: 'pan_card_img', label: 'PAN Card Image', type: 'file' },
            { name: 'pan_card', label: 'PAN Number', type: 'text' },
            { name: 'aadhar_card', label: 'Aadhar Number', type: 'number' },
            // { name: 'paid_leaves', label: 'Paid Leaves', type: 'number' },
            // { name: 'unpaid_leaves', label: 'Unpaid Leaves', type: 'number' }
        ]
    }
];

export default function AddEmployeeComp({ limitedPlanPermissions }) {

    const location = useLocation();
    const employee = location.state?.employee;

    const navigate = useNavigate();
    const [designations, setDesignations] = useState([]);
    const [countries, setCountries] = useState(Country.getAllCountries());
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [shifts, setShifts] = useState([]);
    const { user_data } = useSelector(state => state.user);
    const { member_data } = useSelector(state => state.member);
    const [loading, setLoading] = useState(false);
    const [clientsList, setClientsList] = useState([])
    const initialFormValues = sections.reduce((acc, section) => {
        section?.fields?.forEach(field => {
            acc[field.name] = null;
        });
        return acc;
    }, {});

    const [formValues, setFormValues] = useState(initialFormValues);
    const [isUpdate, setIsUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [changePassOpen, setChangePassOpen] = useState(false)

    let employeeId = localStorage?.getItem('employeeId');

    const [locationTracking, setLocationTracking] = useState(false);


    const handleError = (error, field) => {
        setErrors(prev => ({ ...prev, [field]: error }));
    };


    const fetchClients = async () => {
        let result = await get_data(`clients/get-all-clients-by-company-for-options/${user_data?._id}`)
        if (result?.status) {
            setClientsList(result?.data)
        }
    }

    const fetchRoles = async () => {
        try {
            let result = await get_data(`roles/get-all-roles-for-options/${user_data?._id}`);
            if (result?.status) {
                setRoles(result?.data?.map(role => role?.name));
            } else {
                setRoles([]);
            }
        } catch (error) {
            setRoles([]);
        }
    };

    const fetchDesignations = async () => {
        try {
            let result = await get_data(`designation/get-all-designations-by-company-for-options/${user_data?._id}`);
            if (result?.status === true && Array.isArray(result.data)) {
                setDesignations(result?.data);
            } else {
                setDesignations([]);
            }
        } catch (error) {
            setDesignations([]);
        }
    };

    const getAllDepartments = async () => {
        try {
            const data = await get_data(`department/get-all-departments-by-company-for-options/${user_data?._id}`);
            if (data.status) {
                setDepartments(data?.data);
            }
        } catch (error) {
            setDepartments([]);
        }
    };

    const fetchShifts = async () => {
        try {
            let result = await get_data(`shifts/get-all-shifts-by-company-for-options/${user_data?._id}`);
            if (result?.status === true && Array.isArray(result.data)) {
                setShifts(result?.data);
            } else {
                setShifts([]);
            }
        } catch (error) {
            setShifts([]);
        }
    };


    useEffect(() => {
        fetchRoles();
        fetchDesignations();
        getAllDepartments();
        fetchShifts();
        fetchClients()
    }, [user_data]);

    useEffect(() => {
        fetchClients()
    }, []);

    useEffect(() => {
        if (employee) {
            setIsUpdate(true);
            const updatedValues = {};

            sections?.forEach(section => {
                section?.fields?.forEach(field => {
                    if (field?.name in employee) {
                        if (field?.name === 'date_of_joining' || field?.name === 'hire_date' || field?.name === 'dob') {
                            // <<<<<<< HEAD
                            //                             updatedValues[field?.name] = dayjs(employee[field?.name]);
                            // =======
                            const date = new Date(employee[field?.name]);
                            updatedValues[field?.name] = date;

                        } else if (field?.name === 'salary') {
                            updatedValues[field?.name] = employee[field?.name]?.base_salary || 0;
                        } else {
                            updatedValues[field?.name] = employee[field?.name] || null;
                        }
                    } else if (field?.name in employee?.address) {
                        updatedValues[field?.name] = employee?.address[field?.name] || null;
                    } else if (field?.name in employee?.designation) {
                        updatedValues[field?.name] = employee?.designation?._id || null;
                    } else if (field?.name in employee?.department) {
                        updatedValues[field?.name] = employee?.department?._id || null;
                    }
                    // else if (field?.name in employee?.client) {
                    //     updatedValues[field?.name] = employee?.client?._id || '';
                    // }

                    else if (field?.name === 'hire_date') {
                        const formattedDate = moment(employee[field?.name]).format('YYYY-MM-DD') || null;
                        updatedValues[field?.name] = formattedDate || '';
                    }

                    else {
                        updatedValues[field?.name] = null;
                    }
                });
            });

            setLocationTracking(employee?.location_tracking);

            setFormValues(updatedValues);

            if (employee?.address?.country) {
                const selectedCountry = countries?.find(country => country.isoCode === employee?.address?.country);
                if (selectedCountry) {
                    setStates(State.getStatesOfCountry(selectedCountry.isoCode));
                }
            }
            if (employee?.address?.state) {
                const selectedState = State.getStatesOfCountry(employee?.address?.country)?.find(state => state.isoCode === employee?.address?.state);
                if (selectedState) {
                    setCities(City.getCitiesOfState(employee?.address?.country, selectedState.isoCode));
                }
            }
        }
    }, [employee, countries]);

    useEffect(() => {
        if (formValues.country) {
            const selectedCountry = countries?.find(country => country.isoCode === formValues.country);
            if (selectedCountry) {
                setStates(State.getStatesOfCountry(selectedCountry.isoCode));
            } else {
                setStates([]);
            }
        }
    }, [formValues.country, countries]);

    useEffect(() => {
        if (formValues.state) {
            const selectedState = states?.find(state => state.isoCode === formValues.state);
            if (selectedState) {
                setCities(City.getCitiesOfState(formValues.country, selectedState.isoCode));
            } else {
                setCities([]);
            }
        }
    }, [formValues.state, states]);
    
    const validation = () => {
        let error = false;
        Object.keys(formValues).forEach(key => {
            const value = formValues[key];
            if (key !== 'profile_picture' && key !== 'aadhar_card' && key !== 'pan_card' && key !== 'pan_card_img' && key !== 'aadhar_card_img' && key !== 'client') { // Exclude profile picture, Aadhar card, and PAN card from validation
                // if (!value?.toString()?.trim()) {
                //     error = true;
                //     handleError('This field is required.', key);
                if (key === 'email' && !/^\S+@\S+\.\S+$/.test(value)) {
                    error = true;
                    handleError('Invalid email address', key);
                } else if (key === 'phone' && !/^\d{10}$/.test(value)) {
                    error = true;
                    handleError('Invalid phone number', key);
                } else if (key === 'password' && !formValues?.password) {
                    error = true;
                    handleError('Enter password', key);
                }
                //  else if (key === 'first_name' && !/^[a-zA-Z ]+$/.test(value)) {
                //     error = true;
                //     handleError('Invalid first name', key);
                // } else if (key === 'last_name' && !/^[a-zA-Z ]+$/.test(value)) {
                //     error = true;
                //     handleError('Invalid last name', key);
                // } else if (key === 'city' && !cities.some(city => city?.name === value)) {
                //     error = true;
                //     handleError('Invalid city name', key);
                // } else if (key === 'state' && !states.some(state => state?.isoCode === value)) {
                //     error = true;
                //     handleError('Invalid state code', key);
                // } else if (key === 'country' && !countries.some(country => country?.isoCode === value)) {
                //     error = true;
                //     handleError('Invalid country code', key);
                // } else if (key === 'department' && !formValues[key]) {
                //     error = true;
                //     handleError('Department is required.', key);
                // } else if (key === 'designation' && !formValues[key]) {
                //     error = true;
                //     handleError('Designation is required.', key);
                // } else {
                //     handleError('', key);
                // }
            }
        });

        console.log('error', error)

        return error;
    };
    console.log('erroers', errors)

    const handleChange = (event, field) => {

        const value = field === 'picture' ? event.target.files[0] : event.target.value;

        if (field === 'country') {
            setFormValues(prev => ({ ...prev, [field]: value?.isoCode }));
            setStates([]);
            setCities([]);


            // const selectedCountry = countries.find(country => country.name === value);
            // if (selectedCountry) {
            //     setFormValues(prev => ({ ...prev, [field]: selectedCountry.isoCode }));
            //     setStates(State.getStatesOfCountry(selectedCountry.isoCode));
            //     setCities([]);
            // } else {
            //     setFormValues(prev => ({ ...prev, [field]: value }));
            //     setStates([]);
            //     setCities([]);
            // }
        } else if (field === 'state') {
            setFormValues(prev => ({ ...prev, [field]: value?.isoCode }));
            setCities([]);
            // setCities(City.getCitiesOfState(formValues.country, value.isoCode));

            // const selectedState = states.find(state => state.name === value);
            // if (selectedState) {
            //     setFormValues(prev => ({ ...prev, [field]: selectedState.isoCode }));
            //     setCities(City.getCitiesOfState(formValues.country, selectedState.isoCode));
            // } else {
            //     setFormValues(prev => ({ ...prev, [field]: value }));
            //     setCities([]);
            // }
        } else if (field === 'city') {
            setFormValues(prev => ({ ...prev, [field]: value?.name }));
        }
        else {
            setFormValues(prev => ({ ...prev, [field]: value }));
        }
        handleError('', field);
    };

    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const handleUpdate = async () => {
        // if (validation()) {
        //     return;
        // }


        try {
            let updatedFormValues = {
                ...formValues,
                location_tracking: locationTracking,
                client: formValues?.client || null,
                salary: {
                    base_salary: formValues?.salary
                }
            };

            // Check if designation has changed
            const designationChanged = employee?.designation?._id !== formValues?.designation?._id;

            if (designationChanged && member_data?.is_member) {
                updatedFormValues = {
                    ...updatedFormValues,
                    designation: member_data?.is_member ? null : formValues?.designation,
                    member_id: member_data?.is_member ? member_data?._id : null,
                    is_designation_approved: member_data?.is_member ? false : true,
                    designation_for_approval: formValues?.designation,
                    designation_type: 'update',
                };
            } else {
                updatedFormValues = {
                    ...updatedFormValues,
                    designation: formValues?.designation,
                };
            }

            const selectedShift = shifts?.find(shift => `${shift.days}, ${new Date(shift.start_time).toLocaleTimeString()} - ${new Date(shift.end_time).toLocaleTimeString()}` === formValues.shift);
            if (selectedShift) {
                updatedFormValues.shift = selectedShift?._id;
            }

            setLoading(true)
            let response = await post_data(`employee/update-employee/${employeeId}`, updatedFormValues);
            if (response.status === true) {
                setLoading(false);
                toast.success('Employee updated successfully');
                navigate('/company/dashboard/employees');
            } else {
                setLoading(false)
                toast.error(response?.response?.data?.message || 'Something went wrong!');
            }
        } catch (error) {
            setLoading(false)
            // console.error(error);
        }
    };

    const handleSubmit = async () => {
        if (validation()) {
            return;
        }
        setLoading(true);
        try {
            var formData = new FormData()

            let updatedFormValues = {
                ...formValues,
                company_id: user_data?._id,
                client: formValues?.client || null,
                location_tracking: locationTracking,

                designation: member_data?.is_member ? null : formValues?.designation,
                member_id: member_data?.is_member ? member_data?._id : null,
                is_designation_approved: member_data?.is_member ? false : true,
                designation_for_approval: formValues?.designation,
                designation_type: 'new',

                salary: {
                    base_salary: formValues?.salary
                }
            };
            const selectedShift = shifts?.find(shift => `${shift.days}, ${new Date(shift?.start_time).toLocaleTimeString()} - ${new Date(shift?.end_time).toLocaleTimeString()}` === formValues?.shift);
            if (selectedShift) {
                updatedFormValues.shift = selectedShift?._id;
            }

            const response = await post_data("employee/create-employee", updatedFormValues);

            if (response.status === true) {
                setLoading(false);
                toast.success('Employee created successfully');
                navigate('/company/dashboard/employees')
            } else {
                setLoading(false);
                toast.error(response?.response?.data?.message || 'Something went wrong!');
            }
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <Grid container spacing={2} padding={2} margin={2} style={{ background: '#fff', border: '1px solid gainsboro', width: '98%', borderRadius: '5px' }}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 17, fontWeight: 500 }}>Regular Employee</Typography>
                </Grid>
                {sections?.map((section, sectionIndex) => (
                    <Grid item xs={12} key={sectionIndex}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography style={{ fontSize: 17, fontWeight: 500 }}>{section?.title}</Typography>
                            </Grid>
                            {section?.fields?.map((field, fieldIndex) => (
                                (formValues.country === 'IN' || (field.name !== 'aadhar_card_img' && field.name !== 'pan_card_img' && field.name !== 'pan_card' && field.name !== 'aadhar_card')) && (
                                    <Grid item xs={4} key={fieldIndex}>
                                        {field?.type === 'select' ? (
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                                                    <Select
                                                        labelId={`${field.name}-label`}
                                                        id={field.name}
                                                        value={formValues[field.name] || ""}
                                                        label={field.label}
                                                        error={Boolean(errors[field.name])}
                                                        onChange={(e) => handleChange(e, field.name)}
                                                    >
                                                        {field.options.map((option, optionIndex) => (
                                                            <MenuItem key={optionIndex} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {errors[field.name] && <span style={{ color: '#EA2024', fontSize: 14 }}>{errors[field.name]}</span>}
                                            </Box>
                                        ) : field.type === 'autocomplete' ? (
                                            <Autocomplete
                                                options={
                                                    field.name === 'designation' ? designations :
                                                        field.name === 'client' ? clientsList :
                                                            field.name === 'department' ? departments :
                                                                field.name === 'roles' ? roles :
                                                                    field.name === 'shift' ? shifts :
                                                                        field.name === 'country' ? countries :
                                                                            field.name === 'state' ? states :
                                                                                cities
                                                }

                                                getOptionLabel={
                                                    field.name === 'client' ?
                                                        (option) => option?.client_name || ""
                                                        :
                                                        field.name === 'shift'
                                                            ? (option) => `${option?.days || ''}, ${option?.start_time ? new Date(option.start_time).toLocaleTimeString() : ''} - ${option?.end_time ? new Date(option.end_time).toLocaleTimeString() : ''}`
                                                            : field.name === 'department' ? (option) => option?.name || '' : (option) => option?.name || ""
                                                }

                                                value={
                                                    field.name === 'city' ? cities?.find(city => city?.name === formValues[field.name]) || ''
                                                        : field.name === 'state'
                                                            ? states?.find(state => state.isoCode === formValues[field.name]) || '' :
                                                            field.name === 'country'
                                                                ? countries?.find(country => country.isoCode === formValues[field.name]) || '' :
                                                                field.name === 'shift'
                                                                    ? shifts?.find(shift => shift._id === formValues[field.name]) || null :
                                                                    field.name === 'designation'
                                                                        ? designations?.find(designation => designation?._id === formValues[field.name] || formValues[field.name]?._id) || '' :
                                                                        field.name === 'department'
                                                                            ? departments?.find(department => department?._id === formValues[field.name] || formValues[field.name]?._id) || '' :
                                                                            field.name === 'client'
                                                                                ? clientsList?.find(client => client?._id === formValues[field.name] || formValues[field.name]?._id) || '' :
                                                                                formValues[field.name] || ''
                                                }

                                                onChange={(event, newValue) =>
                                                    handleChange({
                                                        target: {
                                                            value: field.name === 'client' || field.name === 'department' || field.name === 'designation' || field.name === 'shift'
                                                                ? newValue?._id
                                                                : newValue
                                                        }
                                                    }, field.name)
                                                }

                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={field.label}
                                                        error={Boolean(errors[field.name])}
                                                        helperText={errors[field.name]}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        ) : field.type === 'password' ? (<>
                                            {isUpdate
                                                ?
                                                <div onClick={() => setChangePassOpen(true)} style={{ color: secondaryColor, fontSize: 14, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>Change Password</div>
                                                :
                                                <TextField
                                                    label={field.label}
                                                    type={showPassword ? 'text' : 'password'}
                                                    // value={formValues[field.name]}
                                                    onChange={(e) => handleChange(e, field.name)}
                                                    error={Boolean(errors[field.name])}
                                                    helperText={errors[field.name]}
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            }
                                        </>
                                        )
                                            : (
                                                <>
                                                    <TextField
                                                        label={field.label}
                                                        type={field.type}
                                                        placeholder={field.placeholder || ''}
                                                        // value={field.type == 'date' ? new Date(formValues[field.name]) : field.type !== 'file' ? formValues[field.name] : undefined}
                                                        value={
                                                            field.type === 'date'
                                                                ? formValues[field.name]
                                                                    ? dayjs(formValues[field.name]).format('YYYY-MM-DD') // Customize the date format as needed
                                                                    : ''
                                                                : field.type !== 'file'
                                                                    ? formValues[field.name]
                                                                    : undefined
                                                        }
                                                        onChange={(e) => handleChange(e, field.name)}
                                                        error={Boolean(errors[field.name])}
                                                        helperText={errors[field.name]}
                                                        fullWidth
                                                    // InputLabelProps={field.type === 'file' ? { shrink: true } : {}}
                                                    />
                                                </>
                                            )}
                                    </Grid>


                                )
                            ))}
                            <Grid item xs={12}>
                                <Divider style={{ margin: '10px 0px' }} />
                            </Grid>

                        </Grid>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <ChangePassModal open={changePassOpen} data={employee} setOpen={setChangePassOpen} type={'EditEmployee'} />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={locationTracking}
                                onChange={(event) => setLocationTracking(event.target.checked)}
                                color="primary"
                            />
                        }
                        label="Track Location"
                    />

                    <div style={{ display: 'flex', marginLeft: 'auto', gap: 10 }}>
                        <Button variant="outlined" style={{ borderColor: primaryColor, color: primaryColor }}>Back</Button>
                        <Button style={{ boxShadow: 'none', background: secondaryColor }}
                            disabled={loading}
                            onClick={isUpdate ? handleUpdate : handleSubmit} variant="contained">
                            {isUpdate ? loading ? 'Update...' : 'Update' : loading ? 'Save...' : 'Create'}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
