import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { useSelector } from "react-redux";
import { createButtonColor } from "../../constant";
import toast from "react-hot-toast";


export default function Penalty() {

    const [loading, setLoading] = useState(true);
    const [penaltyData, setPenaltyData] = useState(null);
    const { user_data } = useSelector(state => state.user);
    const [formData, setFormData] = useState({ penalty_amount: penaltyData?.penalty_amount || '', company_id: user_data?._id });

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };

    useEffect(() => {
        if (penaltyData) {
            setFormData({
                penalty_amount: penaltyData?.penalty_amount
            })
        }
    }, [penaltyData])


    const getPenaltyAmount = async () => {
        setLoading(true);
        try {
            const response = await get_data(`global/get-global/${user_data?._id}`);
            if (response.status) {
                setPenaltyData(response?.data);
            } else {
                setPenaltyData(null)
            }
        } catch (error) {
            setPenaltyData(null)
        } finally {
        }
    };

    useEffect(() => {
        getPenaltyAmount();
    }, [user_data]);

    const handleSubmit = async () => {
        let response
        penaltyData ? response = await post_data(`global/update-penalty_amount/${user_data?._id}`, { penalty_amount: formData?.penalty_amount }) : response = await post_data(`global/create-global`, { ...formData });
        if (response.status === true) {
            toast.success('Penalty amount updated')
            setFormData('');
            getPenaltyAmount();
        } else {
            toast.error('Something went wrong!')
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [user_data]);

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={page_container}>
                    <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: "10px 20px", marginBottom: 20 }} >
                        <Typography variant="h6" fontWeight="500" gutterBottom>
                            Penalty Amount
                        </Typography>
                    </Box>

                    <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }} >
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={4}>
                                <TextField
                                    onChange={(e) => setFormData({ ...formData, penalty_amount: e.target.value })}
                                    label="Penalty Amount"
                                    fullWidth
                                    value={formData.penalty_amount}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Button onClick={handleSubmit} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                                    {penaltyData ? 'Upadate' : 'Add'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Grid>
        </>
    );
}