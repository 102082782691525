import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    Box,
    Chip,
} from '@mui/material';
import 'chart.js/auto'; // Import chart.js
import { secondaryColor } from '../../constant';
import { useSelector } from 'react-redux';
import { get_data } from '../../api';

const DashboardComp = () => {

    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true)
    const [totalEmployees, setTotalEmployees] = useState([])
    const [totalDepartments, setTotalDepartments] = useState([])
    const [totalDesignations, setTotalDesignations] = useState([])
    const [totalRoles, setTotalRoles] = useState([])
    const [totalPresent, setTotalPresent] = useState([])
    const [totalAbsent, setTotalAbsent] = useState([])
    const [totalLate, setTotalLate] = useState([])
    const [totalEarlyOnTime, setTotalEarlyOnTime] = useState([])

    const getDashboardStats = async () => {
        try {
            const response = await get_data(`company/get-dashboard-stats/${user_data?._id}`);
            setLoading(false);
            if (response.data) {
                setTotalEmployees(response?.data?.totalEmployees);
                setTotalDepartments(response?.data?.totalDepartments);
                setTotalDesignations(response?.data?.totalDesignations);
                setTotalRoles(response?.data?.totalRoles);
                setTotalPresent(response?.data?.totalPresent);
                setTotalAbsent(response?.data?.totalAbsent);
                setTotalLate(response?.data?.totalLate);
                setTotalEarlyOnTime(response?.data?.totalEarlyOnTime);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDashboardStats()
    }, [user_data])

    return (
        <Box>
            <Grid container justifyContent="space-between" alignItems="center" mb={0}>
                <Typography variant="h5" fontWeight="500">
                    Analytics Dashboard
                </Typography>
            </Grid>
            <Typography variant="subtitle1" color="textSecondary">
                Welcome back, {user_data?.name}! We've missed you. 👋
            </Typography>

            <div style={{ display: 'flex', gap: 20 }}>
                <Grid container spacing={2} mt={2} style={{ width: '50%' }}>
                    {/* Visitors */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Employees
                            </Typography>
                            <Typography variant="h4" mt={1} style={{ color: secondaryColor }}>
                                {totalEmployees || '0'}
                            </Typography>
                            {/* <Typography color="green" fontWeight="500">
                                +14% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>

                    {/* Activity */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Roles
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalRoles || '0'}
                            </Typography>
                            {/* <Typography color="red" fontWeight="500">
                                -12% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>

                    {/* Real-Time */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Departments
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalDepartments || '0'}
                            </Typography>
                            {/* <Typography color="red" fontWeight="500">
                                -18% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>

                    {/* Bounce */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Designations
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalDesignations || '0'}
                            </Typography>
                            {/* <Typography color="green" fontWeight="500">
                                +27% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>
                </Grid>

                <Grid container spacing={2} mt={2} style={{ width: '50%' }}>
                    {/* Visitors */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Present
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalPresent || '0'}
                            </Typography>
                            {/* <Typography color="green" fontWeight="500">
                                +14% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>

                    {/* Activity */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Absent
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalAbsent || '0'}
                            </Typography>
                            {/* <Typography color="red" fontWeight="500">
                                -12% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>

                    {/* Real-Time */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                On Time or Early
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalEarlyOnTime || '0'}
                            </Typography>
                            {/* <Typography color="red" fontWeight="500">
                                -18% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>

                    {/* Bounce */}
                    <Grid item xs={12} md={6}>
                        <CardContent style={{ background: 'white', height: 140, borderRadius: 5, border: '1px solid gainsboro' }}>
                            <Typography variant="h6" color="textSecondary">
                                Late
                            </Typography>
                            <Typography variant="h4" mt={1}>
                                {totalLate || '0'}
                            </Typography>
                            {/* <Typography color="green" fontWeight="500">
                                +27% <span style={{ color: 'gray' }}>Since last week</span>
                            </Typography> */}
                            <Chip
                                label="Employees"
                                size="small"
                                sx={{ backgroundColor: '#e3f2fd', color: '#1e88e5', marginTop: '5px' }}
                            />
                        </CardContent>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
};

export default DashboardComp;