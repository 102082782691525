import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Autocomplete, IconButton, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';
import { createButtonColor, primaryColor, secondaryColor } from '../../../constant';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
};

export default function AddMember({ open, setOpen, isEdit, updateData, fetchData }) {

    const [errors, setErrors] = useState({});
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const { user_data } = useSelector(state => state.user);

    const handleClose = () => {
        setSelectedRoles([]);
        setSelectedEmployee(null);
        setPassword('');
        setConfirmPassword('');
        setOpen(false);
    };

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const fetchRoles = async () => {
        let result = await get_data(`roles/get-all-roles-for-options/${user_data?._id}`);
        if (result?.status) {
            setRoles(result?.data);
        }
    };

    const fetchEmployees = async () => {
        let result = await get_data(`employee/get-all-employees-by-company-for-options/${user_data?._id}`);
        if (result?.status) {
            setEmployees(result?.data);
        }
    };

    React.useEffect(() => {
        fetchRoles()
        fetchEmployees()
        if (isEdit) {
            setSelectedRoles(updateData?.roles?.length > 0 ? updateData?.roles?.map((item) => item?._id) : []);
            setSelectedEmployee(updateData?.employee_id || null);
        }
    }, [updateData, open]);

    const validation = () => {
        let error = false;

        if (selectedRoles.length === 0) {
            error = true;
            handleError('Please select at least one Role', 'selectedRoles');
        }

        if (!selectedEmployee) {
            error = true;
            handleError('Please select an Employee', 'selectedEmployee');
        }

        if (!password) {
            error = true;
            handleError('Password is required', 'password');
        }

        if (password !== confirmPassword) {
            error = true;
            handleError('Passwords do not match', 'confirmPassword');
        }

        return error;
    };

    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            const body = {
                roles: selectedRoles,
                company_id: user_data?._id,
                employee_id: selectedEmployee?._id,
                email: selectedEmployee?.email,
                password
            };

            let result = await post_data(`members/create-member`, body);

            if (result?.status) {
                toast.success('Member added successfully');
                fetchData();
                handleClose();
            } else {
                toast.error("Something went wrong");
            }
        }
    };

    const handleUpdate = async () => {
        const hasError = validation();
        if (!hasError) {
            const body = {
                roles: selectedRoles,
                company_id: user_data?._id,
                employee_id: selectedEmployee?._id,
                email: selectedEmployee?.email,
                password
            };

            let result = await post_data(`members/update-member/${updateData?._id}`, body);
            if (result?.status === true) {
                toast.success('Member updated successfully');
                fetchData();
                handleClose();
            } else {
                toast.error("Something went wrong");
            }
        }
    };


    const handleRoleChange = (event) => {
        setSelectedRoles(event.target.value);
        setErrors({ ...errors, selectedRoles: false })
    };

    const handleEmployeeChange = (event, value) => {
        setSelectedEmployee(value);
        if (value) {
            handleError('', 'selectedEmployee');
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        handleError('', 'password');
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        handleError('', 'confirmPassword');
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={employees}
                                getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name || ''}
                                value={selectedEmployee}
                                onChange={handleEmployeeChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Employee"
                                        error={!!errors?.selectedEmployee}
                                        helperText={errors?.selectedEmployee}
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Select Roles</InputLabel>
                                <Select
                                    multiple
                                    value={selectedRoles}
                                    onChange={handleRoleChange}
                                    label="Select Roles"
                                    renderValue={(selected) => selected.map(id => roles?.find(option => option?._id === id)?.name).join(', ')}
                                >
                                    {roles?.map(role => (
                                        <MenuItem key={role?._id} value={role?._id}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors?.selectedRoles && (
                                    <Typography variant="body2" color="error">
                                        {errors?.selectedRoles}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handlePasswordChange}
                                error={!!errors?.password}
                                helperText={errors?.password}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={toggleShowPassword} edge="end">
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                error={!!errors?.confirmPassword}
                                helperText={errors?.confirmPassword}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={toggleShowPassword} edge="end">
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ padding: '20px 0px' }}>
                        <Button onClick={isEdit ? handleUpdate : handleSubmit} style={{ background: secondaryColor, color: '#fff' }} fullWidth>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
