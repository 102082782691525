import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { fetchAddress, formatDate, getAddressFromCoordinates } from '../../../constant';
import { serverURL } from '../../../api';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            // padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    statusButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.present': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.on_time': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.early_leave': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.late': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.absent': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.overtime': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.early': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const AttendanceTable = ({
    data,
    tableHeader,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    totalData,
    totalPages,
}) => {

    const classes = useStyles();

    const [rowsData, setRowsData] = useState(data || []);


    useEffect(() => {
        setRowsData(data);
    }, [data])

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);


    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    return (
        <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow>
                        {
                            tableHeader?.map((item) => {
                                return (
                                    <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsData?.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: 30 }}>{index + 1}</TableCell>

                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>
                                {
                                    row?.selfie_in !== '' ? (
                                        <>
                                            <img src={`${serverURL}/uploads/attendance-pic/${row?.selfie_in}`} style={{ borderRadius: 50, height: 60, width: 60 }} />
                                        </>
                                    ) : (
                                        <>-</>
                                    )
                                }
                            </TableCell>

                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>
                                {
                                    row?.selfie_out !== '' ? (
                                        <>
                                            <img src={`${serverURL}/uploads/attendance-pic/${row?.selfie_out}`} style={{ borderRadius: 50, height: 60, width: 60 }} />
                                        </>
                                    ) : (
                                        <>-</>
                                    )
                                }
                            </TableCell>

                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 140 }}>{row?.employee_id?.first_name + ' ' + row?.employee_id?.last_name}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.employee_id?.phone}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>{row?.punch_in || '-'}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>{row?.punch_out || '-'}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 100 }}>{row?.location_in?.latitude || '-'}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 100 }}>{row?.location_out?.latitude || '-'}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', width: 80 }}>{formatDate(row?.created_at)}</TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textTransform: 'capitalize' }}>
                                <Button
                                    style={{ fontSize: 11, fontWeight: 500 }}
                                    className={`${classes.statusButton} ${row?.status.toLowerCase().replace(' ', '')}`}
                                >
                                    {row?.status.replace(/_/g, ' ') || '-'}
                                </Button>
                            </TableCell>
                            <TableCell style={{ borderRight: '1px solid gainsboro', textTransform: 'capitalize', width: 60 }}>
                                <Button
                                    style={{ fontSize: 11, fontWeight: 500 }}
                                    className={`${classes.statusButton} ${row?.time_status.toLowerCase().replace(' ', '')}`}
                                >
                                    {row?.time_status.replace(/_/g, ' ') || '-'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {rowsData?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </TableContainer>
    );
};

export default AttendanceTable;