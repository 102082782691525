import React, { useRef, useEffect, useState } from "react";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { primaryColor, secondaryColor } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slices/user-slice";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { post_data } from "../../api";
import toast from "react-hot-toast";

export default function OtpComponent({
    email,
    setOpen,
    handleResend,
    phone,
    name,
    password,
    country,
    type,
    state,
    city,

}) {

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 8000,
        timerProgressBar: true,
    });

    // const pathname = usePathname();
    // const [currentPath, setCurrentPath] = useState(pathname);
    // const router = useRouter();
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const [loading2, setLoading2] = useState(false);
    const otpInputRefs = useRef([]);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
    const matches_md = useMediaQuery(theme.breakpoints.down("md"));
    const [timer, setTimer] = useState(59);
    const [canResend, setCanResend] = useState(false);
    const { user_data } = useSelector(state => state.user)

    const navigate = useNavigate()

    useEffect(() => {

        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            setCanResend(true);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleFocus = (index) => {
        setFocusedIndex(index);
    };

    const otpBox = {
        width: matches_md ? 30 : 30,
        height: matches_md ? 40 : 40,
        background: "transparent",
        padding: "2.5% 4%",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
    };

    const handleOtpChange = (index, value) => {
        let arr = value !== "" ? value.split("") : "";
        let currentValue = "";

        if (arr !== "") {
            currentValue = arr[arr.length - 1];
        }

        const newOtp = [...otp];
        newOtp[index] = currentValue;
        setOtp(newOtp);

        if (currentValue !== "" && index < otpInputRefs.current.length - 1) {
            otpInputRefs.current[index + 1].focus();
        }

        if (currentValue === "" && index > 0) {
            otpInputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = async () => {

        setLoading2(true);

        if (type === 'signup') {
            let payload = {
                phone: phone, email: email, name: name,
                password: password, otp: otp.join("")
            };
            const response = await post_data("company/verify-otp-for-company-signup", payload);
            if (response?.status === true) {
                toast.success(response?.message);
                dispatch(login(response?.data?.user));
                localStorage.setItem("authToken", response?.data?.token);
                navigate('/company/dashboard')
                setLoading2(false)
            } else {
                toast.error(response?.response?.data?.message || "Something went wrong");
            }
            setLoading2(false);
        }

        if (type === 'login') {
            let payload = { email: email, otp: otp.join("") };
            const response = await post_data("company/verify-otp-for-company-login", payload);

            if (response?.status) {
                dispatch(login(response?.data?.user));
                localStorage.setItem("authToken", response?.data?.token);
                setLoading2(false);
                navigate('/company/dashboard')
            } else {
                if (response?.message === "Invalid Otp") {
                    Toast.fire({
                        title: response?.message,
                        icon: 'error',
                        color: 'red'
                    })
                }
                else if (response?.response?.data?.message === "Otp Expired") {
                    Toast.fire({
                        title: response?.message,
                        icon: 'error',
                        color: 'red'
                    })
                }
                else {
                    Toast.fire({
                        title: response?.message,
                        icon: 'error',
                        color: 'red'
                    })
                }
            }
            setLoading2(false);
        }

        if (type === 'change-email') {
            let payload = { otp: otp.join(""), new_email: email, email: user_data?.email };
            const response = await post_data("company/verify-otp-for-change-email", payload);
            if (response?.status === true) {
                setLoading2(false)
                setOpen(false);
                dispatch(login(response?.data));
                navigate("/login")
            } else {
                toast.error(response?.response?.data?.message || "Something went wrong");
            }
            setLoading2(false);
        }

        // if (type === 'change-number') {
        //     let payload = { otp: otp.join(""), new_number: phone, user_id: user_data?._id };  // change customer email
        //     const response = await postData("user/verify-otp-for-change-number", payload);
        //     if (response?.status) {
        //         setLoading2(false)
        //         setOpen(false);
        //         dispatch(login(response?.data));
        //         router.push('/user/dashboard');
        //     } else {
        //         toast.error('Wrong Otp!');
        //     }
        //     setLoading2(false);
        // }

        if (type === 'change-admin-email') {
            let payload = { email: user_data?.email, otp: otp.join(""), new_email: email };
            const response = await post_data("super-admin/verify-otp-for-change-email", payload);
            if (response?.status) {
                setTimeout(() => {
                    setLoading2(false)
                    dispatch(login(response?.data));
                    navigate("/admin/login")
                }, 1000);
            } else {
                toast.error('Wrong Otp!');
            }
            setLoading2(false);
        }
    };

    const handleResendOtp = async () => {
        setTimer(59);
        setCanResend(false);
        handleResend()
    };

    const handleEnter = (event) => {
        if (event.key == 'Enter') {
            handleSubmit()
        }
    }

    const button = {
        padding: '10px 0',
        backgroundColor: secondaryColor,
        color: 'white',
        fontSize: 15,
        width: '85%',
        margin: '0 auto 4%',
        textTransform: 'capitalize',
        cursor: 'pointer',
        border: 'none',
    }

    const resendTimer = `00:${timer < 10 ? `0${timer}` : timer} seconds`

    return (
        <div style={{ width: '100%', padding: '40px 0px', borderRadius: '30px' }}>
            <div className="otp-form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} >
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    {otp?.map((digit, index) => {
                        return (
                            <>
                                <div
                                    style={{

                                        marginRight: '1%',
                                        ...otpBox,
                                        border:
                                            focusedIndex === index
                                                ? `2px solid ${primaryColor}`
                                                : "2px solid #CBCBCB",
                                    }}
                                >
                                    <TextField
                                        onKeyPress={(event) => handleEnter(event)}
                                        onFocus={() => handleFocus(index)}
                                        value={digit}
                                        inputRef={(ref) => (otpInputRefs.current[index] = ref)}
                                        onChange={(e) =>
                                            handleOtpChange(index, e.target.value, "one")
                                        }
                                        // id="zero"
                                        sx={{

                                            input: {
                                                color: "black",
                                                fontSize: 30,
                                                textAlign: "center",
                                                fontWeight: "bold",
                                            },
                                        }}
                                        variant="standard"
                                        hiddenLabel
                                        placeholder=" "
                                        InputProps={{
                                            disableUnderline: true,
                                            inputProps: { maxLength: 1 },
                                        }}
                                    />
                                </div>
                            </>
                        );
                    })}
                </div>
                <p className="otp-form-txt" style={{ marginTop: '3%', marginBottom: '0px' }}>
                    Please enter your verification code.
                </p>
                <p style={{ marginTop: '0px', marginBottom: '5%' }}>
                    An OTP has been sent to {email ? email : phone}
                </p>
            </div >

            <center>
                <Button
                    disabled={loading2}
                    onClick={handleSubmit}
                    // fullWidth
                    className={loading2 ? "register-btn-disable" : "register-btn"}
                    style={button}
                >
                    {loading2 ? "Verifying..." : "Verify"}
                </Button>
            </center>

            <center>
                <div style={{ marginBottom: '0%' }}>
                    {canResend ? (
                        <p onClick={handleResendOtp} style={{ cursor: 'pointer', color: primaryColor, fontWeight: 500, textDecoration: 'underline' }}>
                            Resend OTP
                        </p>
                    ) : (
                        <p className="otp-form-txt">
                            Didn&apos;t receive OTP? Resend in <b>{resendTimer}</b>
                        </p>

                    )}
                </div>
            </center>

        </div>
    );
}
