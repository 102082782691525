import React from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiBuilding2Line } from "react-icons/ri";
import { Avatar } from "@mui/material";
import { serverURL } from "../../api";

export default function Sidebar({
    dashboard_items,
    employee_items,
    company_items,
    profile_items
}) {
    const navigate = useNavigate();
    const { user_data } = useSelector(state => state.user);

    const user_dashboard_sidebar = {
        background: '#06121e',
        width: '100%',
        height: '100vh', // 100% viewport height to enable scrolling
        position: 'relative',
        borderRight: '1px solid gainsboro',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
    };

    const logo_section = {
        padding: '7.5% 5%',
        display: "flex",
        gap: 10,
        border: '1px solid #1a2835',
        justifyContent: 'left',
        alignItems: 'center',
    };

    const sidebar_items_div = {
        padding: '2% 4%',
    };

    const admin_section = {
        position: 'sticky',
        bottom: 0, // Makes it stick to the bottom
        width: '100%', // Ensures it spans the entire width of the sidebar
        backgroundColor: '#06121e', // Matches the sidebar background
        padding: '1em',
        gap: 10,
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #1a2835',
        textAlign: 'center',
    };

    const handleListItem = (item, i) => {
        if (item?.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    };

    let current_path = window.location.pathname;

    const display_sidebar_items = (arr) => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {arr?.map((item, i) => {
                    return (
                        <div key={i}>
                            <ListItemButton
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    padding: '5% 6%',
                                    borderRadius: 8,
                                    margin: '1% 0',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? '#1a2835' : 'transparent' : ''
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    gap: '8%',
                                    width: '100%',
                                    alignItems: item?.type !== 'dropdown' ? "center" : 'center'
                                }}>
                                    {item.icon}
                                    <p style={{ color: current_path === item?.link ? 'white' : '#c9c9c9', fontWeight: 400, fontSize: 14, margin: 0, padding: 0 }}>
                                        {item?.type !== 'dropdown' ? item?.title : ''}
                                    </p>
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-container">
                <div style={logo_section}>
                    <div style={{ borderRadius: 2, border: '1px solid gray', height: 45, width: 45, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <RiBuilding2Line color="white" fontSize={20} />
                    </div>
                    <h3 style={{ fontWeight: 500, fontSize: 18, color: 'white', margin: 0 }}>
                        {user_data?.name}
                    </h3>
                </div>
                <div style={sidebar_items_div}>
                    {display_sidebar_items(dashboard_items)}
                    <p style={{ color: 'white', margin: '2% 5% 0' }}>Employee</p>
                    {display_sidebar_items(employee_items)}
                    <p style={{ color: 'white', margin: '2% 5% 0' }}>Company</p>
                    {display_sidebar_items(company_items)}
                    <p style={{ color: 'white', margin: '2% 5% 0' }}>Profile</p>
                    {display_sidebar_items(profile_items)}
                </div>

                <div style={admin_section}>
                    <Avatar alt="Travis Howard" src={`${serverURL}/uploads/company-logo/${user_data?.logo}` || ''} />
                    <div>
                        <h3 style={{ fontWeight: 500, fontSize: 12, color: 'white', margin: 0, textAlign: 'left' }}>{user_data?.name}</h3>
                        <p style={{ fontWeight: 400, fontSize: 11, opacity: '70%', color: 'white', margin: 0, textAlign: 'left' }}>{user_data?.email}</p>
                    </div>
                </div>
            </div>
        </>
    );
}