import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../global/components/SearchComponent";
import EmployeeTable from "../components/tables/EmployeeTable";
import { createButtonColor, primaryColor, secondaryColor } from "../../constant";
import FilterModal from "../components/FilterModel";
import TableLoader from "../../global/components/TableLoader";
import CompaniesTable from "../components/tables/CompaniesTable";
import AdminPermissionsTable from "../components/AdminPermissionsTable";
import AddPermissionModel from "../components/modals/AddPermissionModel";
import Empty from "../../global/components/Empty";

export default function AdminPermissions() {

    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const [permissionType, setPermissionType] = useState('')
    const [permission, setPermission] = useState('')
    const [department, setDepartment] = useState('')
    const [rowsData, setRowsData] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [open, setOpen] = useState(false)
    const [searchState, setSearchState] = useState(false);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };



    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            if (value !== '') {
                searchData();
                setSearchState(true);
            } else {
                fetchData();
                setSearchState(false);
            }
        }, 500);
    };

    const searchData = async () => {
        try {
            const response = await get_data(`permission/search-permissions/${value}?pageNumber=${currentPage}`);
            setLoading(false);
            if (response.status) {
                setRowsData(response?.data?.subAdminPermission);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalPermissions);
            } else {
                setRowsData([]);
            }
        } catch (error) {
            setLoading(false);
        }
    };


    const fetchData = async () => {
        try {
            const response = await get_data(`permission/get-all-sub-admin-permission?pageNumber=${currentPage}`);
            if (response.data) {
                setRowsData(response?.data?.subAdminPermission);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalPermissions);
            }
        } catch (error) {
        }
    };


    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchData()
            }
            else {
                fetchData();
            }
        }
    }, [currentPage, refresh, searchState])


    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [currentPage, refresh, searchState])


    const tableHeader = [
        "S No.", "Name", "Assigned", "Created", "Date"
    ]

    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>

                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                        <SearchComponent handleSearch={handleSearch} setValue={setValue} value={value} />
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>

                        {/* <Button onClick={() => setOpen(true)} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                            Add Permission
                        </Button> */}

                    </div>
                </div>

                <div style={page_container}>

                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    rowsData?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <AdminPermissionsTable
                                            type='permission'
                                            data={rowsData}
                                            tableHeader={tableHeader}
                                            itemsPerPage={15}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            refresh={refresh}
                                            setRefresh={setRefresh}
                                        />
                                    )
                                }
                            </>
                        )
                    }

                </div>
                <AddPermissionModel open={open} onClose={handleClose} refresh={refresh} setRefresh={setRefresh} />
            </Grid>
        </>
    )
}