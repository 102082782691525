import { TextField, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { primaryColor, secondaryColor } from '../../constant';

const HeroSection = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isDesktop ? '6% 16%' : '80px 1px',
        },
        leftSide: {
            flex: 1,
            padding: '20px',
            order: isDesktop ? 1 : 2
        },
        rightSide: {
            flex: 1,
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            order: isDesktop ? 2 : 1
        },
        title: {
            fontSize: 18,
            fontWeight: 400,
            textAlign: 'center'
        },
        heading: {
            fontSize: isDesktop ? 50 : '32px',
            fontWeight: '600',
            marginBottom: '20px',
            marginTop: 0,
            textAlign: 'center',
            lineHeight: 1.1
        },
        highlight: {
            color: primaryColor,
        },
        description: {
            fontSize: 20,
            opacity: '70%',
            textAlign: 'center',
            width: '70%',
            margin: ' 0 auto 20px',
        },
        button: {
            padding: isDesktop ? '2% 3%' : '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: secondaryColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: "2%"
        },
        image: {
            maxWidth: isDesktop ? '90%' : '100%',
            height: 'auto',
            // marginRight: '-90%',
            borderRadius: '5px',
        }
    };

    const headings = ["Payroll Management", "Attendace Records", "Location Monitoring"];

    const [currentHeading, setCurrentHeading] = useState(0);
    const [displayText, setDisplayText] = useState("");
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayText((prev) => prev + headings[currentHeading][charIndex]);
            setCharIndex((prev) => prev + 1);
        }, 100);

        if (charIndex === headings[currentHeading].length) {
            clearInterval(interval);
            setTimeout(() => {
                setCurrentHeading((prev) => (prev + 1) % headings.length);
                setDisplayText("");
                setCharIndex(0);
            }, 2000);
        }

        return () => clearInterval(interval);
    }, [charIndex, currentHeading, headings]);


    return (
        <div style={styles.container}>
            <div>
                {/* <h2 style={styles.title}>We Provide Business Solutions</h2> */}
                <h2 style={styles.heading}><span style={{ color: secondaryColor }}>{displayText}</span> <br />Staff Management Solution for your Business</h2>
                <p style={styles.description}>
                    Manage your staff attendance, salary, payments and compliances in few clicks
                </p>
                <center>
                    <button style={styles.button}>Check all Services</button>
                </center>
            </div>
            {/* <div style={styles.rightSide}>
                <img
                    src="/images/dashboard-2.png"
                    alt="Home Maintenance"
                    style={styles.image}
                />
            </div> */}
        </div>
    );
}


export default HeroSection;