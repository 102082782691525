import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody, CircularProgress, Switch, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { formatDate, formatPrice } from '../../../constant';
import { post_data } from '../../../api';
import toast from 'react-hot-toast';

const FieldsDetailsModal = ({ open, setOpen, data, title, getAllEmployeeUpdateRequest }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const [fieldsToUpdate, setFieldsToUpdate] = useState([])
    const [fieldsUpdated, setFieldsUpdated] = useState([])
    const [fieldsDeclined, setFieldsDeclined] = useState([])
    const [switchLoaders, setSwitchLoaders] = useState({});

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,

        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'end'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };


    function formatString(input) {
        const isCamelCase = /[a-z][A-Z]/.test(input);

        if (isCamelCase) {
            return input
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace('-', '')
                .trim()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        } else {
            return input
                .replace(/[-_]/g, ' ')
                .trim()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    }

    const handleCheck = async (id, status) => {
        setSwitchLoaders(prev => ({ ...prev, [id]: true }));

        const fieldToUpdate = data.fields_to_update.find(field => field._id === id);

        let payload = {
            "fields_to_update": [
                {
                    ...fieldToUpdate,
                    "status": status
                }
            ],
        };

        let endpoint = `update-request/update-update-request/${data?._id}/${data?.employee_id?._id}`;
        try {
            const response = await post_data(endpoint, payload);
            if (response.status) {
                getAllEmployeeUpdateRequest()
                toast.success("Request approved Successfully");
            } else {
                toast.error("Something Went Wrong");
            }
        } catch (error) {
            toast.error("Failed to update status");
        }

        setSwitchLoaders(prev => ({ ...prev, [id]: false }));
    };



    const DataRow = ({ label, value, id, status }) => (
        <div style={styles.row}>
            <div style={styles.dataLabel}>{formatString(label || '-')}</div>
            <div style={styles.dataValue}>{value || '-'}</div>
            <div style={{ ...styles.dataValue, display: 'flex' }}>
                {switchLoaders[id] ?
                    <CircularProgress size={20} />
                    :
                    <>
                        <FormGroup style={{ display: 'flex' }}>
                            <FormControlLabel control={<Checkbox checked={status === 'approved' ? true : false} />} label="Approved" onChange={() => handleCheck(id, 'approved')} />
                            <FormControlLabel control={<Checkbox checked={status === 'declined' ? true : false} />} label="Declined" onChange={() => handleCheck(id, 'declined')} />
                        </FormGroup>
                    </>
                }
            </div>
        </div>
    );


    useEffect(() => {
        const fields_to_update = data?.fields_to_update?.filter((item) => item?.status === 'pending')
        if (fields_to_update) {
            setFieldsToUpdate(fields_to_update)
        }
        const fields_updated = data?.fields_to_update?.filter((item) => item?.status === 'approved')
        if (fields_updated) {
            setFieldsUpdated(fields_updated)
        }
        const fields_declined = data?.fields_to_update?.filter((item) => item?.status === 'declined')
        if (fields_declined) {
            setFieldsDeclined(fields_declined)
        }
    }, [open, data])


    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    {title}
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    {
                        title === 'Fields To Update' && fieldsToUpdate?.map((item, i) => {
                            return (
                                <DataRow label={item.field_name} value={item?.new_value} id={item?._id} status={item?.status} />
                            )
                        })
                    }
                    {
                        title === 'Fields Updated' && fieldsUpdated?.map((item, i) => {
                            return (
                                <DataRow label={item.field_name} value={item?.new_value} id={item?._id} status={item?.status} />
                            )
                        })
                    }
                    {
                        title === 'Fields Declined' && fieldsDeclined?.map((item, i) => {
                            return (
                                <DataRow label={item.field_name} value={item?.new_value} id={item?._id} status={item?.status} />
                            )
                        })
                    }
                </Box>
            </Box>
        </Modal>
    );
};

export default FieldsDetailsModal;