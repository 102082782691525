import AppFeatures1 from "../components/AppFeatures1";
import AppFeatures2 from "../components/AppFeatures2";
import AppFeatures3 from "../components/AppFeatures3";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";

export default function Home() {
    return (
        <>
            <Header />
            <HeroSection />
            <Features />
            <AppFeatures1 />
            <AppFeatures2 />
            <AppFeatures3 />
            <Footer />
        </>
    )
}