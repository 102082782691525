import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate, formatPrice } from '../../../constant';
import PayrollDetailsModal from '../modals/PayrollDetailsModal';
import PayrollInvoiceModal from '../modals/PayrollInvoiceModal';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    statusButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.unpaid': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.pending': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.paid': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.approved': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        }
    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

export default function PayrollTable({
    data,
    tableHeader,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    count,
    totalData,
    getAllPayrolls,
    type,
    totalPages,
}) {

    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [selectedRow, setSelectedRow] = useState({})
    const [invoiceOpen, setInvoiceOpen] = useState(false)

    const [rowsData, setRowsData] = useState(data || []);

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleDownload = (row) => {
        setSelectedRow(row)
        setInvoiceOpen(true)
    }

    return (
        <>
            <PayrollInvoiceModal
                setOpen={setInvoiceOpen}
                open={invoiceOpen}
                data={selectedRow}
            />
            <PayrollDetailsModal open={open} setOpen={setOpen} data={selectedRow} />
            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {
                                tableHeader?.map((item) => {
                                    return (
                                        <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: 60 }}>{index + 1}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.employee_id?.first_name + ' ' + row?.employee_id?.last_name}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.month}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(row?.salary?.base_salary)}</TableCell>
                                {/* <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(row?.salary?.bonuses)}</TableCell> */}
                                {/* <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(row?.salary?.deductions)}</TableCell> */}
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(row?.salary?.net_salary)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <Button style={{ fontSize: 11, fontWeight: 500 }}
                                        className={`${classes.statusButton} ${row?.status?.toLowerCase().replace(' ', '')}`}
                                    >
                                        {row?.status}
                                    </Button>
                                </TableCell>

                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.created_at)}</TableCell>

                                {
                                    type === 'main_payroll' && (
                                        <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                            <Button
                                                onClick={() => handleDownload(row)}
                                                style={{ fontSize: 10, fontWeight: 500, backgroundColor: '#E7F5E9', color: '#43A047', borderRadius: 50 }}>
                                                Download
                                            </Button>
                                        </TableCell>
                                    )
                                }

                                <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        {
                                            type !== 'main_payroll' && (
                                                <MenuItem onClick={() => {
                                                    handleMenuClose()
                                                    setSelectedRow(row)
                                                    setOpen(true)
                                                }}>View Details</MenuItem>
                                            )
                                        }

                                        <MenuItem onClick={() => {
                                            handleMenuClose()
                                            setSelectedRow(row)
                                            setOpen(true)
                                        }}> {row?.is_approved ? 'Approve' : 'Disapprove'} Payroll</MenuItem>

                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {rowsData?.length > 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}
            </TableContainer>
        </>
    );
};


