import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { formatDate, formatPrice, primaryColor } from "../../../constant";
import { useSelector } from "react-redux";

const PayrollInvoiceModal = ({ open, setOpen, data }) => {

    const contentRef = useRef(null);
    const { user_data } = useSelector(state => state.user);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };

    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = 210;
            const pageHeight = 295;
            const margin = 10;
            const tableStartY = 20;
            const rowHeight = 10;

            let positionY = tableStartY;

            pdf.setFontSize(12);
            pdf.text("Invoice no.", margin, positionY);
            pdf.text("Employee Name", margin + 30, positionY);
            pdf.text("Month", margin + 70, positionY);
            pdf.text("Base Salary", margin + 90, positionY);
            pdf.text("Net Salary", margin + 120, positionY);
            pdf.text("Paid Date", margin + 150, positionY);

            positionY += rowHeight;

            pdf.setFontSize(10);
            pdf.text(data?.invoice_number, margin, positionY);
            pdf.text(data?.employee_id?.first_name + ' ' + data?.employee_id?.last_name || '-', margin + 30, positionY);
            pdf.text(data?.month, margin + 70, positionY);
            pdf.text(formatPrice(data?.salary?.base_salary) || '-', margin + 90, positionY);
            pdf.text(formatPrice(data?.salary?.net_salary) || '-', margin + 120, positionY);
            pdf.text(formatDate(data?.paid_on) || '-', margin + 150, positionY);

            positionY += rowHeight;

            pdf.save("plan_invoice.pdf");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                    <div ref={contentRef} id="invoice" style={styles.invoice}>
                        <div style={styles.header}>
                            <div style={styles.brand}>
                                <h2 style={{ fontWeight: 600, margin: 0, }}>Invoice</h2>
                            </div>
                        </div>

                        <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                            <thead style={{ textAlign: "left" }}>
                                <tr>
                                    <th>Invoice no.</th>
                                    <th>Employee Name</th>
                                    <th>Month</th>
                                    <th>Base Salary</th>
                                    <th>Net Salary</th>
                                    <th>Paid Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                    <td style={{ padding: '1% 0' }}>{data?.invoice_number || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{data?.employee_id?.first_name || '-'} {data?.employee_id?.last_name || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{data?.month}</td>
                                    <td style={{ padding: '1% 0' }}>{formatPrice(data?.salary?.base_salary) || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{formatPrice(data?.salary?.net_salary) || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{formatDate(data?.paid_on)} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ ...styles.footer, ...styles.text }}>
                            <div style={styles.terms}>
                                <p>For {user_data?.name}</p>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default PayrollInvoiceModal;