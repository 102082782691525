import { Button, Divider, Grid, TextField, Typography, Box, InputLabel, MenuItem, FormControl, Select, Autocomplete, IconButton, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import { get_data, post_data } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { primaryColor, secondaryColor } from "../../constant";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import toast, { Toaster } from 'react-hot-toast';

const sections = [
    {
        title: 'Profile Information',
        fields: [
            { name: 'company', label: 'Select company', type: 'autocomplete' }, ,
            { name: 'first_name', label: 'First Name', type: 'text' },
            { name: 'last_name', label: 'Last Name', type: 'text' },
            { name: 'profile_picture', label: 'Profile Picture', type: 'file' },
            { name: 'password', label: 'Password', type: 'password' }
        ]
    },
    {
        title: 'General Information',
        fields: [
            { name: 'salary', label: 'Salary', type: 'number' },
            { name: 'department', label: 'Department', type: 'autocomplete' },
            { name: 'designation', label: 'Designation', type: 'autocomplete' },
            { name: 'shift', label: 'Shift', type: 'autocomplete' }
        ]
    },
    {
        title: 'Personal Information',
        fields: [

            { name: 'gender', label: 'Gender', type: 'select', options: ['Male', 'Female'] },
            { name: 'dob', label: 'Date of Birth', type: 'date', placeholder: 'MM/DD/YYYY' },
            { name: 'email', label: 'Email', type: 'text' },
            { name: 'phone', label: 'Phone No.', type: 'number' },
            { name: 'street', label: 'Street', type: 'text' },
            { name: 'state', label: 'State', type: 'autocomplete' },
            { name: 'city', label: 'City', type: 'autocomplete' },
            { name: 'zipCode', label: 'Zip Code', type: 'text' }
        ]
    },
    {
        title: 'Employment Information',
        fields: [
            { name: 'hire_date', label: 'Date of hire', type: 'date', placeholder: 'MM/DD/YYYY' },
            { name: 'date_of_joining', label: 'Date of Joining', type: 'date', placeholder: 'MM/DD/YYYY' },
            { name: 'pan_card', label: 'PAN Number', type: 'text' },
            { name: 'aadhar_card', label: 'Aadhar Number', type: 'number' },
            // { name: 'paid_leaves', label: 'Paid Leaves', type: 'number' },
            // { name: 'unpaid_leaves', label: 'Unpaid Leaves', type: 'number' }
        ]
    }
];

export default function AdminAddEmployeeComp() {

    const navigate = useNavigate()
    const [designations, setDesignations] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [shifts, setShifts] = useState([]);
    const { user_data } = useSelector(state => state.user);
    const [companies, setCompanies] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const initialFormValues = sections.reduce((acc, section) => {
        section.fields.forEach(field => {
            acc[field.name] = '';
        });
        return acc;
    }, {});

    const [formValues, setFormValues] = useState(initialFormValues);
    const [isUpdate, setIsUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    let employeeId = localStorage?.getItem('employeeId');

    const handleError = (error, field) => {
        setErrors(prev => ({ ...prev, [field]: error }));
    };

    const location = useLocation();
    const employee = location.state?.employee;

    const fetchCompanies = async () => {
        let result = await get_data(`company/get-all-companies-for-options`)
        if (result?.status) {
            setCompanies(result?.data?.map(company => company?.name))
        }
    }

    useEffect(function () {
        fetchCompanies()
    }, [])

    const fetchDesignations = async () => {
        try {
            let result = await get_data(`designation/get-all-designations-for-options`);
            if (result?.status) {
                setDesignations(result?.data?.map(designation => designation?.name));
            } else {
                setDesignations([]);
            }
        } catch (error) {
            setDesignations([]);
        }
    };

    const getAllDepartments = async () => {
        try {
            const data = await get_data(`department/get-all-departments-for-options`);
            if (data.status) {
                setDepartments(data?.data?.map(department => department?.name));
            }
        } catch (error) {
            setDepartments([]);
        }
    };

    const fetchShifts = async () => {
        try {
            let result = await get_data(`shifts/get-all-shifts-for-options`);
            if (result?.status) {
                setShifts(result?.data);
            } else {
                setShifts([]);
            }
        } catch (error) {
            setShifts([]);
        }
    };

    useEffect(() => {
        fetchDesignations();
        getAllDepartments();
        fetchShifts();
        setStates(State.getStatesOfCountry("IN"));
    }, [user_data]);


    useEffect(() => {
        if (employee) {
            setIsUpdate(true);
            const updatedValues = {};
            sections?.forEach(section => {
                section?.fields?.forEach(field => {
                    if (field?.name in employee) {
                        updatedValues[field?.name] = employee[field?.name] || '';
                    } else if (field?.name in employee?.address) {
                        updatedValues[field?.name] = employee?.address[field?.name] || '';
                    } else {
                        updatedValues[field?.name] = '';
                    }
                });
            });

            setFormValues(updatedValues);

            // Set state and city options if they are present in the employee's address
            if (employee?.address?.state) {
                const selectedState = State.getStatesOfCountry("IN").find(state => state.isoCode === employee?.address?.state);
                if (selectedState) {
                    setCities(City.getCitiesOfState("IN", selectedState.isoCode));
                }
            }
        }
    }, [employee]);


    useEffect(() => {
        if (formValues.state) {
            const selectedState = states.find(state => state.isoCode === formValues.state);
            if (selectedState) {
                setCities(City.getCitiesOfState("IN", selectedState.isoCode));
            } else {
                setCities([]);
            }
        }
    }, [formValues.state, states]);

    const validation = () => {
        let error = false;
        Object.keys(formValues).forEach(key => {
            const value = formValues[key];
            if (!value.toString().trim()) {
                error = true;
                handleError('This field is required.', key);
            }
            else if (key === 'email' && !/^\S+@\S+\.\S+$/.test(value)) {
                error = true;
                handleError('Invalid email address', key);
            } else if (key === 'phone' && !/^\d{10}$/.test(value)) {
                error = true;
                handleError('Invalid phone number', key);
            } else if (key === 'first_name' && !/^[a-zA-Z ]+$/.test(value)) {
                error = true;
                handleError('Invalid first name', key);
            } else if (key === 'last_name' && !/^[a-zA-Z ]+$/.test(value)) {
                error = true;
                handleError('Invalid last name', key);
            } else if (key === 'designation' && !designations.includes(value)) {
                error = true;
                handleError('Invalid designation', key);
            } else if (key === 'city' && !cities.some(city => city?.name === value)) {
                error = true;
                handleError('Invalid city name', key);
            } else if (key === 'state' && !states.some(state => state?.isoCode === value)) {
                error = true;
                handleError('Invalid state code', key);
            } else {
                handleError('', key);
            }
        });
        return error;
    };

    const handleChange = (event, field) => {
        const value = field === 'picture' ? event.target.files[0] : event.target.value;
        if (field === 'state') {
            const selectedState = states.find(state => state.name === value);
            if (selectedState) {
                setFormValues(prev => ({ ...prev, [field]: selectedState.isoCode }));
                setCities(City.getCitiesOfState("IN", selectedState.isoCode));
            } else {
                setFormValues(prev => ({ ...prev, [field]: value }));
                setCities([]);
            }
        } else {
            setFormValues(prev => ({ ...prev, [field]: value }));
        }
        handleError('', field);
    };

    const handleClickShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const handleUpdate = async () => {
        if (validation()) {
            return;
        }

        try {
            let updatedFormValues = { ...formValues };
            const selectedShift = shifts.find(shift => `${shift.days}, ${new Date(shift.start_time).toLocaleTimeString()} - ${new Date(shift.end_time).toLocaleTimeString()}` === formValues.shift);
            if (selectedShift) {
                updatedFormValues.shift = selectedShift?._id;
            }

            let response = await post_data(`employee/update-employee/${employeeId}`, updatedFormValues);
            if (response.status === true) {
                toast.success('Employee updated successfully');
            } else {
                toast.error('Something went wrong!');
            }
        } catch (error) {
            // console.error("Error submitting form:", error);
        }
    };

    const handleSubmit = async () => {
        if (validation()) {
            return;
        }

        try {
            let updatedFormValues = { ...formValues, company_id: user_data?._id };
            const selectedShift = shifts?.find(shift => `${shift.days}, ${new Date(shift?.start_time).toLocaleTimeString()} - ${new Date(shift?.end_time).toLocaleTimeString()}` === formValues?.shift);
            if (selectedShift) {
                updatedFormValues.shift = selectedShift?._id;
            }

            const response = await post_data("employee/create-employee", updatedFormValues);

            if (response.status === true) {
                toast.success('Employee created successfully');
            } else {
                toast.error('Something went wrong!');
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div style={{ width: '100%' }}>

            <Grid container spacing={2} padding={2} margin={2} style={{ background: '#fff', border: '1px solid gainsboro', width: '98%', borderRadius: '5px' }}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 17, fontWeight: 500 }}>Regular Employee</Typography>
                </Grid>
                {sections?.map((section, sectionIndex) => (
                    <Grid item xs={12} key={sectionIndex}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography style={{ fontSize: 17, fontWeight: 500 }}>{section?.title}</Typography>
                            </Grid>
                            {section?.fields?.map((field, fieldIndex) => (
                                <Grid item xs={3} key={fieldIndex}>
                                    {field.type === 'select' ? (
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                                                <Select
                                                    labelId={`${field.name}-label`}
                                                    id={field.name}
                                                    value={formValues[field.name]}
                                                    label={field.label}
                                                    error={Boolean(errors[field.name])}
                                                    onChange={(e) => handleChange(e, field.name)}
                                                >
                                                    {field.options.map((option, optionIndex) => (
                                                        <MenuItem key={optionIndex} value={option}>{option}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {errors[field.name] && <span style={{ color: '#EA2024', fontSize: 14 }}>{errors[field.name]}</span>}
                                        </Box>
                                    ) : field.type === 'autocomplete' ? (
                                        <Autocomplete
                                            options={field.name === 'designation' ? designations : field.name === 'department' ? departments : field?.name === 'company' ? companies : field.name === 'shift' ? shifts.map(shift => `${shift.days}, ${new Date(shift.start_time).toLocaleTimeString()} - ${new Date(shift.end_time).toLocaleTimeString()}`) : field.name === 'state' ? states.map(state => state.name) : cities.map(city => city.name)}
                                            value={field.name === 'state' ? (states.find(state => state.isoCode === formValues[field.name])?.name || '') : formValues[field.name]}
                                            onChange={(event, newValue) => handleChange({ target: { value: newValue } }, field.name)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={field.label}
                                                    error={Boolean(errors[field.name])}
                                                    helperText={errors[field.name]}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    ) : field.type === 'password' ? (
                                        <TextField
                                            label={field.label}
                                            type={showPassword ? 'text' : 'password'}
                                            value={formValues[field.name]}
                                            onChange={(e) => handleChange(e, field.name)}
                                            error={Boolean(errors[field.name])}
                                            helperText={errors[field.name]}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            label={field.label}
                                            type={field.type === 'file' ? 'file' : field.type}
                                            placeholder={field.placeholder || ''}
                                            value={field.type !== 'file' ? formValues[field.name] : undefined}
                                            onChange={(e) => handleChange(e, field.name)}
                                            error={Boolean(errors[field.name])}
                                            helperText={errors[field.name]}
                                            fullWidth
                                            InputLabelProps={field.type === 'file' ? { shrink: true } : {}}
                                        />
                                    )}
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Divider style={{ margin: '10px 0px' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', marginLeft: 'auto', gap: 10 }}>
                        <Button variant="outlined" style={{ borderColor: primaryColor, color: primaryColor }}>Back</Button>
                        <Button style={{ boxShadow: 'none', background: secondaryColor }}
                            onClick={isUpdate ? handleUpdate : handleSubmit} variant="contained">{isUpdate ? 'Update' : 'Save'}</Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
