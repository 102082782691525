import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { formatPrice, primaryColor, secondaryColor } from '../../constant';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRazorpay } from 'react-razorpay';
import { useSelector } from 'react-redux';
import { post_data } from '../../api';
import { useNavigate } from 'react-router-dom';

export default function CheckoutModal({
    open, setOpen, selectedPlan, selectedTenure
}) {

    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const { error, isLoading, Razorpay } = useRazorpay();
    const handleClose = () => setOpen(false);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTablet = useMediaQuery('(max-width: 960px)');
    const navigate = useNavigate()

    const yearlyDiscountPrice = selectedPlan?.yearly_discount_price || 0;
    const gstAmount = user_data?.gst ? yearlyDiscountPrice * 0.18 : yearlyDiscountPrice * 0.05;
    const netPayable = yearlyDiscountPrice + gstAmount;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        bgcolor: 'white',
        border: 'none',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    const styles = {
        cartSection: {
            padding: '20px',
            backgroundColor: 'white',
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            boxSizing: "border-box",
        },
        summarySection: {
            padding: '20px',
            border: '1px solid #E0E0E0',
            borderRadius: '10px',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
        },
        cartItem: {
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-between',
            marginBottom: '20px',
            alignItems: 'start',
        },
        itemDetails: {
            display: 'flex',
            flexDirection: 'column',
        },
        itemActions: {
            display: 'flex',
            alignItems: 'center',
        },
        itemImage: {
            width: '80px',
            height: '80px',
            marginRight: '20px',
            borderRadius: 5
        },
        discountPrice: {
            fontWeight: '600',
            fontSize: '18px',
        },
        price: {
            fontWeight: '400',
            textDecoration: 'line-through',
            marginLeft: '10px',
            color: '#9E9E9E',
            fontSize: '18px',
        },
        discountCode: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        purchaseButton: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: secondaryColor,
            border: 'none',
            cursor: 'pointer',
            width: '100%',
            marginTop: '20px',
            boxShadow: 'none',
        },
        priceRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1% 0',
        },
    };


    const calculateExpiryDate = (tenure) => {
        const expiryDate = new Date();
        if (tenure === 'annual') {
            expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        } else if (tenure === 'monthly') {
            expiryDate.setMonth(expiryDate.getMonth() + 1);
        }
        return expiryDate.toISOString();
    };


    const handleRzrpPayment = async (params) => {

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();
    }


    const options = {
        key: "rzp_test_GQ6XaPC6gMPNwH",
        amount: netPayable * 100,
        currency: "INR",
        name: "Nivishka PMS",
        description: "Staff and Payroll Management",
        image: '/images/nivishka-pms-logo.png',

        handler: async function (response) {

            let payload = {
                company_id: user_data?._id,
                plan_id: selectedPlan?.id,
                plan_tenure: selectedTenure,
                plan_expiry_date: calculateExpiryDate(selectedTenure),
                payment_amount: netPayable,
                amount_before_gst: yearlyDiscountPrice,
                transaction_number: response.razorpay_payment_id
            };

            const result = await post_data('company-billings/create-billing', payload);

            if (result?.status) {
                handleClose();
                navigate('/payment-status', { state: { status: 'success' } })
                window.scrollTo(0, 0)
            }
            else {
                handleClose();
                navigate('/payment-status', { state: { status: 'failure' } })
                window.scrollTo(0, 0)
            }
        },
        prefill: {
            name: `${user_data?.name}`,
            email: user_data?.email,
            contact: user_data?.phone,
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: secondaryColor,
        },
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>

                    <Grid container spacing={2} style={{ width: '100%' }}>
                        <Grid item md={6} style={{ width: '50%' }}>
                            <Box sx={styles.summarySection}>

                                <Typography variant="h6" gutterBottom>{selectedPlan?.name} Plan</Typography>

                                <Box sx={styles.priceRow}>
                                    <Typography variant="body1" style={{ textTransform: 'capitalize' }}>{selectedTenure}</Typography>
                                </Box>

                            </Box>
                        </Grid>

                        <Grid item md={6} style={{ width: '50%' }}>
                            <Box sx={styles.summarySection}>

                                <Typography variant="h6" gutterBottom>Billing Details</Typography>

                                <Box sx={styles.priceRow}>
                                    <Typography variant="body1">Total:</Typography>
                                    <Typography variant="body1">{formatPrice(yearlyDiscountPrice)}</Typography>
                                </Box>

                                <Box sx={styles.priceRow}>
                                    <Typography variant="body1">GST ({user_data?.gst ? "18%" : "5%"}):</Typography>
                                    <Typography variant="body1">{formatPrice(gstAmount)}</Typography>
                                </Box>

                                <Box sx={styles.priceRow}>
                                    <Typography variant="body1" style={{ fontWeight: 500 }}>Net Payable:</Typography>
                                    <Typography variant="body1" style={{ fontWeight: 500 }}>{formatPrice(netPayable)}</Typography>
                                </Box>

                                <Button style={styles.purchaseButton} onClick={handleRzrpPayment}>Pay Now</Button>
                            </Box>
                        </Grid>

                    </Grid>

                </Box>
            </Modal>
        </div>
    );
}
