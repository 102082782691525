import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { post_data, serverURL } from '../../api';
import { formatDate, secondaryColor } from '../../constant';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import toast, { Toaster } from 'react-hot-toast';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';

export default function EmployeeDocs({ employeeData, fetchEmployeeDetails }) {
    const [selectedAadhar, setSelectedAadhar] = useState(null);
    const [selectedPan, setSelectedPan] = useState(null);
    const [anchorElAadhar, setAnchorElAadhar] = useState(null);
    const [anchorElPan, setAnchorElPan] = useState(null);
    const [openAadharModal, setOpenAadharModal] = useState(false);
    const [openPanModal, setOpenPanModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleAadharChange = (event) => {
        setSelectedAadhar(event.target.files[0]);
    };

    const handlePanChange = (event) => {
        setSelectedPan(event.target.files[0]);
    };

    const handleUploadAadhar = async () => {
        let formData = new FormData();
        formData.append('aadhar_card_img', selectedAadhar);
        setLoading(true);
        let result = await post_data(`employee/update-aadhar/${employeeData?._id}`, formData);
        if (result?.status === true) {
            toast.success('Aadhaar card uploaded successfully!');
            fetchEmployeeDetails()
        } else {
            toast.error('Something went wrong!');
        }
        setLoading(false);
    };

    const handleUploadPan = async () => {
        let formData = new FormData();
        formData.append('pan_card_img', selectedPan);
        setLoading(true);
        let result = await post_data(`employee/update-pan/${employeeData?._id}`, formData);
        if (result?.status === true) {
            toast.success('Pan card uploaded successfully!');
            fetchEmployeeDetails()
        } else {
            toast.error('Something went wrong!');
        }
        setLoading(false);
    };

    const handleUpdateAadhar = async () => {
        let formData = new FormData();
        formData.append('aadhar_card_img', selectedAadhar);
        setLoading(true);
        let result = await post_data(`employee/update-aadhar/${employeeData?._id}`, formData);
        if (result?.status === true) {
            toast.success('Aadhaar card updated successfully!');
            fetchEmployeeDetails()
            setOpenAadharModal(false);
        } else {
            toast.error('Something went wrong!');
        }
        setLoading(false);
    };

    const handleUpdatePan = async () => {
        let formData = new FormData();
        formData.append('pan_card_img', selectedPan);
        setLoading(true);
        let result = await post_data(`employee/update-pan/${employeeData?._id}`, formData);
        if (result?.status) {
            toast.success('Pan card updated successfully!');
            fetchEmployeeDetails()
            setOpenPanModal(false);
        } else {
            toast.error('Something went wrong!');
        }
        setLoading(false);
    };

    const handleMenuOpenAadhar = (event) => {
        setAnchorElAadhar(event.currentTarget);
    };

    const handleMenuCloseAadhar = () => {
        setAnchorElAadhar(null);
    };

    const handleMenuOpenPan = (event) => {
        setAnchorElPan(event.currentTarget);
    };

    const handleMenuClosePan = () => {
        setAnchorElPan(null);
    };

    const handleUpdateAadharClick = () => {
        setOpenAadharModal(true);
        handleMenuCloseAadhar();
    };

    const handleUpdatePanClick = () => {
        setOpenPanModal(true);
        handleMenuClosePan();
    };

    const handleDeleteAadhar = () => {
        handleMenuCloseAadhar();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: secondaryColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let deleteResult = await post_data(`employee/delete-aadhar/${employeeData?._id}`);
                if (deleteResult?.status) {
                    toast.success('Aadhaar card deleted successfully!');
                    fetchEmployeeDetails()
                } else {
                    toast.error('Failed to delete Aadhaar card.');
                }
            }
        });
    };

    const handleDeletePan = () => {
        handleMenuClosePan();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: secondaryColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let deleteResult = await post_data(`employee/delete-pan/${employeeData?._id}`);
                if (deleteResult?.status) {
                    toast.success('Pan card deleted successfully!');
                    fetchEmployeeDetails()
                } else {
                    toast.error('Failed to delete Pan card.');
                }
            }
        });
    };

    return (
        <>
            <div style={{ display: 'flex', gap: 20, width: '100%' }}>
                {
                    employeeData?.aadhar_card_img?.length === 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '2px dashed #ccc',
                                padding: '20px',
                                borderRadius: '8px',
                                width: 300,
                            }}
                        >
                            <CloudUploadIcon sx={{ fontSize: 50, color: secondaryColor }} />
                            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                                Upload Aadhaar Card
                            </Typography>
                            <Button
                                variant="contained"
                                component="label"
                                style={{
                                    background: 'white',
                                    border: '1px dashed gray',
                                    boxShadow: 'none',
                                    color: 'black'
                                }}
                                startIcon={<CloudUploadIcon style={{ color: secondaryColor }} />}
                            >
                                Choose File
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleAadharChange}
                                />
                            </Button>
                            {selectedAadhar && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        background: secondaryColor,
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => handleUploadAadhar()}
                                >
                                    {loading ? 'Uploading...' : 'Upload'}
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <Card sx={{ width: 300 }}>
                            <CardHeader
                                action={
                                    <IconButton aria-label="settings" onClick={handleMenuOpenAadhar}>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Aadhaar Card"
                                subheader={`Updated at ${formatDate(employeeData?.updated_at)}`}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={`${serverURL}/uploads/employee-docs/${employeeData?.aadhar_card_img}`}
                                alt="Aadhaar card image"
                            />
                        </Card>
                    )
                }

                <Menu
                    anchorEl={anchorElAadhar}
                    open={Boolean(anchorElAadhar)}
                    onClose={handleMenuCloseAadhar}
                >
                    <MenuItem onClick={handleUpdateAadharClick}>Update</MenuItem>
                    <MenuItem onClick={handleDeleteAadhar}>Delete</MenuItem>
                </Menu>

                {
                    employeeData?.pan_card_img?.length === 0 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '2px dashed #ccc',
                                padding: '20px',
                                borderRadius: '8px',
                                width: 300,
                            }}
                        >
                            <CloudUploadIcon sx={{ fontSize: 50, color: secondaryColor }} />
                            <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                                Upload Pan Card
                            </Typography>
                            <Button
                                variant="contained"
                                component="label"
                                style={{
                                    background: 'white',
                                    border: '1px dashed gray',
                                    boxShadow: 'none',
                                    color: 'black'
                                }}
                                startIcon={<CloudUploadIcon style={{ color: secondaryColor }} />}
                            >
                                Choose File
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handlePanChange}
                                />
                            </Button>
                            {selectedPan && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        mt: 2,
                                        background: secondaryColor,
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => handleUploadPan()}
                                >
                                    Upload
                                </Button>
                            )}
                        </Box>
                    ) : (
                        <Card sx={{ width: 300 }}>
                            <CardHeader
                                action={
                                    <IconButton aria-label="settings" onClick={handleMenuOpenPan}>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Pan Card"
                                subheader={`Updated at ${formatDate(employeeData?.updated_at)}`}
                            />
                            <CardMedia
                                component="img"
                                height="194"
                                image={`${serverURL}/uploads/employee-docs/${employeeData?.pan_card_img}`}
                                alt="Pan card image"
                            />
                        </Card>
                    )
                }

                <Menu
                    anchorEl={anchorElPan}
                    open={Boolean(anchorElPan)}
                    onClose={handleMenuClosePan}
                >
                    <MenuItem onClick={handleUpdatePanClick}>Update</MenuItem>
                    <MenuItem onClick={handleDeletePan}>Delete</MenuItem>
                </Menu>
            </div>

            <Dialog open={openAadharModal} onClose={() => setOpenAadharModal(false)}>
                <DialogTitle>Update Aadhaar Card</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please choose a new Aadhaar card image to upload.
                    </DialogContentText>
                    <input style={{ marginTop: '2%' }} type="file" accept="image/*" onChange={handleAadharChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateAadhar()} color="primary" style={{
                        background: secondaryColor,
                        color: 'white'
                    }}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openPanModal} onClose={() => setOpenPanModal(false)}>
                <DialogTitle>Update Pan Card</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please choose a new Pan card image to upload.
                    </DialogContentText>
                    <input style={{ marginTop: '2%' }} type="file" accept="image/*" onChange={handlePanChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdatePan()} color="primary" style={{
                        background: secondaryColor,
                        color: 'white'
                    }}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}