import styled from 'styled-components';
import { useState } from 'react';
import Swal from "sweetalert2";
import { TextField, Button, IconButton, InputAdornment, Dialog, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { login } from "../../redux/slices/user-slice"
import { post_data } from "../../api"
import { primaryColor, secondaryColor } from '../../constant';
import OtpComponent from './OtpComponent';
import toast from 'react-hot-toast';

const Container = styled.div`
  width:100vw; 
  height: 100vh;
  background: white;
`;

const LoginBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const FormSection = styled.div`
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  boxSizing:border-box;
`;

const ImageSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  margin-bottom: 1px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Link = styled.a`
  font-size: 14px;
  color: #6c63ff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

function SignupForm() {

    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [conformPassword, setConformPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConformPassword, setShowConformPassword] = useState(false);
    const [OtpBoxOpen, setOtpBoxOpen] = useState(false)

    const dispatch = useDispatch();

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 8000,
        timerProgressBar: true,
    });

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;

        if (email.length === 0) {
            error = true;
            handleError('email', 'Email is Required')
        } if (companyName.length === 0) {
            error = true;
            handleError('companyName', 'Company Name is Required')
        } if (phoneNumber.length === 0) {
            error = true;
            handleError('phoneNumber', 'Phone Number is Required')
        } if (password.length === 0) {
            error = true;
            handleError('password', 'Password is Required')
        } if (conformPassword.length === 0) {
            error = true;
            handleError('conformPassword', 'Conform Password is Required')
        }

        return error;
    }


    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleSignup();
        }
    };

    const showOtp = () => {
        return <Dialog maxWidth={'l'} open={OtpBoxOpen} onClose={() => setOtpBoxOpen(false)} >
            <DialogContent style={{ padding: 0 }} sx={{ width: '400px', borderRadius: '30px' }}>
                <OtpComponent handleResend={handleSignup} email={email} phone={phoneNumber} name={companyName} password={password} type={'signup'} />
            </DialogContent>
        </Dialog>
    }

    const handleSignup = async () => {
        const error = validate();
        if (!error) {
            if (password === conformPassword) {
                try {
                    setLoader(true);
                    const data = await post_data("company/send-otp-for-company-signup", { email, phone: phoneNumber });
                    if (data.status === true) {
                        setLoader(false);
                        setOtpBoxOpen(true)

                    } else {
                        toast.error(data.response?.data?.message || "Something went wrong");
                        setLoader(false);
                    }
                } catch (error) {
                    setLoader(false);
                }
            } else {
                toast.error("Password and Confirm Password should be same");
            }
        }
    }


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConformPassword = () => {
        setShowConformPassword(!showConformPassword);
    };


    return (
        <Container  >
            <LoginBox>
                <FormSection>
                    <Title>Welcome Back!</Title>
                    <Subtitle>Please Sign un to continue</Subtitle>
                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        type="text"
                        value={companyName}
                        label="Company Name"
                        className={errors.companyName ? "errorborder" : ""}
                        onChange={(e) => {
                            setCompanyName(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, companyName: "" })
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.companyName}</small>
                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        type="email"
                        value={email}
                        label="Email Address"
                        className={errors.email ? "errorborder" : ""}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, email: "" })
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.email}</small>
                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        type="number"
                        value={phoneNumber}
                        label="Phone Number"
                        className={errors.phoneNumber ? "errorborder" : ""}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, phoneNumber: "" })
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.phoneNumber}</small>

                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        className={errors.password ? "errorborder" : ""}
                        fullWidth
                        label="Enter Password"
                        name="newPassword"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, password: "" })
                        }}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.password}</small>

                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        className={errors.conformPassword ? "errorborder" : ""}
                        fullWidth
                        label="Enter Conform Password"
                        name="newConformPassword"
                        type={showConformPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={conformPassword}
                        onChange={(e) => {
                            setConformPassword(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, conformPassword: "" })
                        }}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConformPassword}
                                        edge="end"
                                    >
                                        {showConformPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.password}</small>


                    <Button variant='contained'
                        style={{
                            boxShadow: 'none', padding: '3% 0', background: secondaryColor
                        }}
                        disabled={loader} onClick={handleSignup}>Sign up</Button>

                    <Link href="/login" style={{ marginTop: '3%' }}>Already have an account, Sign in!</Link>

                </FormSection>

                {showOtp()}
            </LoginBox>

        </Container>
    );
};

export default SignupForm;
