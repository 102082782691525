import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { formatDate, formatPrice, primaryColor } from "../../constant";

const CompTansactionsModal = ({ open, setOpen, data, type }) => {
    const contentRef = useRef(null);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };

    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = 210;
            const pageHeight = 295;
            const margin = 10;
            const tableStartY = 20;
            const rowHeight = 10;

            let positionY = tableStartY;

            pdf.setFontSize(12);
            pdf.text("Invoice no.", margin, positionY);
            {
                type === 'admin' && (
                    pdf.text("Company", margin + 30, positionY)
                )
            }
            pdf.text("Plan Name", margin + 30, positionY);
            pdf.text("Tenure", margin + 60, positionY);
            pdf.text("Amount", margin + 80, positionY);
            pdf.text("Paid Amount", margin + 100, positionY);
            pdf.text("Purchased Date", margin + 130, positionY);
            pdf.text("Expiry Date", margin + 165, positionY);

            positionY += rowHeight;

            pdf.setFontSize(10);
            pdf.text(data?.invoice_number, margin, positionY);
            {
                type === 'admin' && (
                    pdf.text(data?.company_id?.name || '-', margin + 30, positionY)
                )
            }
            pdf.text(data?.plan_id?.name || '-', margin + 30, positionY);
            pdf.text(data?.plan_tenure, margin + 60, positionY);
            pdf.text(formatPrice(data?.amount_before_gst) || '-', margin + 80, positionY);
            pdf.text(formatPrice(data?.payment_amount) || '-', margin + 100, positionY);
            pdf.text(formatDate(data?.created_at) || '-', margin + 130, positionY);
            pdf.text(formatDate(data?.plan_expiry_date), margin + 165, positionY);

            positionY += rowHeight;

            pdf.save("plan_invoice.pdf");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                    <div ref={contentRef} id="invoice" style={styles.invoice}>
                        <div style={styles.header}>
                            <div style={styles.brand}>
                                <h2 style={{ fontWeight: 600, margin: 0, }}>Billing Details</h2>
                            </div>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>Billing Invoice</h2>
                        </div>

                        <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                            <thead style={{ textAlign: "left" }}>
                                <tr>
                                    <th>Invoice no.</th>
                                    {
                                        type === 'admin' && (
                                            <th>Company</th>
                                        )
                                    }
                                    <th>Plan Name</th>
                                    <th>Tenure</th>
                                    <th>Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Purchased Date</th>
                                    <th>Expiry Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                    <td style={{ padding: '1% 0' }}>{data?.invoice_number || '-'}</td>
                                    {
                                        type === 'admin' && (
                                            <td style={{ padding: '1% 0' }}>{data?.company_id?.name || '-'}</td>
                                        )
                                    }
                                    <td style={{ padding: '1% 0' }}>{data?.plan_id?.name || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{data?.plan_tenure}</td>
                                    <td style={{ padding: '1% 0' }}>{formatPrice(data?.amount_before_gst) || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{formatPrice(data?.payment_amount) || '-'}</td>
                                    <td style={{ padding: '1% 0' }}>{formatDate(data?.created_at)} </td>
                                    <td style={{ padding: '1% 0' }}>{formatDate(data?.plan_expiry_date)} </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ ...styles.footer, ...styles.text }}>
                            <div style={styles.terms}>
                                <p>For Niviska PMS</p>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

export default CompTansactionsModal;