import { Avatar, Button, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { CgWorkAlt } from "react-icons/cg";
import { PiBuildingsLight } from "react-icons/pi";
import { RiPassportLine } from "react-icons/ri";
import { FaIdCard } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdWorkspacePremium } from "react-icons/md";
import { red, blue, purple, yellow } from '@mui/material/colors';
import { formatDate, formatPrice, formatTime, primaryColor, secondaryColor } from "../../constant";
import { FaCircleUser } from "react-icons/fa6";
import Empty from "../../global/components/Empty";
import toast from "react-hot-toast";
import { useState, useRef } from "react";
import { post_data, serverURL } from "../../api";
import { FaCamera } from "react-icons/fa";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { LuClock8 } from "react-icons/lu";


export default function EmployeeCard({
    employeeData,
    setEmployeeData,
    recentAttendance,
    recentFiles,
    fetchEmployeeDetails
}) {
    const [profileLocal, setProfileLocal] = useState(null);
    const [profile, setProfile] = useState(employeeData?.profile_picture || '');
    const [showUploadDelete, setShowUploadDelete] = useState(false);
    const [camraOpen, setCamraOpen] = useState(false);
    const fileInputRef = useRef(null);

    const compensations = [
        {
            amount: '862.00 USD per month',
            effectiveDate: 'May 10, 2015',
        },
        {
            amount: '1560.00 USD per quarter',
            effectiveDate: 'Jun 08, 2022',
        },
        {
            amount: '378.00 USD per week',
            effectiveDate: 'Jun 08, 2022',
        }
    ];

    const aboutInfo = [
        {
            icon: <FaPhoneAlt style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Phone',
            value: `+91 ${employeeData?.phone}`
        },
        {
            icon: <MdOutlineMail style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Email',
            value: employeeData?.email
        },
    ]

    const addressInfo = [
        {
            icon: <CgWorkAlt style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Address',
            value: employeeData?.address?.street || "-"
        },
        {
            icon: <IoLocationOutline style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Pincode',
            value: employeeData?.address?.zip_code || "-"
        },
        {
            icon: <PiBuildingsLight style={{ color: 'gray', fontSize: 14 }} />,
            title: 'City, State, Country',
            value: `${employeeData?.address?.city || "-"}, ${employeeData?.address?.state || "-"}, ${employeeData?.address?.country || "-"}`
        },
    ]


    const employeeDetails = [
        {
            icon: <LuClock8 style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Shift',
            value: formatTime(employeeData?.shift?.start_time) + ' --- ' + formatTime(employeeData?.shift?.end_time)
        },
        {
            icon: <LiaRupeeSignSolid style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Salary',
            value: formatPrice(employeeData?.salary?.base_salary)
        },
        {
            icon: <RiPassportLine style={{ color: 'gray', fontSize: 14 }} />,
            title: 'DOB',
            type: 'date',
            value: employeeData?.dob
        },
        {
            icon: <FaIdCard style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Aadhar Card',
            value: employeeData?.aadhar_card || "-"
        },
        {
            icon: <FaIdCard style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Pan Card',
            value: employeeData?.pan_card || "-"
        },
        {
            icon: <MdWorkspacePremium style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Designation',
            value: employeeData?.designation?.name || "-"
        },
        {
            icon: <PiBuildingsLight style={{ color: 'gray', fontSize: 14 }} />,
            title: 'Department',
            value: employeeData?.department?.name || "-"
        },
        {
            icon: <FaRegCalendarAlt style={{ color: 'gray', fontSize: 14 }} />,
            type: 'date',
            title: 'Hire Date',
            value: employeeData?.hire_date || "-"
        },
        {
            icon: <FaRegCalendarAlt style={{ color: 'gray', fontSize: 14 }} />,
            type: 'date',
            title: 'Join Date',
            value: employeeData?.date_of_joining || "-"
        },
    ]

    const handleUploadPicture = async () => {
        const formData = new FormData();
        formData.append("profile_picture", profile);

        const data = await post_data(`employee/update-profile-picture/${employeeData?._id}`, formData);

        if (data?.status) {
            toast.success("Profile Picture Updated Successfully");
            setEmployeeData(data?.data);
        }
        else {
            toast.error(data?.response?.data?.message || "Failed to update profile picture");
        }
        setProfile('');
        setProfileLocal('');
    }

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid item md={4} style={{ borderRight: '1px solid gainsboro' }}>
                    <div style={{ display: 'flex', gap: 15, alignItems: 'start' }}>

                        <div>
                            <div className="profile-header">
                                {employeeData?.profile_picture ?
                                    <div>
                                        <div className="icon-container" onClick={handleIconClick}>
                                            <Avatar className="user-icon" src={profileLocal ? profileLocal : `${serverURL}/uploads/employee-profile-picture/${employeeData?.profile_picture}`} style={{ height: '60px', width: '60px' }} />
                                            <FaCamera className="camera-icon" />
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={(e) => {
                                                    setProfile(e.target.files[0]);
                                                    setProfileLocal(URL.createObjectURL(e.target.files[0]))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="icon-container" onClick={handleIconClick}>
                                        <Avatar className="user-icon" src={profileLocal} sx={{ height: '60px', width: '60px' }} />
                                        <FaCamera className="camera-icon" />
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                setProfile(e.target.files[0]);
                                                setProfileLocal(URL.createObjectURL(e.target.files[0]))
                                            }}
                                        />
                                    </div>}
                                {profileLocal && <div>
                                    <Button style={{ color: "#5DB761" }} onClick={handleUploadPicture}>{employeeData?.profile_picture ? "Update" : "Upload"}</Button>
                                    <Button style={{ color: "#5DB761" }} onClick={() => {
                                        setProfile('');
                                        setProfileLocal('');
                                    }}>Cancel</Button>
                                </div>}
                            </div>

                            <div className="profile-info">
                                <p style={{ fontWeight: 500, fontSize: 20, color: 'black', marginTop: 8 }}>{`${employeeData?.first_name} ${employeeData?.last_name}`}</p>
                                {/* <p>{employeeData?.full_name === '' ? '' : employeeData?.user_name}</p> */}
                            </div>
                        </div>



                        {/* <div onMouseEnter={() => setCamraOpen(true)} onMouseLeave={() => setCamraOpen(false)} >
                            {profileLocal ? (
                                // Display avatar with local uploaded picture
                                <Avatar
                                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                    alt="Profile Picture"
                                    src={profileLocal?.filename}
                                />
                            ) : employeeData?.profile_picture?.length > 0 ? (
                                // Display avatar with employee data picture and overlay button
                                <div style={{ position: 'relative', width: 100, height: 100 }}>
                                    <Avatar
                                        sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                        alt="Profile Picture"
                                        src={`${serverURL}/uploads/employee-profile-picture/${employeeData?.profile_picture}`}
                                    />
                                    {camraOpen ? <div
                                        style={{
                                            position: 'absolute',
                                            bottom: '0px',
                                            right: '0px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            borderRadius: '50%',
                                            color: 'white',
                                            zIndex: 10,
                                            padding: 5,
                                        }}
                                    >
                                        <IconButton component="label" sx={{ color: 'white' }}>
                                            <CameraAltIcon sx={{ fontSize: 20 }} />
                                            <input onChange={handleLocalPicture} hidden type="file" accept="image/*" />
                                        </IconButton>
                                    </div> : ""}
                                </div>
                            ) : (
                                <Avatar
                                    sx={{ width: 100, height: 100, cursor: 'pointer', backgroundColor: '#f0f0f0' }}
                                    alt="Default Profile"
                                >
                                    <IconButton
                                        component="label"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            color: 'white',
                                        }}
                                    >
                                        <CameraAltIcon />
                                        <input onChange={handleLocalPicture} hidden type="file" accept="image/*" />
                                    </IconButton>
                                </Avatar>
                            )}
                        </div>

                        <div>
                            <h3 style={{ fontWeight: 500, fontSize: 20, margin: 0 }}>{employeeData?.first_name} {employeeData?.last_name}</h3>
                            <p style={{ opacity: '70%', margin: '3%' }}>{employeeData?.employee_id}</p>
                        </div> */}

                    </div>
                    {/* {showUploadDelete && (
                        <div style={{ display: 'flex', gap: 20, marginTop: '10px' }}>
                            <Button onClick={handleUploadPicture} variant="contained" style={{ background: `${secondaryColor}`, color: '#fff', boxShadow: 'none', padding: '8px 15px' }}>
                                Upload
                            </Button>
                            <Button onClick={handleDeletePicture} variant="outlined" color={primaryColor} style={{ padding: '0px 44px 0px 44px' }}>
                                Delete
                            </Button>
                        </div>
                    )} */}

                    <div>
                        <h3 style={{ fontWeight: 500, fontSize: 17, marginTop: '6%' }}>About</h3>
                        {
                            aboutInfo?.map((item) => {
                                return (
                                    <>
                                        <div style={{ display: 'flex', gap: 10, alignItems: 'center', margin: '4% 0' }}>
                                            {item?.icon}
                                            <span style={{ color: 'gray', fontSize: 14 }}>{item?.title}: </span>
                                            <span style={{ fontSize: 14 }}>{item?.value}</span>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>

                    <Divider style={{ margin: '6% 0 4%', width: '90%' }} />

                    <div>
                        <h3 style={{ fontWeight: 500, fontSize: 17, marginTop: '6%' }}>Address</h3>
                        {
                            addressInfo?.map((item) => {
                                return (
                                    <>
                                        <div style={{ display: 'flex', gap: 10, alignItems: 'center', margin: '4% 0' }}>
                                            {item?.icon}
                                            <span style={{ color: 'gray', fontSize: 14 }}>{item?.title}: </span>
                                            <span style={{ fontSize: 14 }}>{item?.value}</span>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>

                    <Divider style={{ margin: '6% 0 4%', width: '90%' }} />


                    <div>
                        <h3 style={{ fontWeight: 500, fontSize: 17, marginTop: '6%' }}>Employee Details</h3>
                        {
                            employeeDetails?.map((item) => {
                                return (
                                    <>
                                        <div style={{ display: 'flex', gap: 10, alignItems: 'center', margin: '4% 0' }}>
                                            {item?.icon}
                                            <span style={{ color: 'gray', fontSize: 14 }}>{item?.title}: </span>
                                            <span style={{ fontSize: 14 }}>
                                                {
                                                    item?.type === 'date' ? (
                                                        formatDate(item?.value)
                                                    ) : (
                                                        item?.value
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>

                </Grid>
                <Grid item md={8} style={{ padding: '0 2%' }}>


                    <TableContainer component={Paper} elevation={0} style={{ marginTop: '20px', borderRadius: '10px' }}>
                        <h3 style={{ fontWeight: 500, fontSize: 17, margin: 0 }}>Recent Attendance</h3>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ padding: 0 }}>DATE</TableCell>
                                    <TableCell>PUNCH IN</TableCell>
                                    <TableCell>PUNCH OUT</TableCell>
                                    <TableCell>LOCATION IN</TableCell>
                                    <TableCell>LOCATION OUT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    recentAttendance?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <>
                                            {recentAttendance?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ padding: 0 }}>
                                                        <Typography variant="body2" fontWeight={500}>
                                                            {formatDate(item?.created_at)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" fontWeight={500}>
                                                            {item?.punch_in || '-'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" fontWeight={500}>
                                                            {item?.punch_out || '-'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" fontWeight={500}>
                                                            {item?.location_in?.latitude}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2" fontWeight={500}>
                                                            {item?.location_out?.latitude}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Grid container spacing={1} style={{ marginTop: '3%' }}>
                        <Grid item xs={12} md={12}>
                            <h3 style={{ fontWeight: 500, fontSize: 17, margin: 0 }}>Recent Files</h3>
                            {
                                recentFiles?.length === 0 ? (
                                    <Empty />
                                ) : (
                                    <>
                                        <List>
                                            {recentFiles?.map((file, index) => (
                                                <ListItem key={index} divider>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ bgcolor: file.avatarColor, textTransform: 'capitalize' }}>{file?.name.charAt(0)}</Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <>
                                                                <Typography component="span" fontSize={15} fontWeight={500}>{file?.name}</Typography>
                                                                <Typography component="span" fontSize={15} color="textSecondary"> {file?.action} </Typography>
                                                                <Typography component="span" fontSize={15} fontWeight={500}>{formatDate(file?.date)}</Typography>
                                                            </>
                                                        }
                                                        secondary={
                                                            <Typography component="span" color="textSecondary" fontSize={15}>
                                                                {file?.time}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                        {/* <Button variant="text" style={{ color: secondaryColor, marginTop: 1, fontSize: 13 }}>View all</Button> */}
                                    </>
                                )
                            }
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <h3 style={{ fontWeight: 500, fontSize: 17, margin: 0 }}>Compensation</h3>
                            <List>
                                {compensations.map((compensation, index) => (
                                    <ListItem key={index} divider>
                                        <ListItemText
                                            primary={
                                                <Typography component="span" fontWeight={500} fontSize={15}>
                                                    {compensation.amount}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography component="span" color="textSecondary" fontSize={15}>
                                                    Effective date on {compensation.effectiveDate}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            <Button variant="text" style={{ color: secondaryColor, marginTop: 1, fontSize: 13 }}>View all</Button>
                        </Grid> */}
                    </Grid>


                </Grid>
            </Grid >
        </>
    )
}