import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { primaryColor, secondaryColor } from '../../constant';
import EmployeeCard from './EmployeeCard';
import CalendarComp from "../components/CalendarComp"
import BackButton from '../../global/components/BackButton';
import EmployeeFilesComp from './EmployeeFilesComp';
import PayrollComp from './PayrollComp';
import { get_data } from '../../api';
import EmployeeAttendance from './EmployeeAttendance';
import EmployeeDocs from './EmployeeDocs';
import UpgradePlan from '../../global/components/UpgradePlan';
import EmployeeLocation from './EmployeeLocation';
import { useSelector } from 'react-redux';

export default function EmployeeComp({ isAdmin, fullPlanPermissions }) {
    const { user_data } = useSelector(state => state.user);
    const [value, setValue] = React.useState(0);
    const [employeeData, setEmployeeData] = React.useState({})
    const [recentAttendance, setRecentAttendance] = React.useState([])
    const [recentFiles, setRecentFiles] = React.useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    let employeeId = localStorage.getItem('employeeId')

    const fetchRecentFiles = async () => {
        let result = await get_data(`employee/get-recent-files/${employeeId}`)
        if (result?.status) {
            setRecentFiles(result?.data)
        }
    }

    const fetchEmployeeDetails = async () => {
        let result = await get_data(`employee/get-employee/${employeeId}`)
        if (result?.status) {
            setEmployeeData(result?.data)
        }
    }

    const fetchRecentAttendance = async () => {
        let result = await get_data(`attendance/get-recent-attendance-of-employee/${employeeId}`)
        if (result?.status) {
            setRecentAttendance(result?.data)
        }
    }

    React.useEffect(function () {
        fetchEmployeeDetails()
        fetchRecentFiles()
        fetchRecentAttendance()
    }, [employeeId])

    return (
        <>
            <BackButton />
            <Box>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: secondaryColor,
                                height: '3px',  // Set the height of the bottom border (indicator)
                            },
                        }}
                    >
                        <Tab
                            label="Details"
                            {...a11yProps(0)}
                            // Customize the Tab text color
                            sx={{
                                color: value === 0 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Attendance"
                            {...a11yProps(1)}
                            sx={{
                                color: value === 1 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Payroll"
                            {...a11yProps(2)}
                            sx={{
                                color: value === 2 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Files"
                            {...a11yProps(3)}
                            sx={{
                                color: value === 3 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Location"
                            {...a11yProps(3)}
                            sx={{
                                color: value === 4 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                        <Tab
                            label="Documents"
                            {...a11yProps(3)}
                            sx={{
                                color: value === 5 ? primaryColor : 'inherit',
                                '&.Mui-selected': { color: primaryColor }, textTransform: 'capitalize'
                            }}
                        />
                    </Tabs>
                </Box>
            </Box>

            <div style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <CustomTabPanel value={value} index={0}>
                    <EmployeeCard employeeData={employeeData} setEmployeeData={setEmployeeData} recentFiles={recentFiles} fetchEmployeeDetails={fetchEmployeeDetails} recentAttendance={recentAttendance} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <EmployeeAttendance employeeData={employeeData} fetchEmployeeDetails={fetchEmployeeDetails} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <PayrollComp employeeData={employeeData} fetchEmployeeDetails={fetchEmployeeDetails} isAdmin={isAdmin} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    {
                        fullPlanPermissions?.includes('employee_files') ? (
                            <EmployeeFilesComp employeeData={employeeData} fetchEmployeeDetails={fetchEmployeeDetails} isAdmin={isAdmin} />
                        ) : (
                            <UpgradePlan title={user_data?.isPlan ? 'Upgrade Your Plan to access this Feature' : 'Buy Plan to access this Feature'} />
                        )
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    {
                        employeeData?.location_tracking ? (
                            <EmployeeLocation data={recentAttendance?.[0]?.location_in} fetchRecentAttendance={fetchRecentAttendance} />
                        ) : (
                            <>Live Location Tracking was is not opt-in for this Employee</>
                        )
                    }
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <EmployeeDocs employeeData={employeeData} fetchEmployeeDetails={fetchEmployeeDetails} />
                </CustomTabPanel>
            </div>
        </>
    );
}