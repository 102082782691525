import React, { useState } from 'react';

const tabData = [
    { title: 'Sustainable Living', content: 'Prioritising eco-friendly practices for minimal environmental impact.', icon: 'https://www.concorde.in/assets-new/images/smart-icon1.svg' },
    { title: 'Modern Design', content: 'Seamlessly blending functionality with aesthetic appeal.', icon: 'https://www.concorde.in/assets-new/images/smart-icon2.svg' },
    { title: 'Amenities - Rich', content: 'Enhancing your lifestyle with best-in-class amenities.', icon: 'https://www.concorde.in/assets-new/images/smart-icon3.svg' },
    { title: 'Real Value', content: 'Ensuring each investment meets quality and financial standards.', icon: 'https://www.concorde.in/assets-new/images/smart-icon4.svg' },
    { title: 'Tech-enabled Homes', content: 'Enabling smarter living with integrated technology solutions.', icon: 'https://www.concorde.in/assets-new/images/smart-icon5.svg' },
];

const Features = () => {

    const [activeTab, setActiveTab] = useState(0);

    return (
        <>
            <center>
                <h3 style={{
                    fontSize: 15,
                    textTransform: 'uppercase',
                    color: '#40c944',
                    margin: '5% auto 2%'
                }}>Our Features</h3>
            </center>

            <div className="smart-philosophy">
                <div className="tabs">
                    {tabData.map((tab, index) => (
                        <button
                            key={index}
                            className={`tab-button ${activeTab === index ? 'active' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            {tab.title}
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    <div className="icon">
                        <img src={tabData[activeTab].icon} />
                    </div>
                    <p>{tabData[activeTab].content}</p>
                </div>
            </div>
        </>
    );
};

export default Features;