import Footer from "../components/Footer";
import Header from "../components/Header";
import PricingPlans from "../components/PricingPlans";

export default function Pricing() {
    return (
        <>
            <Header />
            <PricingPlans />
            <Footer />
        </>
    )
}