import React from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody } from '@mui/material';
import { formatDate } from '../../../constant';
import { serverURL } from '../../../api';

const CompanyDetailsModal = ({ open, setOpen, data }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
            height: '450px',
            overflow:'scroll'
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,

        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'end'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };

    const userAddress = data?.address
        ? `${data.address.street || "-"}, ${data.address.city || "-"}, ${data.address.zipCode || "-"}, ${data.address.state || "-"}`
        : '-';

    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={styles.dataLabel}>{label}</span>
            <span style={styles.dataValue}>{value}</span>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Company Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    {/* <img src={`${serverURL}/uploads/files/${data?.logo}`}  /> */}
                    <DataRow label="Name" value={data?.name || '-'} />
                    <DataRow label="Email" value={data?.email || '-'} />
                    <DataRow label="Phone" value={data?.phone || '-'} />
                    <DataRow label="Date" value={formatDate(data?.created_at) || "-"} />
                    <DataRow label="Support Phone" value={data?.support_phone || "-"} />
                    <DataRow label="Support Email" value={data?.support_email || "-"} />
                    <DataRow label="Hr Email" value={data?.hr_email || "-"} />
                    <DataRow label="Gst" value={data?.gst || "-"} />
                    <DataRow label="Website" value={data?.website || "-"} />
                    <DataRow label="Address" value={userAddress || "-"} />
                    {/* <DataRow label="Verified Phone" value={data?.isPhoneVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} />
                    <DataRow label="Verified Email" value={data?.isEmailVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} />
                    <DataRow label="Premium User" value={data?.isMembership ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} /> */}
                    {/* <DataRow label="Aadhar Verified" value={data?.isAadharVerified ? <img src='/images/check-icon.svg' style={styles.icon} alt="Verified" /> : '-'} /> */}
                    <div>
                        {/* <DataRow label="Details" value={''} />
                            <Table style={{
                                border:'.5px solid grey',
                                fontSize:'.85rem',
                                borderRadius:'10px'
                            }}
                            cellSpacing={0}
                           
                            >
                                <TableHead style={{
                                border:'.5px solid grey',
                            }}>
                                    <tr style={{
                                border:'.5px solid grey',
                            }}>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontWeight:500
                            }}>DeviceUniqueId</td>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontWeight:500
                            }}>Imei</td>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontWeight:500
                            }}>SimNumber</td>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontWeight:500
                            }}>VehicleNumber</td>
                                    </tr>
                                </TableHead>
                                <TableBody >
                                    {data?.details?.map((item,index)=>{
                                        return <tr style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontSize:'.8rem'
                            }}>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontSize:'.8rem'
                            }}>{item?.deviceUniqueId || "-"}</td>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontSize:'.8rem'
                            }}>{item?.imei || "-"}</td>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontSize:'.8rem'
                            }}>{item?.simNumber || "-"}</td>
                                        <td style={{
                                border:'.5px solid grey',
                                padding:'5px',
                                fontSize:'.8rem'
                            }}>{item?.vehicleNumber || "-"}</td>
                                        </tr >
                                    })}
                                </TableBody>
                            </Table> */}
                    </div>
                </Box>
            </Box>
        </Modal>
    );
};

export default CompanyDetailsModal;
