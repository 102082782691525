import React, { useState, useEffect } from 'react';
import { Box, Grid, Avatar, Button, TextField, Typography, IconButton } from '@mui/material';
import { primaryColor, secondaryColor } from '../../constant';
import LogoutIcon from '@mui/icons-material/Logout';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Swal from "sweetalert2";
import { Country, State, City } from 'country-state-city';
import Autocomplete from '@mui/material/Autocomplete';
import { login, logout } from '../../redux/slices/user-slice';
import { useDispatch, useSelector } from 'react-redux';
import { post_data } from '../../api';
import toast, { Toaster } from 'react-hot-toast';
import ChangePassModal from '../../global/components/ChangePassModal';
import { useNavigate } from 'react-router-dom';
import ChangeEmailModel from './ChangeEmailModel';

const AdminSettingComp = () => {

    const { user_data } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [name, setName] = useState(user_data?.name || '');
    const [phone, setPhone] = useState(user_data?.phoneNumber || '');
    const [email, setEmail] = useState(user_data?.email || '');
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()
    const [changePassOpen, setChangePassOpen] = useState(false)
    const [openChangeEmail, setOpenChangeEmail] = useState(false);

    useEffect(() => {
        if (user_data?.address?.state) {
            const selectedCountry = Country.getAllCountries().find(country => country?.isoCode === 'IN');
            const selectedState = State.getStatesOfCountry('IN').find(st => st?.isoCode === user_data?.address?.state);
            const selectedCity = City.getCitiesOfState('IN', user_data?.address?.state).find(ct => ct?.name === user_data?.address?.city);
            setCountry(selectedCountry);
            setState(selectedState);
            setCity(selectedCity);
        }
    }, [user_data]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!name.trim()) {
            error = true;
            handleError('Please input name', 'name');
        }
        if (!phone.trim() || !/^[\d]{10}$/.test(phone)) {
            error = true;
            handleError('Please input a valid phone number', 'phone');
        }
        if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) {
            error = true;
            handleError('Please input a valid email', 'email');
        }
        return error;
    };

    const handleUpdate = async () => {
        const error = validation();
        if (error) return;

        const updatedData = {
            name,
            phoneNumber: phone,
            email,
        };

        try {
            const response = await post_data(`super-admin/update-super-admin/${user_data?._id}`, updatedData);
            if (response.status) {
                dispatch(login(response?.data));
                toast.success('Profile updated successfully');
            } else {
                toast.error('Failed to update profile');
            }
        } catch (error) {
        }
    };

    const handleLogout = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: `${primaryColor}`,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('memberId');
                dispatch(logout());
                navigate('/admin/login')
                window.scrollTo(0, 0)
            }
        });
    };

    return (

        <Box>

            <ChangePassModal open={changePassOpen} setOpen={setChangePassOpen} type={'admin'} />

            <ChangeEmailModel open={openChangeEmail} setOpen={setOpenChangeEmail} type={'admin'} />

            {/* Tabs Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <Typography sx={{ fontWeight: '500', paddingBottom: '0.5rem', borderBottom: '3px solid ' + secondaryColor }}>
                    General settings
                </Typography>
            </Box>

            {/* Main Grid Layout */}
            <Grid container spacing={3}>
                {/* Profile Details Section */}
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }} >
                                <Typography variant="h6" fontWeight="500" gutterBottom>
                                    Profile Details
                                </Typography>

                                <Grid container spacing={2} >
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Name"
                                            fullWidth
                                            value={name}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                            onFocus={() => handleError('', 'name')}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            onFocus={() => handleError('', 'email')}
                                            onChange={(event) => setEmail(event.target.value)}
                                            label="Email"
                                            fullWidth
                                            disabled
                                            value={email}
                                            variant="outlined"
                                        />
                                        <div onClick={() => setOpenChangeEmail(true)} style={{ color: secondaryColor, textDecoration: 'none', cursor: 'pointer' }}>Change Email</div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            label="Phone"
                                            fullWidth
                                            error={!!errors.phone}
                                            helperText={errors.phone}
                                            onFocus={() => handleError('', 'phone')}
                                            onChange={(event) => setPhone(event.target.value)}
                                            value={phone}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={7} >
                                        <Button style={{ background: secondaryColor, boxShadow: 'none' }}
                                            variant='contained'
                                            onClick={handleUpdate}
                                        >Update</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Sidebar Section */}
                <Grid item xs={12} md={4} >
                    <div style={{ background: 'white', borderRadius: 5, padding: '6%', border: '1px solid gainsboro' }}>
                        <Typography variant="h6" fontWeight="500" gutterBottom>
                            Change Password
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            You can change or reset your password, in case you forget it.
                        </Typography>
                        <Button variant="contained" style={{ marginTop: '1rem', width: '100%', boxShadow: 'none', background: secondaryColor, padding: '3% 0' }} onClick={() => setChangePassOpen(true)}>
                            Change Password
                        </Button>
                    </div>

                    {/* Close Account */}
                    <div style={{ background: 'white', borderRadius: 5, padding: '6%', border: '1px solid gainsboro', marginTop: '5%' }}>
                        <Button onClick={handleLogout} variant="outlined" color="error" sx={{ width: '100%', gap: 2, cursor: 'pointer' }}>
                            <LogoutIcon sx={{ width: 20 }} /> Logout
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </Box>

    );
};

export default AdminSettingComp;
