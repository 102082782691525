import { Button, Grid } from "@mui/material";
import CompanySettingsComp from "../components/CompanySettingsComp";

export default function CompanySettings() {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
        padding: '2%'
    };


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={page_container}>
                    <CompanySettingsComp />
                </div>

            </Grid>
        </>
    )
}