import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CompChangePass from '../../company-dashboard/pages/CompChangePass';
import ChangeAdminPasswordComp from '../../company-dashboard/components/modals/ChangeAdminPasswordComp';
import ChangeEditEployeePass from '../../company-dashboard/components/ChangeEditEployeePass';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: 'none',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function ChangePassModal({
    type,
    open,
    setOpen,
    data,
}) {

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography> */}
                    {
                        type === 'company' ? (
                            <CompChangePass handleClose={handleClose} />
                        ) : type === 'EditEmployee' ? (
                            <ChangeEditEployeePass setOpen={setOpen} data={data} />
                        ) : (
                            <ChangeAdminPasswordComp handleClose={handleClose} />
                        )
                    }
                </Box>
            </Modal>
        </div>
    );
}
