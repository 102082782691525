import { Box, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import * as XLSX from 'xlsx';
import { formatDate, formatPrice, primaryColor } from "../../../constant";
import Empty from "../../../global/components/Empty";

const AdminExportBillingsModal = ({ exportOpen, setExportOpen, data, fromDate, toDate }) => {

    const contentRef = useRef(null);

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const pdf = new jsPDF("p", "mm", "a4");
            const pageWidth = 210;
            const pageHeight = 295;
            const margin = 10;
            const tableStartY = 20;
            const rowHeight = 10;
            const rowsPerPage = Math.floor((pageHeight - tableStartY - margin) / rowHeight);

            // Clone the data to paginate through it
            const dataClone = [...data];
            let positionY = tableStartY;
            let pageNumber = 1;

            // Render each page
            while (dataClone.length > 0) {
                const currentPageData = dataClone.splice(0, rowsPerPage);

                pdf.setFontSize(12);
                pdf.text("Invoice no.", margin, positionY);
                pdf.text("Company", margin + 30, positionY)
                pdf.text("Plan Name", margin + 30, positionY);
                pdf.text("Tenure", margin + 70, positionY);
                pdf.text("Amount", margin + 90, positionY);
                pdf.text("Paid Amount", margin + 120, positionY);
                pdf.text("Purchased Date", margin + 150, positionY);
                pdf.text("Expiry Date", margin + 170, positionY);

                positionY += rowHeight;

                currentPageData.forEach((item, i) => {
                    pdf.setFontSize(10);

                    pdf.text(data?.invoice_number, margin, positionY);
                    pdf.text(data?.company_id?.name || '-', margin + 30, positionY)
                    pdf.text(data?.plan_id?.name || '-', margin + 30, positionY);
                    pdf.text(data?.plan_tenure, margin + 70, positionY);
                    pdf.text(formatPrice(data?.amount_before_gst) || '-', margin + 90, positionY);
                    pdf.text(formatPrice(data?.payment_amount) || '-', margin + 120, positionY);
                    pdf.text(formatDate(data?.created_at) || '-', margin + 150, positionY);
                    pdf.text(formatDate(data?.plan_expiry_date), margin + 170, positionY);

                    positionY += rowHeight;
                });

                if (dataClone.length > 0) {
                    pdf.addPage();
                    positionY = tableStartY;
                    pageNumber++;
                }
            }

            pdf.save("invoice.pdf");
        }
    };


    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        maxHeight: '90%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        padding: '0 0 5%',
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: primaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '15%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: '2%'
    }

    return (
        <>
            <Modal
                open={exportOpen}
                onClose={() => setExportOpen(false)}
            >
                <Box sx={styleModal}>
                    {
                        data?.length === 0 ? (
                            <>
                                <Empty />
                            </>
                        ) : (
                            <>
                                <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                                <div ref={contentRef} id="invoice" style={styles.invoice}>
                                    <div style={styles.header}>
                                        <div style={styles.brand}>
                                            <h2 style={{ fontWeight: 600, margin: 0, }}>Billings</h2>
                                        </div>
                                    </div>

                                    <table style={{ ...styles.table, ...styles.text, margin: "3% 0" }}>
                                        <thead style={{ textAlign: "left" }}>
                                            <tr>
                                                <th>Invoice no.</th>
                                                <th>Company</th>
                                                <th>Plan Name</th>
                                                <th>Tenure</th>
                                                <th>Amount</th>
                                                <th>Paid Amount</th>
                                                <th>Purchased Date</th>
                                                <th>Expiry Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((item, i) => {
                                                    return (
                                                        <>
                                                            <tr style={{ margin: 0, borderBottom: '1px solid gainsboro' }}>
                                                                <td style={{ padding: '1% 0' }}>{item?.invoice_number || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.company_id?.name || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.plan_id?.name || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{item?.plan_tenure}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatPrice(item?.amount_before_gst) || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatPrice(item?.payment_amount) || '-'}</td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.created_at)} </td>
                                                                <td style={{ padding: '1% 0' }}>{formatDate(item?.plan_expiry_date)} </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ ...styles.footer, ...styles.text }}>
                                        <div style={styles.terms}>
                                            <p>All Invoices are from {formatDate(fromDate)} to {formatDate(toDate)} period.</p>
                                            <p>For Nivishka PMS</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </Box>
            </Modal>
        </>
    );
};

export default AdminExportBillingsModal;