import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CiFilter } from "react-icons/ci";
import { useState, useEffect } from 'react';
import { Button, FormControl, Autocomplete, TextField } from '@mui/material';
import { primaryColor, secondaryColor } from '../../constant';
import { Country, State, City } from 'country-state-city';
import { get_data, post_data } from '../../api';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

export default function FilterModal({
    isAdmin, employees, setEmployees, department,
    setDepartment, setRowsData, setTotalData, setTotalPages
}) {

    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState('');
    const { user_data } = useSelector(state => state.user);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setCountry(null);
        setState(null);
        setCity(null);
        setDesignations([]);
    };

    useEffect(() => {
        const fetchDesignations = async () => {
            try {
                let result = await get_data(`designation/get-all-designations-by-company-for-options/${user_data?._id}`);
                if (result?.status === true && Array.isArray(result?.data)) {
                    setDesignations(result?.data);
                } else {
                    setDesignations([]);
                }
            } catch (error) {
                console.error("Failed to fetch designations:", error);
                setDesignations([]);
            }
        };
        fetchDesignations();

        const getAllDepartments = async () => {
            try {
                const data = await get_data(`department/get-all-departments-by-company-for-options/${user_data?._id}`);
                if (data.status) {
                    setDepartments(data?.data?.map(department => department?.name));

                }
            } catch (error) {
                setDepartments([]);
            }
        };
        getAllDepartments();

    }, [user_data]);

    const handleApply = async () => {
        const filter = {
            country: country?.isoCode,
            state: state?.isoCode,
            city: city?.name,
            // department: department,
            // designation: selectedDesignation,
            // company_id: user_data?._id
        }

        const result = await post_data("employee/get-filtered-employee", filter);

        if (result?.status) {
            setRowsData(result?.data?.employee);
            setTotalData(result?.data?.totalEmployees);
            setTotalPages(result?.data?.totalPages);
            setOpen(false);
        }
    }

    return (
        <div>
            <div onClick={handleOpen}>
                <div><CiFilter fontSize={25} /></div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Box sx={{ minWidth: 120, padding: '20px 0px 20px 0px' }}>
                        <Autocomplete
                            options={Country.getAllCountries()}
                            getOptionLabel={(option) => option.name}
                            value={country}
                            onChange={(e, value) => {
                                setCountry(value);
                                setState(null);
                                setCity(null);
                            }}
                            renderInput={(params) => <TextField {...params} label="Country" />}
                        />
                    </Box>

                    <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                        <Autocomplete
                            options={country ? State.getStatesOfCountry(country.isoCode) : []}
                            getOptionLabel={(option) => option.name}
                            value={state}
                            onChange={(e, value) => {
                                setState(value);
                                setCity(null);
                            }}
                            renderInput={(params) => <TextField {...params} label="State" />}
                        />
                    </Box>

                    <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                        <Autocomplete
                            options={state ? City.getCitiesOfState(state.countryCode, state.isoCode) : []}
                            getOptionLabel={(option) => option.name}
                            value={city}
                            onChange={(e, value) => setCity(value)}
                            renderInput={(params) => <TextField {...params} label="City" />}
                        />
                    </Box>

                    {
                        !isAdmin && (
                            <>
                                <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                                    <Autocomplete
                                        options={designations.filter(designation => designation?.name)}
                                        getOptionLabel={(option) => option.name || ""}
                                        value={employees}
                                        onChange={(e, value) => {
                                            setEmployees(value)
                                            setSelectedDesignation(value?.name)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Designation" />}
                                    />
                                </Box>

                                <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                                    <Autocomplete
                                        options={departments}
                                        getOptionLabel={(option) => option || ""}
                                        value={department}
                                        onChange={(e, value) => setDepartment(value)}
                                        renderInput={(params) => <TextField {...params} label="Department" />}
                                    />
                                </Box>
                            </>
                        )
                    }


                    <Box sx={{ padding: '5px 0px 20px 0px' }}>
                        <Button style={{ background: `${secondaryColor}`, color: '#fff' }} onClick={handleApply} fullWidth>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
