import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IoMdAdd } from "react-icons/io";
import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { primaryColor, createButtonColor, secondaryColor } from '../../../constant';
import { useSelector } from 'react-redux';
import { get_data, post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};


export default function AadDepartmentModal({ refresh, setRefresh, isEdit, departmentId }) {

    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [department, setDepartment] = useState('')

    const handleError = (error, label) => {
        setErrors(prev => ({ ...prev, [label]: error }));
    };

    const { isLoggedIn, user_data } = useSelector(state => state.user);

    const validation = () => {
        let error = false;
        if (!department.trim()) {
            error = true;
            handleError('Please input Department', 'department');
        }

        return error;
    };

    const fetchDepartmentDetails = async () => {
        let result = await get_data(`department/get-department/${departmentId}`)
        if (result?.status == true) {
            setDepartment(result?.data?.name)
        }
    }

    React.useEffect(function () {
        fetchDepartmentDetails()
    }, [])


    const handleSubmit = async () => {
        const hasError = validation();
        if (!hasError) {
            try {

                if (isEdit == true) {
                    let payload = { name: department, company_id: user_data?._id }
                    let result = await post_data(`department/update-department/${departmentId}`, payload)
                    if (result?.status == true) {
                        toast.success('Department updated successfully!');
                        setRefresh(!refresh)
                        handleClose()
                    }
                } else {
                    let payload = { name: department, company_id: user_data?._id }
                    let result = await post_data('department/create-department', payload)
                    if (result?.status == true) {
                        toast.success('Department created successfully!');
                        setRefresh(!refresh)
                        handleClose()
                    }
                }

            } catch (error) {
            }
        }
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setDepartment('')
    }

    return (
        <div>

            <div onClick={handleOpen}>
                {isEdit ? <div>Edit</div> : <Button startIcon={<IoMdAdd />} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                    Add Department
                </Button>}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <TextField
                        value={department}
                        onFocus={() => handleError('', 'department')}
                        error={errors.department}
                        helperText={errors.department}
                        onChange={(e) => setDepartment(e.target.value)}
                        label='Add Department' fullWidth />

                    <Box sx={{ minWidth: 120, padding: '20px 0px 20px 0px', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Button onClick={handleSubmit} style={{ background: `${secondaryColor}`, color: '#fff' }} fullWidth>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}