import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { useNavigate } from "react-router-dom";
import PayrollTable from "../components/tables/PayrollTable";
import SearchComponent from "../../global/components/SearchComponent";
import TableLoader from "../../global/components/TableLoader";
import Empty from "../../global/components/Empty";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import { createButtonColor, primaryColor } from "../../constant";
import AddSalaryModal from "../components/modals/AddSalaryModal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import toast from "react-hot-toast";
import PayrollTransactionModal from "../components/PayrollTransactionModal";


export default function ExpensesComp() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [open, setOpen] = useState(false)
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [value, setValue] = useState('')
    const { user_data } = useSelector(state => state.user);
    const [searchState, setSearchState] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [payrollsData, setPayrollsData] = useState([]);
    const [exportOpen, setExportOpen] = useState(false);


    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            if (value !== '') {
                searchData();
                setSearchState(true);
            } else {
                setSearchState(false);
                getAllPayrolls();
            }
        }, 500);
    };


    const searchData = async () => {
        try {
            const response = await get_data(`payroll/search-payrolls/${value}/${user_data?._id}?pageNumber=${currentPage}`);

            setLoading(false);
            if (response.status) {
                setData(response?.data?.payrolls);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalPayrolls);
            } else {
                setData([]);
            }
        } catch (error) {
            setLoading(false);
        }
    };



    const getAllEmployees = async () => {
        try {
            const data = await get_data(`employee/get-all-employees-by-company-for-options/${user_data?._id}`);
            if (data.status) {
                setEmployees(data?.data);
            }
        } catch (error) {
        }
    }


    const getAllPayrolls = async () => {
        setLoading(true);
        try {
            let body = { company_id: user_data?._id }
            const data = await post_data(`payroll/get-all-payrolls?pageNumber=${currentPage}`, body);
            if (data.status) {
                setData(data?.data?.payrolls);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalPayrolls);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getAllEmployees()
    }, [])

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchData()
            }
            else {
                getAllPayrolls();
            }
        }
    }, [currentPage, searchState, refresh, user_data])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = ["S No.", "Employee Name", "Month", "Base Salary", "Net Salary", "Status", "Paid Date", "Download"]

    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '4% 0% 4% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };



    const handleDateChange = async (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
            try {
                const fromDate = moment(startDate).toISOString();
                const toDate = moment(endDate).toISOString();
            } catch (error) {
            }
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
        }
    }, [startDate, endDate]);

    const datePicker = {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        borderRadius: 3,
        border: '1px solid gray',
        gap: 10
    }

    const handleClick = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response

            response = await post_data('payroll/get-all-payrolls-by-date', {
                from: fromDate,
                to: toDate,
                company_id: user_data?._id
            })

            if (response?.status) {
                setPayrollsData(response?.data?.payrolls)
                setExportOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {
                toast.error('Error! Something went wrong!')
            }
        } catch (error) {
        }
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>
                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                        <SearchComponent handleSearch={handleSearch} setValue={setValue} value={value} />
                    </div>

                    <Button startIcon={<IoMdAdd />} onClick={() => setOpen(true)} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                        Add Payroll
                    </Button>

                    <span style={datePicker}>
                        <div style={{ ...rangePickerStyle, border: 'none', }}>
                            <DatePicker
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                dateFormat="dd-MM-yyyy"
                                maxDate={new Date()}
                                customInput={
                                    <button style={{ border: 'none', background: '#fff', cursor: 'pointer', }}>
                                        {startDate && endDate ? `${moment(startDate).format('DD-MM-YYYY')} to ${moment(endDate).format('DD-MM-YYYY')}` : 'Select Date Range'}
                                    </button>
                                }
                            />
                        </div>
                        <Backdrop
                            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </span>


                    <Button onClick={handleClick} variant="contained" style={{ background: primaryColor, boxShadow: 'none' }}>
                        Export
                    </Button>

                    {
                        open &&
                        <AddSalaryModal open={open} setOpen={setOpen} getAllPayrolls={getAllPayrolls} type='payrollTable' employees={employees} refresh={refresh} setRefresh={setRefresh} />
                    }

                </div>

                <div style={page_container}>

                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (<>
                                        <PayrollTable
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            type='main_payroll'
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllPayrolls={getAllPayrolls}
                                        />
                                        <PayrollTransactionModal
                                            setExportOpen={setExportOpen}
                                            exportOpen={exportOpen}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            data={payrollsData}
                                        /></>
                                    )
                                }
                            </>
                        )
                    }
                </div>

            </Grid>
        </>
    )
}