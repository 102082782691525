import React, { useState, useEffect } from 'react';
import { Box, Grid, Avatar, Button, TextField, Typography, IconButton } from '@mui/material';
import { primaryColor, secondaryColor } from '../../constant';
import LogoutIcon from '@mui/icons-material/Logout';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Swal from "sweetalert2";
import { Country, State, City } from 'country-state-city';
import Autocomplete from '@mui/material/Autocomplete';
import { login, logout } from '../../redux/slices/user-slice';
import { useDispatch, useSelector } from 'react-redux';
import { post_data, serverURL } from '../../api';
import toast, { Toaster } from 'react-hot-toast';
import ChangePassModal from '../../global/components/ChangePassModal';
import { useNavigate } from 'react-router-dom';
import { member_logout } from '../../redux/slices/member-slice';
import ChangeEmailModel from './ChangeEmailModel';

const CompanySettingsComp = () => {

    const { user_data } = useSelector(state => state.user);
    const { member_data } = useSelector(state => state.member);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [profilePicture, setProfilePicture] = useState(user_data?.logo || '');
    const [name, setName] = useState(user_data?.name || '');
    const [phone, setPhone] = useState(user_data?.phone || '');
    const [email, setEmail] = useState(user_data?.email || '');
    const [street, setStreet] = useState(user_data?.address?.street || '');
    const [zipCode, setZipCode] = useState(user_data?.address?.zip_code || '');
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [website, setWebsite] = useState(user_data?.website || '');
    const [gst, setGst] = useState(user_data?.gst || '');
    const [errors, setErrors] = useState({});
    const [showUploadDelete, setShowUploadDelete] = useState(false);
    const [localPicture, setLocalPicture] = useState(null);
    const [changePassOpen, setChangePassOpen] = useState(false)
    const [support_phone, setSupport_phone] = useState('')
    const [support_email, setSupport_email] = useState('')
    const [hr_email, setHr_email] = useState('')
    const [open, setOpen] = useState(false)
    const [openChangeEmail, setOpenChangeEmail] = useState(false);

    useEffect(() => {
        if (user_data?.address?.state) {
            const selectedCountry = Country.getAllCountries().find(country => country.isoCode === 'IN');
            const selectedState = State.getStatesOfCountry('IN').find(st => st.isoCode === user_data?.address.state);
            const selectedCity = City.getCitiesOfState('IN', user_data?.address.state).find(ct => ct.name === user_data?.address.city);
            setCountry(selectedCountry);
            setState(selectedState);
            setCity(selectedCity);
        }
        setSupport_email(user_data?.support_email)
        setSupport_phone(user_data?.support_phone)
        setHr_email(user_data?.hr_email)
    }, [user_data]);

    const handleError = (error, label) => {
        setErrors((prev) => ({ ...prev, [label]: error }));
    };

    const validation = () => {
        let error = false;
        if (!name.trim()) {
            error = true;
            handleError('Please input name', 'name');
        }
        if (!phone.trim() || !/^[\d]{10}$/.test(phone)) {
            error = true;
            handleError('Please input a valid phone number', 'phone');
        }
        if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) {
            error = true;
            handleError('Please input a valid email', 'email');
        }
        return error;
    };

    const handleUpdate = async () => {
        const error = validation();
        if (error) return;

        const updatedData = {
            name,
            phone,
            email,
            website,
            gst,
            support_phone,
            support_email,
            hr_email,
            address: {
                street,
                zip_code: zipCode,
                city: city?.name || '',
                state: state?.isoCode || ''
            }
        };

        try {
            const response = await post_data(`company/update-company/${user_data._id}`, updatedData);
            if (response.status) {
                toast.success('Profile updated successfully');
            } else {
                toast.error('Failed to update profile');
            }
        } catch (error) {
            // console.error("Error updating profile:", error);
        }
    };

    const handlePicture = (event) => {
        setProfilePicture({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) });
        setShowUploadDelete(true);
    };

    const handleLocalPicture = (event) => {
        setLocalPicture(URL.createObjectURL(event.target.files[0]));
        setProfilePicture({ bytes: event.target.files[0], filename: URL.createObjectURL(event.target.files[0]) });
        setShowUploadDelete(true);
    };

    const handleUploadPicture = async () => {
        if (profilePicture.bytes) {
            const formData = new FormData();
            formData.append('logo', profilePicture.bytes);
            try {
                const response = await post_data(`company/upload-logo/${user_data._id}`, formData);
                if (response.status) {
                    dispatch(login(response.data));
                    setShowUploadDelete(false);
                    setLocalPicture(null);
                    toast.success('Logo uploaded successfully');
                } else {
                    toast.error('Failed to upload logo');
                }
            } catch (error) {
                toast.error('An unexpected error occurred');
            }
        }
    };

    const handleDeletePicture = () => {
        setProfilePicture(user_data.logo || '');
        setLocalPicture(null);
        setShowUploadDelete(false);
    };

    const handleLogout = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: `${primaryColor}`,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('memberId');
                if (member_data?.is_member) {
                    dispatch(member_logout());
                    navigate('/member/login')
                }
                else {
                    dispatch(logout());
                    navigate('/login')
                }
            }
        });
    };


    const handleCloseAccount = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: `${primaryColor}`,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Close My Account!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await post_data(`company/close_account/${user_data?._id}`)
                // localStorage.removeItem('authToken');
                // dispatch(logout());
            }
        });
    };


    return (
        <Box>
            <ChangePassModal open={changePassOpen} setOpen={setChangePassOpen} type={'company'} />

            <ChangeEmailModel open={openChangeEmail} setOpen={setOpenChangeEmail} type={'company'} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                <Typography sx={{ fontWeight: '500', paddingBottom: '0.5rem', borderBottom: '3px solid ' + secondaryColor }}>
                    General settings
                </Typography>
            </Box>

            {/* Main Grid Layout */}
            <Grid container spacing={3}>
                {/* Profile Details Section */}

                {
                    member_data?.is_member ? (
                        <></>
                    ) : (
                        <>
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }} >
                                            <Typography variant="h6" fontWeight="500" gutterBottom>
                                                Profile Details
                                            </Typography>
                                            <Grid sx={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem', gap: 5, position: 'relative' }}>

                                                {
                                                    localPicture ? (
                                                        <Avatar
                                                            sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                            alt="Profile Picture"
                                                            src={localPicture}
                                                        >
                                                        </Avatar>
                                                    )
                                                        :
                                                        user_data?.logo?.length > 0 ? (
                                                            <>
                                                                <Avatar
                                                                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                                    alt="Profile Picture"
                                                                    src={`${serverURL}/uploads/company-logo/${user_data?.logo}`}
                                                                >
                                                                </Avatar>
                                                                <IconButton
                                                                    component="label"
                                                                    sx={{ position: 'absolute', bottom: '0', left: 70, backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff' }}
                                                                >
                                                                    <CameraAltIcon sx={{ fontSize: 14 }} />
                                                                    <input onChange={handleLocalPicture} hidden type="file" accept="image" />
                                                                </IconButton>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Avatar
                                                                    sx={{ width: 100, height: 100, cursor: 'pointer' }}
                                                                    alt="Profile Picture"
                                                                    src={`${profilePicture?.filename}`}
                                                                >
                                                                    <IconButton
                                                                        component="label"
                                                                        sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(0, 0, 0, 0.5)', color: '#fff' }}
                                                                    >
                                                                        <CameraAltIcon />
                                                                        <input onChange={handlePicture} hidden type="file" accept="image" />
                                                                    </IconButton>
                                                                </Avatar>
                                                            </>
                                                        )
                                                }

                                                {showUploadDelete && (
                                                    <Grid style={{ display: 'flex', gap: 20, marginLeft: '20px' }}>
                                                        <Button onClick={handleUploadPicture} variant="contained" style={{ background: `${secondaryColor}`, color: '#fff', boxShadow: 'none', padding: '8px 15px' }}>
                                                            Upload
                                                        </Button>
                                                        <Button onClick={handleDeletePicture} variant="outlined" color={primaryColor} style={{ padding: '0px 44px 0px 44px' }}>
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>

                                            <Grid container spacing={2} >
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        label="Name"
                                                        fullWidth
                                                        value={name}
                                                        error={!!errors.name}
                                                        helperText={errors.name}
                                                        onFocus={() => handleError('', 'name')}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        error={!!errors.email}
                                                        helperText={errors.email}
                                                        onFocus={() => handleError('', 'email')}
                                                        onChange={(event) => setEmail(event.target.value)}
                                                        label="Email"
                                                        fullWidth
                                                        disabled
                                                        value={email}
                                                        variant="outlined"
                                                    />
                                                    <span onClick={() => setOpenChangeEmail(true)} style={{ fontSize: 14, color: secondaryColor, cursor: 'pointer' }}>change email</span>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Phone"
                                                        fullWidth
                                                        error={!!errors.phone}
                                                        helperText={errors.phone}
                                                        onFocus={() => handleError('', 'phone')}
                                                        onChange={(event) => setPhone(event.target.value)}
                                                        value={phone}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Website"
                                                        fullWidth
                                                        onChange={(event) => setWebsite(event.target.value)}
                                                        value={website}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="GST"
                                                        fullWidth
                                                        onChange={(event) => setGst(event.target.value)}
                                                        value={gst}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Support Phone"
                                                        fullWidth
                                                        onChange={(event) => setSupport_phone(event.target.value)}
                                                        value={support_phone}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Support Email"
                                                        fullWidth
                                                        onChange={(event) => setSupport_email(event.target.value)}
                                                        value={support_email}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <TextField
                                                        label="Hr Email"
                                                        fullWidth
                                                        onChange={(event) => setHr_email(event.target.value)}
                                                        value={hr_email}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Box style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro', padding: 20 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        options={Country.getAllCountries()}
                                                        getOptionLabel={(option) => option.name}
                                                        value={country}
                                                        onChange={(e, value) => {
                                                            setCountry(value);
                                                            setState(null);
                                                            setCity(null);
                                                        }}
                                                        onFocus={() => handleError('', 'country')}
                                                        renderInput={(params) => <TextField {...params} label="Country" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        options={country ? State.getStatesOfCountry(country.isoCode) : []}
                                                        getOptionLabel={(option) => option.name}
                                                        value={state}
                                                        onChange={(e, value) => {
                                                            setState(value);
                                                            setCity(null);
                                                        }}
                                                        onFocus={() => handleError('', 'state')}
                                                        renderInput={(params) => <TextField {...params} label="State" />}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        options={state ? City.getCitiesOfState(country.isoCode, state.isoCode) : []}
                                                        getOptionLabel={(option) => option.name}
                                                        value={city}
                                                        onChange={(e, value) => setCity(value)}
                                                        onFocus={() => handleError('', 'city')}
                                                        renderInput={(params) => <TextField {...params} label="City" />}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        error={errors.street}
                                                        helperText={errors.street}
                                                        onFocus={() => handleError('', 'street')}
                                                        onChange={(e) => setStreet(e.target.value)}
                                                        label='Street' fullWidth value={street} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        error={errors.zipCode}
                                                        helperText={errors.zipCode}
                                                        onFocus={() => handleError('', 'zipCode')}
                                                        onChange={(e) => setZipCode(e.target.value)}
                                                        type='number' label='Zipcode' fullWidth value={zipCode} />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Button onClick={handleUpdate} variant="contained" style={{ background: `${secondaryColor}`, color: '#fff', padding: '8px 44px 8px 44px', marginTop: '10px', boxShadow: 'none' }}  >
                                                        Update
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )
                }

                {/* Sidebar Section */}
                <Grid item xs={12} md={4} >

                    {
                        member_data?.is_member ? (
                            <></>
                        ) : (
                            <div style={{ background: 'white', borderRadius: 5, padding: '6%', border: '1px solid gainsboro' }}>
                                <Typography variant="h6" fontWeight="500" gutterBottom>
                                    Change Password
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    You can change or reset your password, in case you forget it.
                                </Typography>
                                <Button onClick={() => setChangePassOpen(true)} variant="contained" style={{ marginTop: '1rem', width: '100%', boxShadow: 'none', background: secondaryColor, padding: '3% 0' }}>
                                    Change Password
                                </Button>
                            </div>
                        )
                    }

                    {/* Close Account */}
                    <div style={{ background: 'white', borderRadius: 5, padding: '6%', border: '1px solid gainsboro', marginTop: '5%' }}>
                        <Typography variant="h6" fontWeight="500" gutterBottom>
                            Logout
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            You can safely logout to keep your account secure.
                        </Typography>
                        {/* <Button variant="outlined" onClick={handleCloseAccount} color="error" sx={{ marginTop: '1rem', width: '100%' }}>
                            Close Account
                        </Button> */}
                        <Button onClick={handleLogout} variant="outlined" color="error" sx={{ marginTop: '1rem', width: '100%', gap: 2, cursor: 'pointer' }}>
                            <LogoutIcon sx={{ width: 20 }} /> Logout
                        </Button>
                    </div>
                </Grid>


            </Grid>
        </Box>
    );
};

export default CompanySettingsComp;
