import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Avatar,
    Button,
    Typography,
    Menu,
    MenuItem,
    IconButton,
    Pagination,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDate } from '../../../constant';
import { post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';

const useStyles = makeStyles({
    root: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: '7px 10px',
        },
        '& .MuiTableRow-root': {
            borderBottom: '1px solid gainsboro',
        },
        '& .MuiCheckbox-root': {
            color: '#a3a3a3',
        },
    },
    statusButton: {
        padding: '4px 12px',
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: 'bold',
        '&.closedOwn': {
            backgroundColor: '#e8f5e9',
            color: '#43a047',
        },
        '&.pending': {
            backgroundColor: '#fff3e0',
            color: '#fb8c00',
        },
        '&.approved': {
            backgroundColor: '#e3f2fd',
            color: '#1e88e5',
        },
        '&.rejected': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },
        '&.closedLost': {
            backgroundColor: '#ffebee',
            color: '#e53935',
        },

    },
    avatarGroup: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarName: {
        marginLeft: '8px',
        fontSize: 14
    },
    moreIcon: {
        color: '#a3a3a3',
    },
});

const ResignationTable = ({
    data,
    tableHeader,
    currentPage,
    setCurrentPage,
    totalData,
    totalPages,
    getAllResignation,
    itemsPerPage
}) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);


    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalData);

    const handleUpdateStatus = async (row, status) => {
        try {
            let payload = { status: status }
            const data = await post_data(`resignation/update-resignation/${row?._id}`, payload);
            if (data?.status) {
                getAllResignation()
                toast.success(status === 'approved' ? 'Resignation Approved successfully!' : 'Resignation Rejected successfully!');
            }
        } catch (error) {
            getAllResignation()
            toast.success('Something went wrong!');
        }
    }

    return (
        <>

            <TableContainer style={{ background: 'white', borderRadius: 5, border: '1px solid gainsboro' }}>
                <Table className={classes.root}>
                    <TableHead>
                        <TableRow>
                            {
                                tableHeader?.map((item) => {
                                    return (
                                        <TableCell style={{ borderRight: '1px solid gainsboro', padding: '16px 10px' }}>{item}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderRight: '1px solid gainsboro', textAlign: 'center', width: 60 }}>{index + 1}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.employee_id?.first_name + ' ' + row?.employee_id?.last_name}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{formatDate(row?.resignation_submitted_date)}</TableCell>
                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>{row?.reason_for_leaving}</TableCell>

                                <TableCell style={{ borderRight: '1px solid gainsboro' }}>
                                    <Button style={{ fontSize: 11, fontWeight: 500 }}
                                        className={`${classes.statusButton} ${row?.status
                                            .toLowerCase()
                                            .replace(' ', '')}`}
                                    >
                                        {row?.status}
                                    </Button>
                                </TableCell>

                                <TableCell style={{ borderRight: '1px solid gainsboro', width: 60 }}>
                                    <IconButton
                                        className={classes.moreIcon}
                                        onClick={(event) => handleMenuOpen(event, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>


                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl) && currentRow === index}
                                        onClose={handleMenuClose}
                                    >
                                        {/* <MenuItem ><EditStatusModal rowData={row?.status} /></MenuItem> */}
                                        <MenuItem onClick={() => {
                                            setAnchorEl(null);
                                            setCurrentRow(null);
                                            handleUpdateStatus(row, 'approved')
                                        }}>Approve</MenuItem>
                                        <MenuItem onClick={() => {
                                            setAnchorEl(null);
                                            setCurrentRow(null);
                                            handleUpdateStatus(row, 'rejected')
                                        }}>Reject</MenuItem>
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {data?.length >= 0 && (
                    <div
                        style={{
                            borderTop: "1px solid gainsboro",
                            padding: "2%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            style={{
                                fontWeight: 500,
                                fontSize: 15,
                                color: "black",
                            }}
                        >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                )}

            </TableContainer>
        </>
    );
};

export default ResignationTable;