import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../global/components/SearchComponent";
import TableLoader from "../../global/components/TableLoader";
import { createButtonColor, primaryColor } from "../../constant";
import ShiftsTable from "../components/tables/ShiftsTable";
import AddShiftModal from "../components/modals/AddShiftModal";
import Empty from "../../global/components/Empty";
import { useSelector } from "react-redux";

export default function Shifts() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const { user_data } = useSelector(state => state.user);
    const [refresh, setRefresh] = useState(false)

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const getAllShifts = async () => {
        try {
            const data = await get_data(`shifts/get-all-shifts/${user_data?._id}?pageNumber=${currentPage}`);
            if (data.status) {
                setData(data?.data?.shifts);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalShifts);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllShifts(currentPage);
        }
    }, [currentPage, refresh])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);


    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = ["S No.", "Start Time", "End Time", "Days"]


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <AddShiftModal refresh={refresh} setRefresh={setRefresh} />
                    </div>
                </div>

                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <ShiftsTable
                                            type='employee'
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllShifts={getAllShifts}
                                            refresh={refresh}
                                            setRefresh={setRefresh}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>

            </Grid>
        </>
    )
}