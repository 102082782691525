import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarComp = () => {
    // Sample events for attendance and holidays
    const [events, setEvents] = useState([
        {
            title: 'Present',
            start: new Date(2024, 8, 8),  // Sept 8, 2024
            end: new Date(2024, 8, 8),
            allDay: true,
            eventType: 'attendance',
        },
        {
            title: 'Holiday',
            start: new Date(2024, 8, 10),  // Sept 10, 2024
            end: new Date(2024, 8, 10),
            allDay: true,
            eventType: 'holiday',
        },
    ]);

    // Add a new event
    const handleSelect = ({ start, end }) => {
        const title = window.prompt('Enter Event Name (e.g., Present, Holiday):');
        const eventType = window.prompt('Event Type (attendance/holiday):');
        if (title && eventType) {
            setEvents([
                ...events,
                {
                    start,
                    end,
                    title,
                    allDay: true,
                    eventType,
                },
            ]);
        }
    };

    return (
        <div style={{ height: '500px' }}>
            <Calendar
                selectable
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={handleSelect}
                style={{ height: 500, margin: '50px' }}
                eventPropGetter={(event) => {
                    const backgroundColor = event.eventType === 'attendance' ? '#00FF00' : '#FF0000';
                    return { style: { backgroundColor } };
                }}
            />
        </div>
    );
};

export default CalendarComp;
