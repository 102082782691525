import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { MdDashboard, MdPeople, MdSettings, MdOutlineSubtitles, MdOutlineMail } from "react-icons/md";
import { TbCreditCardRefund } from "react-icons/tb";
import { BsCalendarMinus } from "react-icons/bs";
import { HiCalendar } from "react-icons/hi";
import { BsCart2 } from "react-icons/bs";
import { useSelector } from "react-redux";
import Sidebar from "../components/SideBar";
import CompanyDashboardPage from "./CompanyDashboardPage";
import Employee from "./Employee";
import EmployeeComp from "../components/EmployeeComp";
import Attendance from "./Attendance"
import CompanySettings from "./CompanySettings";
import AddEmployee from "./AddEmployee";
import Departments from "./Departments";
import Designation from "./Designation";
import Holidays from "./Holidays";
import LeaveRequests from "./LeaveRequests";
import Resignation from "./Resignation";
import Expenses from "./Expenses";
import Payroll from "./Payroll";
import Shifts from "./Shifts";
import Roles from "./Roles";
import CompanyPermissions from "./CompanyPermissions";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { FaPowerOff } from "react-icons/fa6";
import { IoMdExit, IoMdPricetags } from "react-icons/io";
import { FaRegIdBadge } from "react-icons/fa6";
import SendEmail from "./SendEmail";
import Members from "./Members";
import { PiUsersBold } from "react-icons/pi";
import { FiLock } from "react-icons/fi";
import PermissionDenied from "../../global/components/PermissionDenied";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import Clients from "./Clients";
import Approve from "./Approve";
import PricingPlans from "../../website/components/PricingPlans";
import CompanyBilling from "./CompanyBilling";
import Penalty from "./Penalty";
import UpgradePlan from "../../global/components/UpgradePlan";
import { useMediaQuery } from "@mui/material";

export default function CompanyDashboard() {

    const isDesktop = useMediaQuery('(min-width: 768px)');
    const location = useLocation()

    const { user_data } = useSelector(state => state.user);
    const { member_data } = useSelector(state => state.member);

    const [permissions, setPermissions] = useState([])
    const [limitedPlanPermissions, setLimitedPlanPermissions] = useState([])
    const [fullPlanPermissions, setFullPlanPermissions] = useState([])

    function getLimitedPlanPermissions(permissions) {
        const allKeys = permissions?.filter(permission => permission?.value !== 'no' && permission?.value !== 'yes') || [];
        return [...new Set(allKeys)];
    }

    function getFullPlanPermissions(permissions) {
        const allKeys = permissions
            ?.filter(permission => permission?.value === 'yes')
            .map(permission => permission.permission_name) || [];
        return [...new Set(allKeys)];
    }

    function getPermissionKeys(roles) {
        const allKeys = roles?.flatMap(role => role?.permissions?.map(permission => permission?.permission_key)) || [];
        return [...new Set(allKeys)];
    }

    useEffect(() => {
        const memberPermissions = getPermissionKeys(member_data?.roles);
        if (memberPermissions) {
            setPermissions(memberPermissions);
        }

        const limited_plan_permissions = getLimitedPlanPermissions(user_data?.billing?.plan_id?.permissions);
        if (limited_plan_permissions) {
            setLimitedPlanPermissions(limited_plan_permissions);
        }

        const full_plan_permissions = getFullPlanPermissions(user_data?.billing?.plan_id?.permissions);
        if (full_plan_permissions) {
            setFullPlanPermissions(full_plan_permissions);
        }
    }, [member_data, user_data]);


    // let current_path = window.location.pathname;

    const dashboard_items = [
        {
            title: 'Dashboard',
            link: '/company/dashboard',
            icon: <MdDashboard style={{ color: location.pathname === '/company/dashboard' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Billing',
            link: '/company/dashboard/billings',
            icon: <IoMdPricetags style={{ color: location.pathname === '/company/dashboard/billings' ? 'white' : '#c9c9c9' }} />,
        },
    ]

    const employee_items = [
        {
            title: 'Employees',
            link: '/company/dashboard/employees',
            icon: <PiUsersBold style={{ color: location.pathname === '/company/dashboard/employees' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Attendance',
            link: '/company/dashboard/attendance',
            icon: <HiCalendar style={{ color: location.pathname === '/company/dashboard/attendance' ? 'white' : '#c9c9c9' }} />,
        },
        // {
        //     title: 'Penalty',
        //     link: '/company/dashboard/penalty',
        //     icon: <LiaRupeeSignSolid style={{ color: location.pathname === '/company/dashboard/penalty' ? 'white' : '#c9c9c9' }} />,
        // },
        {
            title: 'Payroll',
            link: '/company/dashboard/payroll',
            icon: <LiaRupeeSignSolid style={{ color: location.pathname === '/company/dashboard/payroll' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Leave Requests',
            link: '/company/dashboard/leave-requests',
            icon: <FaPowerOff style={{ color: location.pathname === '/company/dashboard/leave-requests' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Resignations',
            link: '/company/dashboard/resignations',
            icon: <IoMdExit style={{ color: location.pathname === '/company/dashboard/resignations' ? 'white' : '#c9c9c9' }} />,
        },
    ]

    const company_items = [
        {
            title: 'Clients',
            link: '/company/dashboard/clients',
            icon: <PiUsersBold style={{ color: location.pathname === '/company/dashboard/clients' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Members',
            link: '/company/dashboard/members',
            icon: <PiUsersBold style={{ color: location.pathname === '/company/dashboard/members' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Roles',
            link: '/company/dashboard/roles',
            icon: <FaRegIdBadge style={{ color: location.pathname === '/company/dashboard/roles' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Permissions',
            link: '/company/dashboard/permissions',
            icon: <FiLock style={{ color: location.pathname === '/company/dashboard/permissions' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Approve Request',
            link: '/company/dashboard/approve-request',
            icon: <IoCheckmarkCircleOutline style={{ color: location.pathname === '/company/dashboard/approve-request' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Departments',
            link: '/company/dashboard/departments',
            icon: <MdPeople style={{ color: location.pathname === '/company/dashboard/departments' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Designations',
            link: '/company/dashboard/designations',
            icon: <TbCreditCardRefund style={{ color: location.pathname === '/company/dashboard/designations' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Expenses',
            link: '/company/dashboard/expenses',
            icon: <BsCart2 style={{ color: location.pathname === '/company/dashboard/expenses' ? 'white' : '#c9c9c9' }} />,
        },
        // {
        //     title: 'Sites',
        //     link: '/company/dashboard/sites',
        //     icon: <MdHomeRepairService style={{ color: location.pathname === '/company/dashboard/sites' ? 'white' : '#c9c9c9' }} />,
        // },
        {
            title: 'Emails',
            link: '/company/dashboard/email',
            icon: <MdOutlineMail style={{ color: location.pathname === '/company/dashboard/email' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Holidays',
            link: '/company/dashboard/holidays',
            icon: <BsCalendarMinus style={{ color: location.pathname === '/company/dashboard/holidays' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Shifts',
            link: '/company/dashboard/shifts',
            icon: <MdOutlineSubtitles style={{ color: location.pathname === '/company/dashboard/shifts' ? 'white' : '#c9c9c9' }} />,
        },
        {
            title: 'Penalty',
            link: '/company/dashboard/penalty',
            icon: <LiaRupeeSignSolid style={{ color: location.pathname === '/company/dashboard/penalty' ? 'white' : '#c9c9c9' }} />,
        }
    ]

    const profile_items = [
        {
            title: 'Settings',
            link: '/company/dashboard/settings',
            icon: <MdSettings style={{ color: location.pathname === '/company/dashboard/settings' ? 'white' : '#c9c9c9' }} />,
        }
    ]


    return (
        <>
            {
                isDesktop ? (
                    <>
                        <div className="layout-container">
                            <div className="sidebar-container">
                                <Sidebar dashboard_items={dashboard_items} employee_items={employee_items} company_items={company_items} profile_items={profile_items} />
                            </div>
                            <div className="content-container" style={{ paddingTop: '1%' }}>
                                <Routes>

                                    <Route element={<CompanyDashboardPage permissions={permissions} member_data={member_data} />} path="/" />


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('billings') ? (
                                                <Route element={<CompanyBilling />} path="/billings" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/billings" />
                                            )
                                        ) : (
                                            <Route element={<CompanyBilling />} path="/billings" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('employees') ? (
                                                <Route element={<Employee />} path="/employees" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/employees" />
                                            )
                                        ) : (
                                            <Route element={<Employee />} path="/employees" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('attendance') ? (
                                                <Route element={<Attendance />} path="/attendance" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/attendance" />
                                            )
                                        ) : (
                                            <Route element={<Attendance />} path="/attendance" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('employees') ? (
                                                <Route element={<EmployeeComp fullPlanPermissions={fullPlanPermissions} />} path="/employee-details" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/employee-details" />
                                            )
                                        ) : (
                                            <Route element={<EmployeeComp fullPlanPermissions={fullPlanPermissions} />} path="/employee-details" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('employees') ? (
                                                <Route element={<AddEmployee limitedPlanPermissions={limitedPlanPermissions} />} path="/addemployee" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/addemployee" />
                                            )
                                        ) : (
                                            <Route element={<AddEmployee limitedPlanPermissions={limitedPlanPermissions} />} path="/addemployee" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('penalty') ? (
                                                <Route element={<Penalty />} path="/penalty" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/penalty" />
                                            )
                                        ) : (
                                            <Route element={<Penalty />} path="/penalty" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('departments') ? (
                                                <Route element={<Departments />} path="/departments" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/departments" />
                                            )
                                        ) : (
                                            <Route element={<Departments />} path="/departments" />
                                        )
                                    }



                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('designations') ? (
                                                <Route element={<Designation />} path="/designations" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/designations" />
                                            )
                                        ) : (
                                            <Route element={<Designation />} path="/designations" />
                                        )
                                    }

                                    {
                                        fullPlanPermissions?.includes('holidays') ? (
                                            <>
                                                {
                                                    member_data?.is_member ? (
                                                        permissions?.includes('holidays') ? (
                                                            <Route element={<Holidays />} path="/holidays" />
                                                        ) : (
                                                            <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/holidays" />
                                                        )
                                                    ) : (
                                                        <Route element={<Holidays />} path="/holidays" />
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <>
                                                <Route element={<UpgradePlan title={user_data?.isPlan ? 'Upgrade Your Plan to access this Feature' : 'Buy Plan to access this Feature'} />} path="/holidays" />
                                            </>
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('leaves') ? (
                                                <Route element={<LeaveRequests />} path="/leave-requests" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/leave-requests" />
                                            )
                                        ) : (
                                            <Route element={<LeaveRequests />} path="/leave-requests" />
                                        )
                                    }



                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('resignations') ? (
                                                <Route element={<Resignation />} path="/resignations" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/resignations" />
                                            )
                                        ) : (
                                            <Route element={<Resignation />} path="/resignations" />
                                        )
                                    }



                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('expenses') ? (
                                                <Route element={<Expenses />} path="/expenses" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/expenses" />
                                            )
                                        ) : (
                                            <Route element={<Expenses />} path="/expenses" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('payroll') ? (
                                                <Route element={<Payroll />} path="/payroll" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/payroll" />
                                            )
                                        ) : (
                                            <Route element={<Payroll />} path="/payroll" />
                                        )
                                    }




                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('shifts') ? (
                                                <Route element={<Shifts />} path="/shifts" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/shifts" />
                                            )
                                        ) : (
                                            <Route element={<Shifts />} path="/shifts" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('emails') ? (
                                                <Route element={<SendEmail />} path="/email" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/email" />
                                            )
                                        ) : (
                                            <Route element={<SendEmail />} path="/email" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/roles" />
                                        ) : (
                                            <Route element={<Roles />} path="/roles" />
                                        )
                                    }

                                    {
                                        fullPlanPermissions?.includes('members') ? (
                                            <>
                                                {
                                                    member_data?.is_member ? (
                                                        <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/members" />
                                                    ) : (
                                                        <Route element={<Members />} path="/members" />
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <Route element={<UpgradePlan title={user_data?.isPlan ? 'Upgrade Your Plan to access this Feature' : 'Buy Plan to access this Feature'} />} path="/members" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('clients') ? (
                                                <Route element={<Clients />} path="/clients" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/clients" />
                                            )
                                        ) : (
                                            <Route element={<Clients />} path="/clients" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/permissions" />
                                        ) : (
                                            <Route element={<CompanyPermissions />} path="/permissions" />
                                        )
                                    }


                                    {
                                        member_data?.is_member ? (
                                            permissions?.includes('approve_request') ? (
                                                <Route element={<Approve />} path="/approve-request" />
                                            ) : (
                                                <Route element={<PermissionDenied title='You have no Permission to view this page' />} path="/approve-request" />
                                            )
                                        ) : (
                                            <Route element={<Approve />} path="/approve-request" />
                                        )
                                    }

                                    <Route element={<CompanySettings member_data={member_data} />} path="/settings" />
                                </Routes>
                            </div>
                        </div>
                    </>
                ) : (
                    <>This Web App is not made for small screen devices</>
                )
            }
        </>
    )
}