import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, TextField, Typography, Button, IconButton, InputAdornment } from '@mui/material';
import { primaryColor, secondaryColor } from '../../constant';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slices/user-slice';
import { useSelector } from 'react-redux';
import { post_data } from '../../api';
import toast from 'react-hot-toast';

const ChangeEditEployeePass = ({ data, setOpen }) => {

    const dispatch = useDispatch();
    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        new_password: "",
        current_password: "",
    })
    const [formErrors, setFormErrors] = useState({});

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });


    const validate = () => {
        const errors = {};
        let hasError = false;
        if (!formValues.current_password) {
            hasError = true;
            errors.current_password = 'Current Password is required'
        };
        if (!formValues.new_password) {
            hasError = true;
            errors.new_password = 'New Password is required';
        }
        setFormErrors(errors);

        return hasError;
    }

    const handleSubmit = async () => {
        if (!validate()) {
            setLoading(true);
            const res = await post_data(`employee/change-password`, { ...formValues, employee_id: data?._id });
            if (res?.status === true) {
                toast.success("Password Changed Succesfully");
                formValues('');
                setOpen(false);
            }
            else {
                toast.error(res?.response?.data?.message || "Something went wrong");
            }
            setLoading(false);
        }
    };
    const button = {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: secondaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        marginTop: '3%',
        boxShadow: 'none'
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        setFormErrors({ ...formErrors, [name]: false })
    };

    return (
        <>
            <Box>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 500, fontSize: 20, marginBottom: '6%' }}>
                    Change Password
                </Typography>

                {/* First row */}
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <TextField
                        fullWidth
                        label="Current Password"
                        name="current_password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={formValues.current_password}
                        onChange={handleChange}
                        error={!!formErrors.current_password}
                        helperText={formErrors.current_password}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {/* Second row */}
                <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                    <TextField
                        fullWidth
                        label="New Password"
                        name="new_password"
                        type={showPasswordTwo ? 'text' : 'password'}
                        variant="outlined"
                        value={formValues.new_password}
                        onChange={handleChange}
                        error={!!formErrors.new_password}
                        helperText={formErrors.new_password}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPasswordTwo(!showPasswordTwo)}
                                        edge="end"
                                    >
                                        {showPasswordTwo ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Button
                    variant="contained"
                    disabled={loading}
                    onClick={handleSubmit}
                    style={{ ...button, marginBottom: '3%' }}>
                    {loading ? "Loading..." : "Change Password"}
                </Button>
                {/* <a style={{ color: primaryColor }} target='_blank' href='/company/forget-password'>Forget Password</a> */}
            </Box>
        </>
    );
};

export default ChangeEditEployeePass;