import React from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCircleUser } from "react-icons/fa6";
import { Avatar } from "@mui/material";

export default function AdminSidebar({
    dashboard_items,
}) {
    const navigate = useNavigate();
    const { user_data } = useSelector(state => state.user);

    const user_dashboard_sidebar = {
        background: '#06121e',
        width: '100%',
        height: '100vh', // 100% viewport height to enable scrolling
        position: 'relative',
        borderRight: '1px solid gainsboro',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
    };

    const logo_section = {
        padding: '7.5% 5%',
        display: "flex",
        gap: 10,
        border: '1px solid #1a2835',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const sidebar_items_div = {
        padding: '2% 4%',
    };

    const admin_section = {
        position: 'absolute',
        bottom: 0, // Makes it stick to the bottom
        width: '100%', // Ensures it spans the entire width of the sidebar
        backgroundColor: '#06121e', // Matches the sidebar background
        padding: '1em',
        gap: 10,
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #1a2835',
        textAlign: 'center',
    };

    const handleListItem = (item, i) => {
        if (item?.link) {
            navigate(item.link);
            window.scrollTo(0, 0);
        }
    };

    let current_path = window.location.pathname;

    const filtered_menu_items = user_data?.isSubAdmin
        ? dashboard_items?.filter(item => item?.title !== 'Sub Admins' && item?.title !== 'Permissions')
        : dashboard_items;

    const display_sidebar_items = (arr) => {
        return (
            <List sx={{ width: '100%', maxWidth: 360 }} component="nav">
                {arr?.map((item, i) => {
                    return (
                        <div key={i}>
                            <ListItemButton
                                onClick={() => handleListItem(item, i)}
                                style={{
                                    padding: '5% 6%',
                                    borderRadius: 8,
                                    margin: '1% 0',
                                    background: item?.type !== 'dropdown' ? current_path === item.link ? '#1a2835' : 'transparent' : ''
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    gap: '8%',
                                    width: '100%',
                                    alignItems: item?.type !== 'dropdown' ? "center" : 'center'
                                }}>
                                    {item.icon}
                                    <p style={{ color: current_path === item?.link ? 'white' : '#c9c9c9', fontWeight: 400, fontSize: 14, margin: 0, padding: 0 }}>
                                        {item?.type !== 'dropdown' ? item?.title : ''}
                                    </p>
                                </div>
                            </ListItemButton>
                        </div>
                    );
                })}
            </List>
        );
    };


    return (
        <>
            <div style={user_dashboard_sidebar} className="sidebar-container">
                <div style={sidebar_items_div}>
                    {display_sidebar_items(filtered_menu_items)}
                </div>

                <div style={admin_section}>
                    {/* <Avatar alt="Travis Howard" src={<FaCircleUser />} /> */}
                    <FaCircleUser style={{ color: 'gainsboro', fontSize: 35 }} />
                    <div>
                        <h3 style={{ fontWeight: 500, fontSize: 16, color: 'white', margin: 0, textAlign: 'left' }}>{user_data?.name}</h3>
                        <p style={{ fontWeight: 400, fontSize: 10, opacity: '70%', color: 'white', margin: 0, textAlign: 'left' }}>{user_data?.email}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
