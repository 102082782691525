import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../global/components/SearchComponent";
import LeaveRequestsTable from "./tables/LeaveRequestsTable";
import { useSelector } from "react-redux";
import TableLoader from "../../global/components/TableLoader";
import Empty from "../../global/components/Empty";


export default function LeaveRequestsComp() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [value, setValue] = useState('')
    const { user_data } = useSelector(state => state.user);

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
        width: "100%",
    };


    const handleSearch = async () => {
        setLoading(true);
        if (value === '') {
            getAllLeaveRequests();
        } else {

            try {
                const response = await get_data(`leaves/search-leaves/${value}/${user_data?._id}?pageNumber=${currentPage}`);
                if (response.status) {
                    setData(response?.data?.leaves);
                    setTotalPages(response?.data?.totalPages);
                    setTotalData(response?.data?.totalLeaves);
                } else {
                    setData([]);
                }
            } catch (error) {
                setData([]);
            } finally {
                setLoading(false);
            }
        }
    };

    const getAllLeaveRequests = async () => {
        try {
            let payload = { company_id: user_data?._id }
            const data = await post_data(`leaves/get-all-leaves-by-company?pageNumber=${currentPage}`, payload);
            if (data.status) {
                setData(data?.data?.leaves);
                setTotalPages(data?.data?.totalPages);
                setTotalData(data?.data?.totalLeaves);
            }
            setLoading(false);
        } catch (error) {
        }
    }

    useEffect(() => {
        if (currentPage) {
            getAllLeaveRequests(currentPage);
        }
    }, [currentPage, user_data])


    const itemsPerPage = 15;
    const count = data?.length;

    const tableHeader = ["S No.", "Employee Name", "Start Date", "End Date", "Reason", "Status"]


    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>
                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                        <SearchComponent setValue={setValue} value={value} handleSearch={handleSearch} />
                    </div>
                </div>

                <div style={page_container}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (
                                        <LeaveRequestsTable
                                            type='employee'
                                            data={data}
                                            tableHeader={tableHeader}
                                            itemsPerPage={itemsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            count={count}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            getAllLeaveRequests={getAllLeaveRequests}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Grid>
        </>
    )
}