import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { secondaryColor } from '../../constant';
import { post_data } from '../../api';
import toast, { Toaster } from 'react-hot-toast';

export default function EditPlanModal({ open, setOpen, selectedPlan, fetchData }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        border: 'none',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    const [formData, setFormData] = useState({
        name: '',
        description: '',
        monthly_price: '',
        monthly_discount_price: '',
        yearly_price: '',
        yearly_discount_price: '',
    });

    const [errors, setErrors] = useState({
        name: false,
        description: false,
        monthly_price: false,
        yearly_price: false,
    });

    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false)

    const handleChange = (field) => (event) => {
        const value = event.target.value;
        setFormData((prev) => ({ ...prev, [field]: value }));

        if (field in errors) {
            setErrors((prev) => ({ ...prev, [field]: !value }));
        }
    };


    const handleSubmit = async () => {
        const newErrors = {
            name: !formData.name,
            description: !formData.description,
            monthly_price: formData.monthly_price === '' || isNaN(formData.monthly_price),
            yearly_price: formData.yearly_price === '' || isNaN(formData.yearly_price),
        };

        setErrors(newErrors);

        if (!Object.values(newErrors).some(Boolean)) {
            setLoading(true)
            try {
                const response = await post_data(`plans/update-plan/${selectedPlan?.id}`, formData);
                if (response.data) {
                    toast.success('Plan updated successfully');
                    setLoading(false);
                    handleClose();
                    fetchData()
                } else {
                    toast.error('Something went wrong!');
                    setLoading(false);
                    handleClose();
                    fetchData()
                }
            } catch (error) {
            }
        }
    };

    React.useEffect(() => {
        setFormData({
            name: selectedPlan?.name,
            description: selectedPlan?.description,
            monthly_price: selectedPlan?.monthly_price,
            monthly_discount_price: selectedPlan?.monthly_discount_price,
            yearly_price: selectedPlan?.yearly_price,
            yearly_discount_price: selectedPlan?.yearly_discount_price,
        })
    }, [selectedPlan])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <TextField
                        label="Name"
                        fullWidth
                        margin="normal"
                        value={formData.name}
                        onChange={handleChange('name')}
                        error={errors.name}
                        helperText={errors.name && "Name is required"}
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        margin="normal"
                        value={formData.description}
                        onChange={handleChange('description')}
                        error={errors.description}
                        helperText={errors.description && "Description is required"}
                    />
                    <TextField
                        label="Monthly Price"
                        fullWidth
                        margin="normal"
                        value={formData.monthly_price}
                        onChange={handleChange('monthly_price')}
                        error={errors.monthly_price}
                        helperText={errors.monthly_price && "Monthly price is required and must be a number"}
                    />
                    <TextField
                        label="Monthly Discount Price"
                        fullWidth
                        margin="normal"
                        value={formData.monthly_discount_price}
                        onChange={handleChange('monthly_discount_price')}
                    />
                    <TextField
                        label="Yearly Price"
                        fullWidth
                        margin="normal"
                        value={formData.yearly_price}
                        onChange={handleChange('yearly_price')}
                        error={errors.yearly_price}
                        helperText={errors.yearly_price && "Yearly price is required and must be a number"}
                    />
                    <TextField
                        label="Yearly Discount Price"
                        fullWidth
                        margin="normal"
                        value={formData.yearly_discount_price}
                        onChange={handleChange('yearly_discount_price')}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '2%', background: secondaryColor, boxShadow: 'none' }}
                        onClick={handleSubmit}
                    >
                        {loading ? 'Update Plan...' : 'Update Plan'}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}