import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { post_data } from '../../api';
import OtpComponent from './OtpComponent';
import { secondaryColor } from '../../constant';
import toast from 'react-hot-toast';

const backdropStyle = {
    backgroundColor: '#000000c5',
};

const ChangeEmailModal = ({ open, setOpen, type }) => {

    const { user_data } = useSelector(state => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [otpForm, setOtpForm] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const handleClose = () => { setOpen(false); setEmailError(false) };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: secondaryColor,
            borderRadius: '8px',
            cursor: 'pointer',
            border: '2px solid ' + secondaryColor,
            marginTop: '3%',
            width: '30%',
            boxShadow: 'none'
        }
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSendCode = async () => {
        if (!validateEmail(newEmail)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        setEmailError('');
        setLoading(true);
        if (type === 'company') {
            try {
                const data = await post_data('company/send-otp-for-change-email', { new_email: newEmail, email: user_data?.email });
                if (data.status === true) {
                    setOtpForm(true);
                } else {
                    toast.error(data?.response?.data?.message || "Something went wrong");
                }
            } catch (error) {
                console.log("error while sending code ", error);
            }
        } else {
            try {
                const data = await post_data('super-admin/send-otp-for-change-email', { new_email: newEmail, email: user_data?.email });
                if (data.status === true) {
                    setOtpForm(true);
                } else {
                    toast.error(data?.response?.data?.message || "Something went wrong");
                }
            } catch (error) {
                console.log("error while sending code ", error);
            }
        }
        setLoading(false);
    };

    return (
        <Modal
            BackdropProps={{
                style: backdropStyle,
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
        >
            <Box sx={styles.modalBox}>
                {
                    otpForm ?
                        <>
                            <OtpComponent
                                email={newEmail}
                                newEmail={newEmail}
                                type={type === 'company' ? 'change-email' : 'change-admin-email'}
                                setOpen={setOpen}
                                handleResend={handleSendCode}
                            />
                        </>
                        :
                        <>
                            <Typography id="logout-modal-title" variant="h6" sx={styles.heading}>
                                Change Email
                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', flexDirection: 'column' }}>
                                <TextField
                                    fullWidth
                                    sx={{ width: '70%', margin: '3% auto 0', }}
                                    label="New Email Address"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    value={newEmail}
                                    onChange={(e) => { setNewEmail(e?.target?.value); setEmailError(false) }}
                                    error={Boolean(emailError)}
                                    helperText={emailError}
                                />
                            </Box>

                            <Box sx={styles.buttons}>
                                <Button variant="outlined" onClick={handleClose} style={{ ...styles.button, color: secondaryColor, background: 'white' }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleSendCode} style={styles.button}>
                                    {loading ? 'Sending...' : 'Send code'}
                                </Button>
                            </Box>
                        </>
                }
            </Box>
        </Modal>
    );
};

export default ChangeEmailModal;
