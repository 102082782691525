import React from 'react';
import { Modal, Box, Typography, Table, TableHead, TableBody } from '@mui/material';
import { formatDate, formatPrice } from '../../../constant';

const PayrollDetailsModal = ({ open, setOpen, data }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        modalBox: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            border: 'none',
            bgcolor: 'white',
            borderRadius: '10px',
            boxShadow: 12,
            textAlign: 'center',
        },
        heading: {
            fontWeight: 600,
            fontSize: 27,
            marginBottom: '10px',
        },
        row: {
            display: 'flex',
            width: '100%',
            gap: '10px',
            margin: '4% 0',
            fontWeight: 400,
            fontSize: 16,

        },
        dataLabel: {
            fontWeight: 400,
            fontSize: 16,
            width: '50%',
            textAlign: 'left'
        },
        dataValue: {
            fontWeight: 400,
            fontSize: 16,
            marginLeft: 'auto',
            width: '50%',
            textAlign: 'right',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'end'
        },
        icon: {
            width: 22,
        },
        headingTypography: {
            fontWeight: 500,
            fontSize: 22,
            marginBottom: '10px',
            textAlign: 'left',
            p: 3,
            borderBottom: '1px solid gainsboro'
        }
    };


    const DataRow = ({ label, value }) => (
        <div style={styles.row}>
            <span style={{ ...styles.dataLabel, fontWeight: label === 'Net Salary' ? 600 : '' }}>{label}</span>
            <span style={{ ...styles.dataValue, fontWeight: label === 'Net Salary' ? 600 : '' }}>{value}</span>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={styles.modalBox}>
                <Typography id="logout-modal-title" variant="h6" sx={styles.headingTypography}>
                    Sub Admin Details
                </Typography>
                <Box sx={{ padding: '2% 6% 6% 6%' }}>
                    <DataRow label="Employee Name" value={data?.employee_id?.first_name + ' ' + data?.employee_id?.last_name || '-'} />
                    <DataRow label="Base Salary" value={formatPrice(data?.salary?.base_salary) || '-'} />
                    <DataRow label="House Rent Allowance" value={'+' + formatPrice(data?.salary?.house_rent_allowance) || '-'} />
                    <DataRow label="Dearness Allowance" value={'+' + formatPrice(data?.salary?.dearness_allowance) || '-'} />
                    <DataRow label="Conveyance Allowance" value={'+' + formatPrice(data?.salary?.conveyance_allowance) || '-'} />
                    <DataRow label="Leave Travel Allowance" value={'+' + formatPrice(data?.salary?.leave_travel_allowance) || '-'} />
                    <DataRow label="Medical Allowance" value={'+' + formatPrice(data?.salary?.medical_allowance) || '-'} />
                    <DataRow label="Bonus" value={'+' + formatPrice(data?.salary?.bonus) || '-'} />
                    <DataRow label="Provident Fund" value={'-' + formatPrice(data?.salary?.provident_fund) || '-'} />
                    <DataRow label="Professional Tax" value={'-' + formatPrice(data?.salary?.professional_tax) || '-'} />
                    <DataRow label="Net Salary" value={formatPrice(data?.salary?.net_salary) || '-'} />
                </Box>
            </Box>
        </Modal>
    );
};

export default PayrollDetailsModal;
