import { Box, Button, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { formatDate, formatPrice, primaryColor, secondaryColor } from "../../../constant";

const SalaryInvoiceModal = ({ open, setOpen, currentInvoice, employeeData }) => {

    const contentRef = useRef(null);
    const { user_data } = useSelector(state => state.user);
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const styles = {
        invoice: {
            padding: "4%",
            height: 470,
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #000",
            paddingBottom: "10px",
            marginBottom: "5%",
        },
        brand: {
            textAlign: "left",
        },
        info: {
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        infoSection: {
            textAlign: "left",
        },
        table: {
            width: "100%",
            borderCollapse: "collapse",
        },
        th: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        td: {
            border: "1px solid #ddd",
            padding: "8px",
        },
        footer: {
            marginTop: "20px",
            borderTop: "2px solid #000",
            marginTop: "5%",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "space-between",
        },
        terms: {
            textAlign: "left",
        },
        payment: {
            textAlign: "left",
        },
        summary: {
            marginTop: "20px",
            textAlign: "right",
        },
        text: {
            fontSize: 14,
        },
    };


    const handleDownloadPdf = async () => {
        if (contentRef.current) {
            const canvas = await html2canvas(contentRef.current);
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save("invoice.pdf");
        }
    };

    const styleModal = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: '85%',
        height: isDesktop ? '85%' : '80%',
        borderRadius: 2,
        overflowY: 'auto',
        bgcolor: "white",
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        border: "none",
        boxShadow: 24,
        p: 2,
    };

    const button = {
        padding: '5px',
        fontSize: 13,
        color: '#fff',
        backgroundColor: secondaryColor,
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: isDesktop ? '15%' : '40%',
        marginTop: '20px',
        boxShadow: 'none',
        marginLeft: 'auto'
    }

    const details = [
        {
            invoice_to: employeeData?.first_name + ' ' + employeeData?.last_name,
            month: currentInvoice?.month,
            base_salary: currentInvoice?.salary?.base_salary,
            house_rent_allowance: currentInvoice?.salary?.house_rent_allowance,
            dearness_allowance: currentInvoice?.salary?.dearness_allowance,
            conveyance_allowance: currentInvoice?.salary?.conveyance_allowance,
            leave_travel_allowance: currentInvoice?.salary?.leave_travel_allowance,
            medical_allowance: currentInvoice?.salary?.medical_allowance,
            bonus: currentInvoice?.salary?.bonus,
            provident_fund: currentInvoice?.salary?.provident_fund,
            professional_tax: currentInvoice?.salary?.professional_tax,
            net_salary: currentInvoice?.salary?.net_salary,
            invoice_date: currentInvoice?.created_at,
            invoice_number: currentInvoice?.invoice_number
        },
    ];


    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box sx={styleModal}>
                    <div ref={contentRef} id="invoice" style={styles.invoice}>
                        <div style={styles.header}>
                            <div style={styles.brand}>
                                <h2 style={{ fontWeight: 600, margin: 0, }}>Nivishka PMS</h2>
                                <p style={{ fontSize: 11, opacity: "70%" }}>
                                    Staff & Payroll Management
                                </p>
                            </div>
                            <h2 style={{ fontWeight: 600, margin: 0 }}>INVOICE</h2>
                        </div>
                        <div style={{ ...styles.info, ...styles.text }}>
                            <div style={styles.infoSection}>
                                <p>
                                    <strong>Invoice to:</strong>
                                </p>
                                <p>{details[0]?.invoice_to}</p>
                                {/* <p>{details[0]?.invoice_to_address}</p> */}
                            </div>
                            <div style={{ ...styles.infoSection, textAlign: 'right' }}>
                                <p>
                                    <strong>Invoice no.: </strong> {details?.[0]?.invoice_number}
                                </p>
                                <p>
                                    <strong>Date: </strong> {formatDate(details?.[0]?.invoice_date)}
                                </p>
                            </div>
                        </div>
                        <table style={{ ...styles.table, ...styles.text, marginTop: "3%" }}>
                            <thead style={{ textAlign: "left" }}>
                                <tr>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>SL.</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Month</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Base Salary</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>House Rent Allowance</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Dearness Allowance</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Conveyance Allowance</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Leave Travel Allowance</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Medical Allowance</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Bonus</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Provident Fund</th>
                                    <th style={{ borderRight: '1px solid gainsboro' }}>Professional Tax</th>
                                    <th>Net Salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>1.</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{details?.[0]?.month}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.base_salary)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.house_rent_allowance)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.dearness_allowance)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.conveyance_allowance)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.leave_travel_allowance)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.medical_allowance)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.bonus)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.provident_fund)}</td>
                                    <td style={{ borderRight: '1px solid gainsboro' }}>{formatPrice(details?.[0]?.professional_tax)}</td>
                                    <td>{formatPrice(details?.[0]?.net_salary)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ ...styles.footer, ...styles.text }}>
                            <div style={styles.terms}>
                                <p>Thanks for doing business with us.</p>
                                {/* <p>Terms & Conditions</p>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                                    dignissim pretium consectetur.
                                </p> */}
                            </div>
                            <div style={{ ...styles.summary, ...styles.text }}>
                                <p style={{ margin: 0, padding: 0 }}>
                                    <strong>Total Paid: {formatPrice(details[0]?.net_salary)}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Button variant="contained" onClick={() => { handleDownloadPdf() }} style={button}>Download</Button>
                </Box>
            </Modal>
        </>
    );
};

export default SalaryInvoiceModal;