import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CiFilter } from "react-icons/ci";
import { useState, useEffect } from 'react';
import { Button, FormControl, Autocomplete, TextField } from '@mui/material';
import { primaryColor, secondaryColor } from '../../constant';
import { State, City, Country } from 'country-state-city';
import { get_data, post_data } from '../../api';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

export default function CompanyFilterModal({ employees, setEmployees, department, setDepartment, setRowsData, setTotalData, setTotalPages }) {

    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState(null)
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);

    const { user_data } = useSelector(state => state.user);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setState(null)
        setCity(null)

    }

    const handleApply = async () => {
        let payload = {
            country: country?.isoCode,
            state: state?.isoCode,
            city: city?.name
        }

        let result = await post_data('company/filter-modal', payload)
        if (result?.status) {
            setRowsData(result?.data?.company);
            setTotalData(result?.data?.totalCompanies);
            setTotalPages(result?.data?.totalPages);
        }
        handleClose();
    }

    return (
        <div>
            <div onClick={handleOpen}>
                <div><CiFilter fontSize={25} /></div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>

                    <Box sx={{ minWidth: 120, padding: '20px 0px 20px 0px' }}>
                        <Autocomplete
                            options={Country.getAllCountries()}
                            getOptionLabel={(option) => option.name}
                            value={country}
                            onChange={(e, value) => {
                                setCountry(value);
                                setState(null)
                                setCity(null);
                            }}
                            renderInput={(params) => <TextField {...params} label="Country" />}
                        />
                    </Box>

                    <Box sx={{ minWidth: 120, padding: '20px 0px 20px 0px' }}>
                        <Autocomplete
                            options={State.getStatesOfCountry(country?.isoCode)}
                            getOptionLabel={(option) => option.name}
                            value={state}
                            onChange={(e, value) => {
                                setState(value);
                                setCity(null);
                            }}
                            renderInput={(params) => <TextField {...params} label="State" />}
                        />
                    </Box>

                    <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                        <Autocomplete
                            options={state ? City.getCitiesOfState(state.countryCode, state.isoCode) : []}
                            getOptionLabel={(option) => option.name}
                            value={city}
                            onChange={(e, value) => setCity(value)}
                            renderInput={(params) => <TextField {...params} label="City" />}
                        />
                    </Box>

                    {/* <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                        <Autocomplete
                            options={designations.filter(designation => designation?.name)} // Filter out invalid options
                            getOptionLabel={(option) => option.name || ""}
                            value={employees}
                            onChange={(e, value) => setEmployees(value)}
                            renderInput={(params) => <TextField {...params} label="Designation" />}
                        />
                    </Box>

                    <Box sx={{ minWidth: 120, padding: '5px 0px 20px 0px' }}>
                        <Autocomplete
                            options={departments}
                            getOptionLabel={(option) => option || ""}
                            value={department}
                            onChange={(e, value) => setDepartment(value)}
                            renderInput={(params) => <TextField {...params} label="Department" />}
                        />
                    </Box> */}

                    <Box sx={{ padding: '5px 0px 20px 0px' }} >
                        <Button style={{ background: `${secondaryColor}`, color: '#fff' }} fullWidth onClick={handleApply} >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
