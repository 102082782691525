import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
    IconButton,
    Modal,
    Backdrop,
    Fade,
    Pagination,
} from "@mui/material";
import {
    Delete as DeleteIcon,
    Download as DownloadIcon,
    InsertDriveFile as FileIcon,
    CloudUpload as CloudUploadIcon
} from "@mui/icons-material";
import { formatDate, secondaryColor } from "../../constant";
import Empty from "../../global/components/Empty";
import { get_data, post_data, serverURL } from "../../api";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import TableLoader from "../../global/components/TableLoader";
import SearchComponent from "../../global/components/SearchComponent";
import axios from "axios";

const EmployeeFilesComp = ({ employeeData, isAdmin }) => {

    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState('');
    const [fileLoad, setFileLoad] = useState(false);
    const { user_data } = useSelector(state => state.user);


    const fetchAllFiles = async () => {
        let result = await get_data(`employee/get-all-files/${employeeData?._id}?pageNumber=${currentPage}`)

        if (result?.status) {
            setFiles(result?.data?.files)
            setTotalPages(result?.data?.totalPages);
            setTotalData(result?.data?.totalFiles);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchAllFiles()
    }, [employeeData, currentPage, refresh])

    const handleDelete = async (fileId) => {
        try {
            const result = await Swal.fire({
                title: "Do you want to delete this file?",
                showDenyButton: true,
                confirmButtonText: "Delete",
                denyButtonText: "Cancel",
            });
            if (result.isConfirmed) {
                const response = await post_data("employee/delete-employee-file", {
                    employee_id: employeeData._id,
                    fileId: fileId
                });
                if (response.status) {
                    setFiles(files.filter(file => file._id !== fileId));
                    Swal.fire("Deleted", "", "success");
                } else {
                    Swal.fire("Failed to delete", "", "error");
                }
                setRefresh(!refresh)
            }
        } catch (error) {
            toast.error("Error deleting file");
        }
    };

    const handleUploadClick = () => {
        setOpen(true);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFileSubmit = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append("files", selectedFile);
        try {
            setFileLoad(true);
            const result = await post_data(`employee/upload-files/${employeeData._id}`, formData);
            if (result.status) {
                setFiles([result.data, ...files]);
                setOpen(false);
                setSelectedFile(null);
                toast.success("File uploaded successfully!");
            } else {
                toast.error("Upload failed");
            }
            setFileLoad(false);
            setRefresh(!refresh)
        } catch (error) {
            toast.error("Something went wrong!");
        } finally {
            setSelectedFile(null);
        }
    };


    // const handleDownload = async (fileId) => {

    //     const file = files?.find((file) => file._id === fileId);
    //     const fileUrl = `${serverURL}/uploads/files/${file?.file}`;

    //     if (file.name.endsWith(".pdf")) {
    //         const pdf = new jsPDF("p", "mm", "a4");
    //         pdf.text(`File Name: ${file.name}`, 10, 10);
    //         pdf.text(`${fileUrl}`, 10, 20);
    //         pdf.save(`${file.name}.pdf`);
    //         toast.success("PDF downloaded successfully!");
    //     } else if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
    //         try {
    //             const worksheet = XLSX.utils.json_to_sheet(
    //                 [employeeData]?.map((item) => ({
    //                     "File Name": file.name,
    //                     "Date": formatDate(file.date),
    //                     "Employee Name": item?.name || "-",
    //                     "Employee ID": item?.employeeId || "-",
    //                 }))
    //             );
    //             const workbook = XLSX.utils.book_new();
    //             XLSX.utils.book_append_sheet(workbook, worksheet, "Files");
    //             XLSX.writeFile(workbook, `${file.name}.xlsx`);
    //             toast.success("Excel file downloaded successfully!");
    //         } catch (error) {
    //             toast.error("Error downloading Excel file");
    //         }
    //     } else if (file.name.endsWith(".png")) {
    //         try {
    //             const response = await axios.get(fileUrl, {
    //                 responseType: "blob",
    //                 withCredentials: true,
    //             });

    //             const url = window.URL.createObjectURL(response.data);
    //             const link = document.createElement("a");
    //             link.href = url;
    //             link.download = file.name;
    //             document.body.appendChild(link);
    //             link.click();
    //             link.remove();
    //             toast.success("PNG file downloaded successfully!");
    //         } catch (error) {
    //             toast.error("Error downloading PNG file");
    //         }
    //     } else {
    //         toast.error("Unsupported file format");
    //     }
    // };


    const handleDownload = async (file) => {
        // const response = await get_data(`employee/download-file/${file?.file}`)
        // if (response?.status) {
        //     console.log('response', response);
        //     console.log('response?.data', response?.data);

        // }
        const fileUrl = `${serverURL}/uploads/files/${file?.file}`
        const response = await fetch(fileUrl)
        const blob = await response.blob()
        const link = document.createElement('a')
        const blobUrl = window.URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = file?.file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(blobUrl);
    }


    const startEntry = (currentPage - 1) * 15 + 1;
    const endEntry = Math.min(currentPage * 15, totalData);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSearch = async () => {
        setLoading(true);
        if (value === '') {
            fetchAllFiles();
            return
        }

        let result = await get_data(`employee/search-file/${value}/${employeeData?._id}?pageNumber=${currentPage}`)
        if (result?.status) {
            setFiles(result?.data?.files)
            setTotalPages(result?.data?.totalPages);
            setTotalData(result?.data?.totalFiles);
        }
        setLoading(false);
    }


    return (
        <Box>
            <Box sx={{ display: "flex", alignItems: 'center', mb: 2 }}>
                <Typography variant="h5">Files</Typography>

                {
                    !isAdmin && (
                        <Button
                            variant="contained"
                            sx={{ boxShadow: 'none', background: secondaryColor, ml: 'auto' }}
                            onClick={handleUploadClick}
                        >
                            Upload File
                        </Button>
                    )
                }
            </Box>

            <Box sx={{ display: "flex", alignItems: 'center', mb: 2 }}>
                <SearchComponent value={value} setValue={setValue} handleSearch={handleSearch} />
            </Box>

            <Grid container spacing={3}>
                {
                    loading ? (
                        <div style={{ width: '100%' }}>
                            <TableLoader />
                        </div>
                    ) : (
                        <>
                            {files.length === 0 ? (
                                <div style={{ width: '100%' }}>
                                    <Empty />
                                </div>
                            ) : (
                                files.map((file) => (
                                    <Grid item xs={12} sm={6} md={3} key={file?._id}>
                                        <Card sx={{ backgroundColor: "#f9f9f9", borderRadius: 2, boxShadow: "none" }}>
                                            <CardContent>
                                                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                                    <FileIcon fontSize="large" sx={{ mr: 2 }} />
                                                    <Typography variant="body1" noWrap>{file?.name}</Typography>
                                                </Box>
                                                <Typography variant="body2" color="textSecondary">
                                                    Uploaded on: {formatDate(file?.date)}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <a target="_blank" href={`https://api-pms.nivishka.com/uploads/files/${file?.file}`} download>
                                                    <DownloadIcon />
                                                </a>
                                                {/* <IconButton color="primary" onClick={() => handleDownload(file)}>
                                                    <DownloadIcon />
                                                </IconButton> */}

                                                {
                                                    !isAdmin && (
                                                        <IconButton color="secondary" onClick={() => handleDelete(file._id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))
                            )}

                        </>
                    )
                }

            </Grid>


            {!loading && files?.length > 0 && (
                <div
                    style={{
                        borderTop: "1px solid gainsboro",
                        padding: "2%",
                        display: "flex",
                        marginTop: '3%',
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 15,
                            color: "black",
                        }}
                    >{`Showing ${startEntry} to ${endEntry} of ${totalData} Records`}</Typography>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}

            {/* Upload Modal */}
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute', top: '50%', left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400, bgcolor: 'background.paper',
                        border: 'none', boxShadow: 24, borderRadius: 2, p: 4,
                    }}>
                        <Typography variant="h6">Upload File</Typography>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            sx={{ mt: 2, mb: 2, width: '100%' }}
                        >
                            Choose File
                            <input type="file" hidden onChange={handleFileChange} />
                        </Button>
                        {selectedFile && (
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Selected File: {selectedFile.name}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            sx={{ mt: 2, background: secondaryColor, boxShadow: 'none' }}
                            onClick={handleFileSubmit}
                            disabled={fileLoad}
                        >
                            {fileLoad ? "loading" : "Submit"}
                        </Button>
                    </Box>
                </Fade>
            </Modal>

        </Box>
    );
};

export default EmployeeFilesComp;
