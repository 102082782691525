import { Button, Grid } from "@mui/material";
import DashboardComp from "../components/DashboardComp";
import TodayAttendance from "../components/TodayAttendance";
import PermissionDenied from "../../global/components/PermissionDenied";

export default function CompanyDashboardPage({ member_data, permissions }) {

    const page = {
        width: '100%',
        marginBottom: '2%'
    };

    const page_container = {
    };

    return (
        <>
            <Grid container spacing={0} style={{ ...page, display: "flex", alignItems: 'center', gap: '1%' }}>

                <div style={page_container}>
                    <DashboardComp />

                    <h3 style={{
                        fontWeight: 500,
                        marginTop: '3%'
                    }}>Today's Attendance</h3>

                    {
                        member_data?.is_member ? (
                            permissions?.includes('attendance') ? (
                                <TodayAttendance />
                            ) : (
                                <><PermissionDenied title={'You have no Permission to view this section'} /></>
                            )
                        ) : (
                            <TodayAttendance />
                        )
                    }


                </div>

            </Grid>
        </>
    )
}