import { Badge, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, useMediaQuery, Drawer, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { primaryColor, secondaryColor } from "../../constant"
import { IoIosLogIn } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import LoginDrawer from "./LoginDrawer";

export default function Header() {

    const { user_data, isLoggedIn } = useSelector(state => state.user);
    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.down('md'));
    const matches1 = useMediaQuery(theme.breakpoints.down(1025));
    const matches2 = useMediaQuery(theme.breakpoints.down(500));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const openLogin = Boolean(anchorEl);
    const handleClickLogin = (event) => {
        navigate('/login')
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const styles = {
        container: {
            width: '100%',
            boxSizing: 'border-box',
            position: 'relative',
            boxShadow: '0 4px 10px rgba(0, 0, 0, .05)',
            zIndex: 99,
            background: 'white'
        },
        headerBar: {
            background: 'black',
            color: 'white',
            padding: '10px',
            fontSize: matches_md ? 12.5 : 14,
            textAlign: 'center'
        },
        navContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: matches1 ? '0 20px' : '0 80px',
            height: 70,
            position: "sticky",
            zIndex: 1000,
        },
        logo: {
            height: 'auto'
        },
        menuIcon: {
            display: matches_md ? 'block' : 'none',
            fontSize: '36px',
            color: 'black',
            cursor: 'pointer',
            marginLeft: 'auto',
        },
        navItems: {
            display: matches_md ? 'none' : 'flex',
            gap: 50,
            color: 'black',
            fontSize: 16,
            cursor: 'pointer'
        },
        buttonContainer: {
            display: matches_md ? 'none' : 'flex',
            gap: '10px'
        },
        signInButton: {
            borderColor: primaryColor,
            color: primaryColor,
            textTransform: 'capitalize',
            borderRadius: 0,
            fontWeight: 400,
            boxShadow: 'none',
            '&:hover': {
                borderColor: primaryColor,
                boxShadow: 'none',
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                borderColor: primaryColor,
            }
        },
        signOutButton: {
            borderColor: primaryColor,
            color: primaryColor,
            border: `2px solid ${primaryColor}`,
            textTransform: 'capitalize',
            borderRadius: '10px',
            padding: '10px',
            textAlign: 'center',
            fontWeight: '500',
            cursor: 'pointer'
        },
        getStartedButton: {
            background: primaryColor,
            color: 'white',
            fontWeight: 400,
            padding: '10px 25px',
            textTransform: 'capitalize',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: secondaryColor,
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                boxShadow: 'none',
                backgroundColor: primaryColor,
            }
        },
        drawerContent: {
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            gap: '10px',
            backgroundColor: 'white',
            color: 'black',
        },
        drawerNavItem: {
            color: 'black',
            fontSize: '18px',
            textDecoration: 'none',
            cursor: 'pointer',
            padding: '10px 0',
            fontWeight: '400',
        },
        drawerButtonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '20px',
        },
        MyAccountButton: {
            padding: '10px 12px',
            borderRadius: '8px',
            backgroundColor: primaryColor,
            color: 'white',
            fontSize: 15,
            textTransform: 'capitalize',
            cursor: 'pointer',
            border: 'none',
        }
    };

    const handleClick = (link) => {
        navigate(link)
        window.scrollTo(0, 0)
    }

    const handleNavigateToMyAccount = () => {
        navigate("/company/dashboard")
    }

    return (<div style={styles.container}>
        <div style={styles.navContainer}>
            <img src="/images/nivishka-pms-logo.png" onClick={() => handleClick('/')}
                style={{ width: 180, cursor: 'pointer' }}
            />

            <div style={styles.navItems}>
                <span style={{ color: 'black' }} onClick={() => handleClick('/')} className="nav-item" >Home</span>
                <span style={{ color: 'black' }} onClick={() => handleClick('/pricing')} className="nav-item"  >Pricing</span>
                <span style={{ color: 'black' }} onClick={() => handleClick('/contact')} className="nav-item"  >Contact</span>
                {/* <span style={{ color: 'black' }} onClick={() => handleClick('/about')} className="nav-item"  >About</span> */}
            </div>


            {isLoggedIn && user_data?.is_company ?
                <div style={styles.buttonContainer} >
                    <Button variant="outlined" sx={styles.signInButton} onClick={handleNavigateToMyAccount}>My Account</Button>
                </div>
                :
                <div style={styles.buttonContainer} >
                    <Button variant="contained"
                        aria-controls={openLogin ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLogin ? 'true' : undefined}
                        onClick={handleClickLogin}
                        sx={styles.getStartedButton}
                        startIcon={<IoIosLogIn />}>  Login </Button>

                </div>
            }

            <IconButton style={styles.menuIcon} onClick={handleDrawerToggle}>
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                    style: {
                        backgroundColor: 'white',
                        color: 'black',
                        width: 300,
                    },
                }}
            >
                <div style={styles.drawerContent}>
                    <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/')} className="nav-item" >Home</span>
                    <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/pricing')} className="nav-item">Pricing</span>
                    <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/contact')} className="nav-item">Contact</span>
                    {/* <span style={{ ...styles.drawerNavItem, color: 'black' }} onClick={() => handleClick('/about')} className="nav-item">About</span> */}

                    {isLoggedIn && user_data?.is_company ?
                        <div style={styles.buttonContainer} >
                            <Button variant="outlined" sx={styles.signInButton} onClick={() => navigate('/company/dashboard')}>My Account</Button>
                        </div>
                        :
                        <div style={styles.buttonContainer} >
                            <Button variant="outlined" sx={styles.signInButton} onClick={() => navigate('/login')}>Login</Button>
                        </div>
                    }
                </div>
            </Drawer>
            <LoginDrawer open={open} setOpen={setOpen} />
        </div>
    </div>
    )
};
