import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { secondaryColor } from '../../../constant';
import { post_data } from '../../../api';
import toast, { Toaster } from 'react-hot-toast';

export default function BankModal({
    open, setOpen,
    employeeData, fetchEmployeeDetails
}) {
    const [formValues, setFormValues] = React.useState({
        accountHolderName: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        branchName: ''
    });

    const [formErrors, setFormErrors] = React.useState({
        accountHolderName: false,
        bankName: false,
        accountNumber: false,
        ifscCode: false,
        branchName: false
    });

    React.useEffect(() => {
        setFormValues({
            accountHolderName: employeeData?.bank_account?.accountHolderName,
            bankName: employeeData?.bank_account?.bankName,
            accountNumber: employeeData?.bank_account?.accountNumber,
            ifscCode: employeeData?.bank_account?.ifscCode,
            branchName: employeeData?.bank_account?.branchName
        })
    }, [employeeData, open])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        borderRadius: 2,
        border: 'white',
        boxShadow: 24,
        p: 4,
    };

    const handleClose = () => {
        setOpen(false)
        setFormValues({
            accountHolderName: '',
            bankName: '',
            accountNumber: '',
            ifscCode: '',
            branchName: ''
        })
    }

    const handleChange = (field) => (event) => {
        const { value } = event.target;
        setFormValues({ ...formValues, [field]: value });
        if (value.trim() === '') {
            setFormErrors({ ...formErrors, [field]: true });
        } else {
            setFormErrors({ ...formErrors, [field]: false });
        }
    };

    const handleSubmit = async () => {
        const errors = {
            accountHolderName: formValues.accountHolderName.trim() === '',
            bankName: formValues.bankName.trim() === '',
            accountNumber: formValues.accountNumber.trim() === '',
            ifscCode: formValues.ifscCode.trim() === '',
            branchName: formValues.branchName.trim() === ''
        };
        setFormErrors(errors);
        const hasErrors = Object.values(errors).some((error) => error);
        if (!hasErrors) {
            let payload = {
                bank_account: {
                    accountHolderName: formValues?.accountHolderName,
                    bankName: formValues?.bankName,
                    ifscCode: formValues?.ifscCode,
                    accountNumber: formValues?.accountNumber,
                    branchName: formValues?.branchName,
                }
            }
            let response = await post_data(`employee/update-employee/${employeeData?._id}`, payload)
            if (response?.status === true) {
                fetchEmployeeDetails()
                toast.success('Bank details updated successfully!');
                handleClose()
            }
            else {
                toast.error('Something went wrong!');
                handleClose()
            }
        }
    };

    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Bank Details
                    </Typography>
                    <Box component="form" sx={{ mt: 2 }}>

                        <TextField
                            fullWidth
                            margin="normal"
                            label="Account Holder Name"
                            value={formValues.accountHolderName}
                            onChange={handleChange('accountHolderName')}
                            error={formErrors.accountHolderName}
                            helperText={formErrors.accountHolderName ? 'Account holder name is required' : ''}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Bank Name"
                            value={formValues.bankName}
                            onChange={handleChange('bankName')}
                            error={formErrors.bankName}
                            helperText={formErrors.bankName ? 'Bank name is required' : ''}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Account Number"
                            value={formValues.accountNumber}
                            onChange={handleChange('accountNumber')}
                            error={formErrors.accountNumber}
                            helperText={formErrors.accountNumber ? 'Account number is required' : ''}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="IFSC Code"
                            value={formValues.ifscCode}
                            onChange={handleChange('ifscCode')}
                            error={formErrors.ifscCode}
                            helperText={formErrors.ifscCode ? 'IFSC code is required' : ''}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Branch Name"
                            value={formValues.branchName}
                            onChange={handleChange('branchName')}
                            error={formErrors.branchName}
                            helperText={formErrors.branchName ? 'Branch name is required' : ''}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                boxShadow: 'none',
                                background: secondaryColor,
                                padding: '10px 0'
                            }}
                            onClick={handleSubmit}
                        >
                            Save Details
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
