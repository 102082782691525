import Footer from "../../website/components/Footer";
import Header from "../../website/components/Header";
import SignupForm from "../components/SignupForm";

export default function CompanySignup() {
    return (
        <div>
            <Header />
            <SignupForm />
            <Footer />
        </div>
    )
} 