import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import { primaryColor, formatDate, secondaryColor, formatPrice } from "../../constant";
import { Grid } from "@mui/material";

export default function CompanySubscription({ plan, billing }) {

    const [planType, setPlanType] = useState("");

    useEffect(() => {
        setPlanType(billing?.plan_tenure);
    }, [billing]);

    if(!plan){
        return null
    }

    const current_plan_div = {
        borderRadius: 10,
        border: '1px solid #F1F1F2',
        boxShadow: '0px 3px 4px 0 #00000008',
        color: "black",
        background: "white",
        height: "100%",
    };

    const plan_items_div = {
        display: "flex",
        gap: "4%",
        justifyContent: "left",
        padding: "4%",
    };

    const plan_item = {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        width: "100%",
    };

    const payment_details_div = {
        display: "flex",
        alignItems: 'center',
        gap: "3%",
        padding: "4%",
        border: "1px solid #F1F1F2",
        borderRadius: 5,
    };

    const planDiv = () => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "2px solid #F1F1F2",
                        padding: "2%",
                    }}
                >
                    <h2
                        className="global-h2"
                        style={{
                            color: "black",
                            fontSize: 18,
                            margin: 0,
                        }}
                    >
                        Plan Summary
                    </h2>
                    <div
                        style={{
                            background: secondaryColor,
                            color: "white",
                            boxShadow: "none",
                            marginLeft: "auto",
                            fontSize: 13,
                            padding: "2% 5%",
                            border: "none",
                            borderRadius: 5,
                            fontWeight: 600,
                        }}
                    >
                        Current Plan
                    </div>
                </div>
            </>
        );
    };


    // let lastinvoice = invoices?.length - 1;
    // const expirationDate = new Date(invoices?.[lastinvoice]?.expiry_date); // (YYYY-MM-DD)

    const expirationDate = new Date(billing?.plan_expiry_date); // (YYYY-MM-DD)

    const expiryProgressBar = () => {
        const today = new Date();
        const timeRemaining = expirationDate - today;
        let totalDuration;
        if (planType === "monthly") {
            totalDuration =
                expirationDate -
                new Date(
                    expirationDate?.getFullYear(),
                    expirationDate?.getMonth() - 1,
                    expirationDate?.getDate()
                );
        }
        else {
            totalDuration =
                expirationDate -
                new Date(
                    expirationDate?.getFullYear() - 1,  // annually
                    expirationDate?.getMonth(),
                    expirationDate?.getDate()
                );
        }
        const percentage = ((totalDuration - timeRemaining) / totalDuration) * 100;
        return (
            <div style={{ padding: "3%" }}>
                <p
                    className="about-para"
                    style={{ fontSize: 13, textTransform: "uppercase" }}
                >
                    Expiry Date
                    <b> {formatDate(billing?.plan_expiry_date)}</b>
                </p>
                <LinearProgress
                    variant="determinate"
                    value={percentage}
                    style={{ borderRadius: 5, height: 23, marginTop: "1%" }}
                />
            </div>
        );
    };



    const planItems = [
        {
            title: "Plan name",
            value: plan?.name + ' Plan',
        },
        {
            title: "Billing Cycle",
            value: billing?.plan_tenure,
        },
        {
            title: "Plan cost",
            value: formatPrice(billing?.payment_amount),
            type: "currency",
        },
    ];

    

    const subscriptionSummary = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <div style={current_plan_div}>
                            {planDiv()}
                            <div style={plan_items_div}>
                                {planItems?.map((item, i) => {
                                    return (
                                        <div style={plan_item}>
                                            <p
                                                className="about-para"
                                                style={{ fontSize: 13, textTransform: "uppercase" }}
                                            >
                                                {item?.title}
                                            </p>
                                            <h3
                                                style={{
                                                    margin: "2%",
                                                    fontSize: 17,
                                                    fontWeight: 500,
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                <>{item?.value}</>
                                            </h3>
                                        </div>
                                    );
                                })}
                            </div>
                            {expiryProgressBar()}
                        </div>
                    </Grid>
                    <Grid item md={6}>

                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <div>
                {subscriptionSummary()}
            </div>
        </>
    );
}