import { Backdrop, Button, CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { get_data, post_data } from "../../api";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../global/components/SearchComponent";
import EmployeeTable from "../components/tables/EmployeeTable";
import { createButtonColor, primaryColor } from "../../constant";
import FilterModal from "../components/FilterModel";
import TableLoader from "../../global/components/TableLoader";
import { useSelector } from "react-redux";
import Empty from "../../global/components/Empty";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import toast from "react-hot-toast";
import CompanyEmployeeModel from "../components/modals/CompanyEmployeeModel";

export default function Employee() {

    const { user_data } = useSelector(state => state.user);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState(null);
    const [totalData, setTotalData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [employeeType, setEmployeeType] = useState('');
    const [employees, setEmployees] = useState('');
    const [department, setDepartment] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [searchState, setSearchState] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [startDate, endDate] = dateRange;
    const [open, setOpen] = useState(false)
    const [fromDate, setFromDate] = useState([])
    const [toDate, setToDate] = useState([])
    const [employeeData, setEmployeeData] = useState([])
    const [exportOpen, setExportOpen] = useState(false)
    const handleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            if (value !== '') {
                searchData();
                setSearchState(true);
            } else {
                fetchData();
                setSearchState(false);
            }
        }, 500);
    };

    const searchData = async () => {
        try {
            const response = await get_data(`employee/search-employees/${value}/${user_data?._id}?pageNumber=${currentPage}`);
            setLoading(false);
            if (response.status) {
                setData(response?.data?.employees);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalEmployees);
            } else {
                setData([]);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            const response = await get_data(`employee/get-all-employees-by-company/${user_data?._id}?pageNumber=${currentPage}`);
            setLoading(false);
            if (response.data) {
                setData(response?.data?.employees);
                setTotalPages(response?.data?.totalPages);
                setTotalData(response?.data?.totalEmployees);
            } else {
                setData([]);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (currentPage) {
            if (searchState) {
                searchData();
            } else {
                fetchData();
            }
        }
    }, [currentPage, refresh, searchState]);

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refresh]);

    const handleClick = async () => {
        try {
            const fromDate = moment(startDate).toISOString();
            const toDate = moment(endDate).toISOString();
            let response

            response = await post_data('employee/get-all-employees-by-date', {
                from: fromDate,
                to: toDate
            })
            if (response?.status) {
                setOpen(false)
                setEmployeeData(response?.data?.employee)
                setExportOpen(true)
                setFromDate(response?.data?.fromDate)
                setToDate(response?.data?.toDate)
            } else {
                toast.error('Error! Something went wrong!')
            }

        } catch (error) {
        }
    };

    const handleAddEmployee = () => {
        navigate('/company/dashboard/addemployee');
    };

    const tableHeader = [
        "S No.", "Employee Name", "EMP ID", "Gender", "Department", "Designation", "Hiring Date", "Contact Phone", "City"
    ];

    const rangePickerStyle = {
        display: 'flex',
        cursor: 'pointer',
        fontSize: 14,
        background: 'white',
        padding: '4% 0% 4% 0%',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 250,
        color: '#7E8299',
        cursor: 'pointer'
    };

    const handleDateChange = async (dates) => {
        if (dates && dates?.length === 2) {
            setDateRange(dates);
            try {
                const fromDate = moment(startDate).toISOString();
                const toDate = moment(endDate).toISOString();
                let response

                response = await post_data(`employee/get-all-employees-by-date?pageNumber=${currentPage}`, {
                    from: fromDate,
                    to: toDate
                })
                if (response?.status) {
                    setOpen(false)
                    setData(response?.data?.employee)
                    setTotalData(response?.data?.totalEmployees)
                    setTotalPages(response?.data?.totalPages)
                    setFromDate(response?.data?.fromDate)
                    setToDate(response?.data?.toDate)
                } else {
                    toast.error('Error! Something went wrong!')
                }
            } catch (error) {
            }
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            //   fetchExportData();
            // setOpen(true)
        }
    }, [startDate, endDate]);

    const datePicker = {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        borderRadius: 3,
        border: '1px solid gray',
        gap: 10
    }

    return (
        <>
            <Grid container spacing={0} style={{ width: '100%', marginBottom: '2%', display: "flex", alignItems: 'center', gap: '1%' }}>
                <div style={{ gap: 10, display: 'flex', marginBottom: '1%', marginTop: 0 }}>
                    <div style={{ display: 'flex', marginRight: 'auto' }}>
                        <SearchComponent handleSearch={handleSearch} setValue={setValue} value={value} />
                    </div>

                    <div style={{ cursor: 'pointer', background: 'gainsboro', borderRadius: 3, width: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FilterModal
                            employeeType={employeeType}
                            setEmployeeType={setEmployeeType}
                            employees={employees}
                            setEmployees={setEmployees}
                            department={department}
                            setDepartment={setDepartment}
                            setRowsData={setData} // Updated to reflect in the main data state
                            setTotalPages={setTotalPages}
                            setTotalData={setTotalData}
                        />
                    </div>

                    <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                        <Button onClick={handleAddEmployee} startIcon={<IoMdAdd />} variant="contained" style={{ background: createButtonColor, boxShadow: 'none' }}>
                            Add Employee
                        </Button>


                    </div>
                </div>

                <div style={{ width: "100%" }}>
                    {
                        loading ? (
                            <TableLoader />
                        ) : (
                            <>
                                {
                                    data?.length === 0 ? (
                                        <Empty />
                                    ) : (<>
                                        <EmployeeTable
                                            type='employee'
                                            data={data} // Use data instead of rowsData
                                            tableHeader={tableHeader}
                                            itemsPerPage={15}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            totalData={totalData}
                                            totalPages={totalPages}
                                            loading={loading}
                                            setLoading={setLoading}
                                            refresh={refresh}
                                            setRefresh={setRefresh}
                                        />
                                        <CompanyEmployeeModel
                                            open={exportOpen}
                                            setOpen={setExportOpen}
                                            fromDate={fromDate}
                                            toDate={toDate}
                                            data={employeeData}
                                        />
                                    </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </Grid>
        </>
    );
}
