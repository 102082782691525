import styled from 'styled-components';
import { useState } from 'react';
import Swal from "sweetalert2";
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { login } from "../../redux/slices/user-slice"
import { post_data } from "../../api"
import { primaryColor, secondaryColor } from '../../constant';
import OtpComponent from '../../company-dashboard/components/OtpComponent';
import toast from 'react-hot-toast';

const Container = styled.div`
  width:100vw; 
  height: 100vh;
  background: white;
`;

const LoginBox = styled.div`
  display: flex;
  width: 100%;
  height:100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const FormSection = styled.div`
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  boxSizing:border-box;
`;

const ImageSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h2`
  margin-bottom: 1px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const Subtitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Link = styled.a`
  font-size: 14px;
  color: #6c63ff;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

function LoginForm() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [loader, setLoader] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [OtpBoxOpen, setOtpBoxOpen] = useState(false)

    const dispatch = useDispatch();

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const handleError = (label, value) => {
        setErrors((prev) => ({ ...prev, [label]: value }))
    }

    const validate = () => {
        let error = false;

        if (email.length === 0) {
            error = true;
            handleError('email', 'Email is Required')
        }

        if (password.length === 0) {
            error = true;
            handleError('password', 'Password is Required')
        }

        return error;
    }


    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleLogin = async () => {
        const error = validate();
        if (!error) {
            try {
                setLoader(true);
                const data = await post_data("company/company-login", { email, password })

                if (data?.status === true) {
                    dispatch(login(data?.data?.user));
                    localStorage.setItem("authToken", data?.data?.token);
                    toast.success("Login Successfully");
                    navigate('/company/dashboard')
                    setLoader(false);
                }
                else {
                    setLoader(false);
                    toast.error(data?.response?.data?.message);
                }
            } catch (error) {
                setLoader(false);
            }
        }
    }


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };


    return (
        <Container>
            <LoginBox>
                <FormSection>
                    <Title>Welcome Back!</Title>
                    <Subtitle>Please Sign In to continue</Subtitle>
                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        type="email"
                        value={email}
                        label="Email Address"
                        className={errors.email ? "errorborder" : ""}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, email: "" })
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.email}</small>
                    <TextField
                        onKeyPress={(event) => handleEnter(event)}
                        className={errors.password ? "errorborder" : ""}
                        fullWidth
                        label="Enter Password"
                        name="newPassword"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        onFocus={() => {
                            setErrors({ ...errors, password: "" })
                        }}
                        autoComplete="off"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <small style={{ marginBottom: '20px', color: 'red' }}>{errors.password}</small>
                    <Button variant='contained'
                        style={{
                            boxShadow: 'none', padding: '3% 0', background: secondaryColor
                        }}
                        disabled={loader} onClick={handleLogin}>Login</Button>
                    <CheckboxContainer style={{ marginTop: '10px' }}>
                        <span style={{ cursor: 'pointer', fontSize: '14px', color: '#6c63ff' }} onClick={() => {
                            navigate('/forget-password', { state: { isClients: true } })
                        }
                        }>Forgot Password?</span>
                    </CheckboxContainer>

                    <Link href="/signup">Didn't have an account, Sign up!</Link>

                </FormSection>
            </LoginBox>
        </Container>
    );
};

export default LoginForm;
