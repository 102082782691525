import './App.css';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { serverURL, get_data, post_data } from './api';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { login } from './redux/slices/user-slice';
import CompanyDashboard from './company-dashboard/pages/CompanyDashboard';
import CompanyLogin from './company-dashboard/pages/CompanyLogin';
import CompanySignup from './company-dashboard/pages/CompanySignup';
import AdminDashboard from './admin-dashboard/pages/AdminDashboard';
import { Toaster } from 'react-hot-toast';
import Home from './website/pages/Home';
import CompForgetPass from './company-dashboard/components/CompForgetPass';
import AdminLogin from './admin-dashboard/pages/AdminLogin';
import Contact from './website/pages/Contact';
import About from './website/pages/About';
import MemberLogin from './company-dashboard/pages/MemberLogin';
import SubAdminLogin from './admin-dashboard/pages/SubAdminLogin';
import Pricing from './website/pages/Pricing';
import PaymentStatus from "../src/website/pages/PaymentStatus"
import { member_login } from './redux/slices/member-slice';
import ChangeEmail from './company-dashboard/components/ChangeEmail';
import PrivacyPolicy from './website/pages/PrivacyPolicy';
import Terms from './website/pages/Terms';
import ResetPassword from './global/components/ResetPassword';
import ResetPasswordAdmin from './global/components/ResetPasswordAdmin';

function App() {

  const dispatch = useDispatch();
  const { isLoggedIn, user_data } = useSelector(state => state.user);

  const token = localStorage.getItem("authToken");
  const member_id = localStorage.getItem("memberId");

  const get_user = async () => {
    const current_data = await post_data("company/get-user", { member_id }, { headers: { "Authorization": token } });
    if (current_data?.status) {
      if (current_data?.data?.member) {
        dispatch(login(current_data?.data?.user));
        dispatch(member_login(current_data?.data?.member));
      }
      else {
        dispatch(login(current_data?.data));
      }
    } else {
    }
  };

  useEffect(() => {
    get_user();
  }, []);

  const [screenLoading, setScreenLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setScreenLoading(false)
    }, 2000);
  }, [])


  return (
    <div className="App">
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      {
        screenLoading ?
          <>
          </>
          :
          <>
            <Router>
              <Routes>
                <Route element={<Home />} path="/" />
                <Route element={<Contact />} path="/contact" />
                <Route element={<About />} path="/about" />
                <Route element={<CompanyLogin />} path="/login" />
                <Route element={<CompanySignup />} path="/signup" />
                <Route element={token ? <CompanyDashboard /> : <CompanyLogin />} path="/company/dashboard/*" />
                <Route element={user_data?.isSuperAdmin ? <AdminDashboard /> : <AdminLogin />} path="/admin/dashboard/*" />
                <Route element={<CompForgetPass />} path="/forget-password" />
                <Route element={<MemberLogin />} path="/member/login" />
                <Route element={<AdminLogin />} path="/admin/login" />
                <Route element={<SubAdminLogin />} path="/sub-admin/login" />
                <Route element={<Pricing />} path="/pricing" />
                <Route element={<PaymentStatus />} path="/payment-status" />
                <Route element={<ChangeEmail />} path="/change-email" />
                <Route element={<ResetPassword />} path="/company/reset-password/:token" />
                <Route element={<ResetPasswordAdmin />} path="/admin/reset-password/:token" />
                <Route element={<PrivacyPolicy />} path="/privacy-policy" />
                <Route element={<Terms />} path="/terms-and-conditions" />
              </Routes>
            </Router>
          </>
      }
    </div>
  );
}

export default App;