import MemberLoginForm from "../../global/components/MemberLoginForm";
import Footer from "../../website/components/Footer";
import Header from "../../website/components/Header";

export default function MemberLogin() {
    return (
        <>
            <Header />
            <MemberLoginForm />
            <Footer />
        </>
    )
}