import React, { useState, useEffect } from 'react';
import { Modal, Button, TextField, Typography, Box } from '@mui/material';
import { post_data } from '../../../api';
import toast, { ToastBar, Toaster, ToastIcon } from 'react-hot-toast';
import { createButtonColor, secondaryColor } from '../../../constant';

const AddPermissionModel = ({ open, onClose, permissionId = null, initialPermissionName = '', refresh, setRefresh }) => {

  const [permissionName, setPermissionName] = useState(initialPermissionName);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (permissionId) {
      setPermissionName(initialPermissionName); // Set the initial name when editing
    } else {
      setPermissionName(''); // Clear the form when adding new permission
    }
  }, [permissionId, initialPermissionName]);

  const handleError = (error, label) => {
    setErrors(prev => ({ ...prev, [label]: error }));
  };

  const handlePermissionNameChange = (e) => {
    setPermissionName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!permissionName.trim()) {
      handleError('Permission name is required.', 'permissionName');
      return;
    }

    const permissionKey = permissionName
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .trim();

    const payload = {
      permission_name: permissionName,
      permission_key: permissionKey,
    };

    try {
      const endpoint = permissionId
        ? `permission/update-sub-admin-permission/${permissionId}`
        : 'permission/create-sub-admin-permission';

      const response = await post_data(endpoint, payload);
      // if (response?.status) {
      //   toast.success("Permission created successfully")
      //   setPermissionName('');
      //   setError({});
      //   onClose();
      //   setRefresh(!refresh)
      // }

      if (response?.status) {
        onClose()
        if (response?.response?.data?.message === 'Permission with this name already exists') {
          toast.error('Permission with this name already exists');
        }
        else {
          toast.success('Company Permission created successfully');
          setPermissionName('');
          setError({});
          onClose();
          setRefresh(!refresh)
        }
      }

    } catch (err) {
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
  };

  return (
    <Modal open={open} onClose={onClose}>

      <Box sx={style}>
        <Typography variant="h6">
          {permissionId ? 'Edit Permission' : 'Add Permission'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Permission Name"
            value={permissionName}
            onChange={handlePermissionNameChange}
            fullWidth
            onFocus={() => handleError('', 'permissionName')}
            error={!!errors.permissionName}
            helperText={errors.permissionName}
            margin="normal"
          />

          <Button type="submit" variant="contained" style={{ boxShadow: 'none', background: secondaryColor, marginTop: '2%' }}>
            {permissionId ? 'Update Permission' : 'Add Permission'}
          </Button>
        </form>
      </Box>

    </Modal>
  );
};

export default AddPermissionModel;
