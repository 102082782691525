import { createSlice } from "@reduxjs/toolkit";

export const memberSlice = createSlice({
    name: "member",
    initialState: {
        isMemberLoggedIn: false,
        member_data: [],
    },
    reducers: {
        member_logout: (state) => {
            state.isLoggedIn = false;
            state.member_data = [];
        },
        member_login: (state, action) => {
            state.isLoggedIn = true;
            state.member_data = action.payload;
        },
    },
});

export const { member_login, member_logout } = memberSlice.actions;

export default memberSlice.reducer;